
import NetInfo from "@react-native-community/netinfo";
import { Platform } from 'react-native';
import DeviceInfo from "../deviceInfo"

/* import {
  ApisauceInstance,
  create,
} from "apisauce" */
import { customApp } from "../../config"
import APIParameters from "../../voolks/APIParameters"


/**
 * Configuring the apisauce instance.
 */
export const DEFAULT_API_CONFIG = {
  url: customApp.baseUrl,
  timeout: 10000,
}

/**
 * Manages all requests to the API. You can use this class to build out
 * various requests that you need to call from your backend API.
 */

export class Api {
  //apisauce: ApisauceInstance
  config: any
  userToken: string
  userId: string
  tenantId: string
  deviceId: string
  uniqueId: any
  deviceModel: string
  onResponse401: Function
  onNetworkError: Function

  /**
   * Set up our API instance. Keep this lightweight!
   */
  constructor(
    userToken: string = "",
    userId: string = "",
    tenantId: string = "",
    config: any = DEFAULT_API_CONFIG,
    onResponse401: Function = undefined,
    onNetworkError: Function = undefined) {
    this.config = config;
    this.tenantId = tenantId;
    this.userToken = userToken;
    this.onResponse401 = onResponse401;
    this.onNetworkError = onNetworkError;
    this.userId = userId;
    this.uniqueId = DeviceInfo.uniqueId;

    this.deviceId = DeviceInfo.deviceId;
    this.deviceModel = DeviceInfo.deviceModel;
    /* this.apisauce = create({
      baseURL: this.config.url,
      timeout: this.config.timeout,
      headers: {
        Accept: "application/json",
      },
    }) */
  }

  baseUrl() {
    return APIParameters().baseUrl;
  }
  async isNetOK() {
    return NetInfo.fetch().then((state) => {
      if (state.isConnected) {
        return true;
      }
      return false;
    });
  }

  async fetchWithTimeout(resource, options) {
    const { timeout = 300000 } = options;
    console.log(resource);
    let haveNet = await Platform.OS == "web" || this.isNetOK();
    if (!haveNet)
      throw new Error("Sin red de datos");
    const controller = new AbortController();
    const id = setTimeout(() => { console.log("ABORT FECHWITHTIMEOUT"); controller.abort() }, timeout);

    const response = await fetch(resource, {
      ...options,
      signal: controller.signal
    }).catch(e => {
      console.log("ApiClient.fetchWithTimeout", e, "\n", resource)
      if (this.onNetworkError)
        this.onNetworkError(resource, response);
      return;
    });
    clearTimeout(id);
    if (this.onResponse401 && response && response.status == 401)
      this.onResponse401(response);
    return response;
  }
  async upFetchTimeout(url, options, signal): Promise<Response> {
    return new Promise((resolve, reject) => {
      signal.addEventListener('abort', () => { reject(new Error("Abort")) });
      fetch(url, options).then((response) => { resolve(response); }).catch(e => { console.log("abort"); reject(e) });
    });
  }

  async registerMe(data: any) {
    return await this.fetchWithTimeout(APIParameters().registerURL(), {
      method: "POST",
      headers: {
        ...APIParameters().reqBaseHeaders
      },
      body: JSON.stringify(data)
    });
  }
  async resetPassword(data: any) {
    return await this.fetchWithTimeout(APIParameters().resetPasswordURL(), {
      method: "POST",
      headers: {
        ...APIParameters().reqBaseHeaders
      },
      body: JSON.stringify(data)
    });
  }

  async signIn(username: string, password: string) {
    return await this.fetchWithTimeout(APIParameters().loginEndpoint(), {
      method: "POST",
      headers: {
        ...APIParameters().reqBaseHeaders
      },
      body: JSON.stringify({
        email: username.trim(),
        password: password.trim(),
        model: this.deviceModel,
        deviceId: await this.uniqueId(),
        packagename: customApp.packagename,
        isPTW: customApp.isPTW,
      })
    });
  }

  async signOut() {
    try {
      if (customApp.logoutSAML) {
        await this.fetchWithTimeout(customApp.logoutSAML.url, {
          method: "GET",
          headers: {
            ...APIParameters().reqBaseHeaders,
            "authorization": 'Bearer ' + this.userToken
          }
        });
      }
    } catch (e) {
      console.error(e.message)
    }
    return await this.fetchWithTimeout(APIParameters().logoutEndpoint(), {
      method: "POST",
      headers: {
        ...APIParameters().reqBaseHeaders,
        "authorization": this.userToken
      },
      body: JSON.stringify({ deviceId: await this.uniqueId(), isPTW: customApp.isPTW })
    });
  }

  async getSubscriptions() {
    return await this.fetchWithTimeout(APIParameters().subscriptionsEndpoint(), {
      method: "GET",
      headers: {
        ...APIParameters().reqBaseHeaders,
        "authorization": this.userToken
      }
    });
  }

  async getPTW() {
    console.log("getPTW", APIParameters().ptwEndpoint());
    return await this.fetchWithTimeout(APIParameters().ptwEndpoint(), {
      method: "GET",
      headers: {
        ...APIParameters().reqBaseHeaders,
        "authorization": this.userToken
      }
    });
  }

  async getTasks() {
    let dateFrom = new Date();
    let dateTo = new Date();
    dateTo.setDate(dateTo.getDate() + 30);
    dateFrom.setDate(dateFrom.getDate() - 10);
    let filter: object = {
      "include": ["client", "form"],
      "where": {
        "or": [
          { "startDate": { "between": [dateFrom.toISOString(), dateTo.toISOString()] } }
          , { "repeat": true }
        ]
      }
    }
    return await this.fetchWithTimeout(APIParameters().tasksEndpoint(this.userId, filter), {
      method: "GET",
      headers: {
        ...APIParameters().reqBaseHeaders,
        "authorization": this.userToken
      }
    });
  }

  async getIntegrations(lstIntegrationId: string[]) {
    return await this.fetchWithTimeout(APIParameters().integrationsEndpoint(lstIntegrationId), {
      method: "GET",
      headers: {
        ...APIParameters().reqBaseHeaders,
        "authorization": this.userToken
      }
    });
  }

  async getIntegration(integrationId: string, config: object = {}, onlyWS: boolean = false) {
    return await this.fetchWithTimeout(APIParameters().integration(integrationId), {
      method: "POST",
      headers: {
        ...APIParameters().reqBaseHeaders,
        "authorization": this.userToken
      },
      body: JSON.stringify({
        onlyWS: onlyWS,
        params: config || false
      })
    });
  }

  async uploadFile(name: string, fileNameSource: string) {
    let haveNet = await this.isNetOK();
    if (!haveNet)
      throw new Error("Sin red de datos");
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), 180000);
    const data = new FormData();
    if (fileNameSource.substring(0, 5) == "data:") {
      //base64
      let res = await fetch(fileNameSource);
      let blob = await res.blob();

      const file = new File([blob], name);
      data.append(name, file, name)

    } else {
      // @ts-ignore
      data.append(name, {
        name: name,
        type: "application/octet-stream",
        uri: Platform.OS === 'ios' ? fileNameSource : `file://${fileNameSource}`,
      });
    }

    let resp = await this.upFetchTimeout(APIParameters().uploadURL(this.tenantId), {
      method: "post",
      body: data,
      headers: Platform.OS == "web" ? { "authorization": this.userToken } : { "Content-Type": "multipart/form-data; ", "authorization": this.userToken },
    }, controller.signal).catch(e => {
      console.log("apis.ts.UploadFile", e)
      throw (new Error('Fallo la conexión subiendo ' + name))
    });
    clearTimeout(id);
    if (this.onResponse401 && resp.status == 401)
      this.onResponse401(resp);
    return resp;
  }

  async deleteFile(uri: string) {
    let haveNet = await this.isNetOK();
    if (!haveNet)
      throw new Error("Sin red de datos");
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), 180000);
    const data = new FormData();
    let resp = await this.upFetchTimeout(APIParameters().deleteFile(uri), {
      method: "delete",
      headers: {
        "authorization": this.userToken
      },
    }, controller.signal).catch(e => {
      throw (new Error('Fallo la conexión subiendo ' + name))
    });
    clearTimeout(id);
    return resp;
  }

  urlDownloadFile(filename: string, container: string = this.tenantId) { return APIParameters().downloadFile(container, filename); }

  async updMember(dataToUpdate: any) {

    return await this.fetchWithTimeout(APIParameters().updMember(this.userId), {
      method: "PUT",
      headers: {
        ...APIParameters().reqBaseHeaders,
        "authorization": this.userToken
      },
      body: JSON.stringify(dataToUpdate)
    });
  }

  async uploadItem(item: any) {
    return await this.fetchWithTimeout(APIParameters().uploadItem(item.formId), {
      method: "POST",
      headers: {
        ...APIParameters().reqBaseHeaders,
        "authorization": this.userToken
      },
      body: JSON.stringify(item)
    });
  }

  async itemPDFURL(deptId: string, formId: string, itemId: string) {
    return await this.fetchWithTimeout(APIParameters().itemPDF(deptId, formId, itemId), {
      method: "GET",
      headers: {
        ...APIParameters().reqBaseHeaders,
        "authorization": this.userToken
      }
    });
  }

  async getInstallations() {
    let filter: object = { "where": { "userId": this.userId } };
    return await this.fetchWithTimeout(APIParameters().installations(filter), {
      method: "GET",
      headers: {
        ...APIParameters().reqBaseHeaders,
        "authorization": this.userToken
      },
    });
  }

  async delInstallation(id: string) {
    return await this.fetchWithTimeout(APIParameters().installation(id), {
      method: "DELETE",
      headers: {
        ...APIParameters().reqBaseHeaders,
        "authorization": this.userToken
      },
    });
  }

  async newInstallation(installation: object) {
    console.log("newInstallation", installation)
    return await this.fetchWithTimeout(APIParameters().installation(), {
      method: "POST",
      headers: {
        ...APIParameters().reqBaseHeaders,
        "authorization": this.userToken
      },
      body: JSON.stringify(installation)
    });
  }

}

// Singleton instance of the API for convenience
export const api = new Api()
