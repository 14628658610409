import { Box, Button, HStack, Icon, IconButton, Spacer, Image, Text, VStack, View, Spinner, AlertDialog } from 'native-base';
import React, { FunctionComponent } from 'react';
import { ElementBase, IElementBaseProps, useInitializeElement } from '..';
import SignatureCanvas from 'react-signature-canvas'
import SignatureScreen from 'react-native-signature-canvas';
import { MaterialIcons, Ionicons, MaterialCommunityIcons, FontAwesome5 } from "@expo/vector-icons";
import * as ImageManipulator from 'expo-image-manipulator';
import { convertImageToBase64 } from '../../utils/general';
import { Platform, useWindowDimensions } from 'react-native';
import { ModalFull } from ".."

const importImageFromPopulate = async (value, element, itemsStore) => {
    let fileName: string = `${element.type}-${element.id}-${itemsStore.itemActive._startedAt.getTime()}.jpg`;
    let imgB64 = await (new Promise((resolve, reject) => {
        try {
            convertImageToBase64(value, resolve);
        } catch (e) {
            reject();
        }
    }))
    value = await itemsStore.saveFilePayload(fileName, imgB64);
    return value.uri;
}
export const SignatureElement: FunctionComponent<IElementBaseProps> = ({ ...props }) => {
    const {
        itemsStore, element, modalVisible, setModalVisible, statusStore, authenticationStore,
        loading, setLoading,
        payload, checkAndSetPayload, errorList,
    } = useInitializeElement(props, true, importImageFromPopulate);
    const [saveToCache, setSaveToCache] = React.useState<boolean>(false);
    const [alertDialog, setAlertDialog] = React.useState(undefined);

    const cancelRef = React.useRef();
    const signRef = React.useRef<SignatureCanvas>(null);
    
    let rndExtraImg = "";
    if (props.parentId && itemsStore.getPayloadElement(props.parentId).type == "simplelist") {
        rndExtraImg = (new Date()).getTime().toString();
    }

    const clear = () => {
        Platform.OS == "web" ? signRef.current?.clear() : signRef.current.clearSignature();
    }


    
    const getFromCache = () => {
        if (authenticationStore.userInfo.user.signCache == undefined) {
            let alert: any = {
                title: "Firma de Perfil",
                message: `No existe configurada una firma en tu perfil ¿Queres crearla ahora, para cambiarla deberas ingresar desde el menú lateral a Configuraciones.?`,
                action: () => {
                    setSaveToCache(true);
                    setModalVisible(true);
                }
            }
            setAlertDialog(alert);
        } else {
            setLoading(true);
            convertImageToBase64(authenticationStore.userInfo.user.signCache, save)
        }
    }



    const getFromCanvas = async () => {
        setLoading(true);
        if (Platform.OS == "web") {
            let uriBase64 = signRef.current?.getCanvas()
                .toDataURL("image/jpeg", 1);
            let resizeTo = [{ resize: { width: 400 } }];
            uriBase64 = await ImageManipulator.manipulateAsync(
                uriBase64, resizeTo,
                { compress: 0.7, format: ImageManipulator.SaveFormat.JPEG }
            );
            save(uriBase64.uri);
        } else {
            signRef.current.readSignature();
        }
    }
    const putStamp = async () => {
        if (Platform.OS == "web")
            signRef.current?.fromDataURL(authenticationStore.userInfo.user.stampCache);
        else
            convertImageToBase64(authenticationStore.userInfo.user.stampCache, signRef.current.loadDataURL);

    }

    const save = async (uri?: string | undefined) => {
        if (saveToCache) {
            authenticationStore.saveSignCache(uri);
        }
        let fileName: string = `${element.type}${rndExtraImg}-${element.id}-${itemsStore.itemActive._startedAt.getTime()}.jpg`;
        let uriSaved = await itemsStore.saveFilePayload(fileName, uri);
        checkAndSetPayload(uriSaved.uri);
        setModalVisible(false);
        setLoading(false);
        setSaveToCache(false);

    }
    const remove = async () => {
        setLoading(true);
        await itemsStore.removeFilePayload(payload.value)
        checkAndSetPayload(undefined);
        setLoading(false);
    }
    const canSign = () => {
        if (element.templateData.closeRecord) {            
            let listOfPayload = itemsStore.getPayloadAsArray();
            let isvalid: boolean = listOfPayload.reduce((prevVal, payloadElement) => {
                if (payloadElement.id != element.id) {
                    if (payloadElement.inactiveGroup)
                        return (prevVal ? true : prevVal);
                    else
                        return (prevVal ? payloadElement.valid : prevVal);
                } else {
                    return prevVal;
                }
            }, true)
            if(!isvalid)
                statusStore.setFlashMessage("Debes completar todos los campos obligatorios antes de firmar")
            return isvalid;
        } else
            return true;
    }

    let width = statusStore.windowDimensions.width - 15;
    let height = (statusStore.windowDimensions.height - 150) > width ? width * 0.66 : (statusStore.windowDimensions.height - 150);
    const screen = useWindowDimensions();
    React.useEffect(() => {
        width = statusStore.windowDimensions.width - 15;
        height = (statusStore.windowDimensions.height - 150) > width ? width * 0.66 : (statusStore.windowDimensions.height - 150);
    }, [screen])

    let mediaName: { uri: string };
    let btnRemove = <></>;
    if (payload.value) {
        mediaName = { uri: payload.value.toString() };
        mediaName.uri = mediaName.uri.concat("?refresh=", new Date().getTime().toString());
        mediaName.uri = ["file://", "http://", "https:/"].includes(mediaName.uri.substring(0, 7)) ? mediaName.uri : "file://".concat(mediaName.uri);
        btnRemove = props.forReview != true ? <IconButton size="md" colorScheme="danger" onPress={remove} icon={<Icon as={Ionicons} name="trash" size="md" />} /> : null;        
    }

    const style = `.m-signature-pad {box-shadow: none; margin-top:0px;margin-left:1px; } 
              .m-signature-pad--body {left:0}
              .m-signature-pad--footer {display: none; margin: 0px;}
              body,html {
              width: ${width.toFixed(0)}px; height: ${height.toFixed(0)}px;}`;



    return <ElementBase errors={errorList} element={element} forReview={props.forReview} rigthButton={btnRemove}>
        {loading ?
            <HStack width="100%" justifyContent={"center"} p="10" ><Spinner size={"lg"}></Spinner></HStack>
            : <>
                <HStack width="100%" >
                    {mediaName?.uri ?
                        <Image alt={"signImage".concat(element.id)} key={"signImage".concat(element.id)} source={mediaName} width="100%" height="150"
                            style={{ height: 150, flex: 1, resizeMode: "contain", backgroundColor: "#fff", marginTop: 2 }} />
                        : <></>
                    }
                </HStack>
                {props.forReview != true ?
                    <HStack justifyContent="center" display={props.forReview ? "none" : "flex"}>
                        <Button variant={"ghost"} width={"1/2"} p="2" borderRadius={0} onPress={() => canSign() && setModalVisible(true)} leftIcon={<Icon as={MaterialCommunityIcons} name="signature-freehand" size="lg" />}>
                            <Text color="secondary.400">Firmar</Text>
                        </Button>
                        {element.templateData.allowSignProfile ?
                            <Button variant={"ghost"} width={"1/2"} p="2" borderRadius={0} onPress={getFromCache} leftIcon={<Icon as={MaterialIcons} name="verified-user" size="lg" />}>
                                <Text color="secondary.400">Traer de Perfil</Text>
                            </Button>
                            : <></>}
                    </HStack>
                    : <></>}
            </>
        }
        <ModalFull
            visible={modalVisible}
            title="Firmar"
            closeButton_onPress={() => { setModalVisible(!modalVisible); }}
            rightButtons={authenticationStore.userInfo.user.stampCache && <IconButton variant="solid" icon={<Icon as={FontAwesome5} name="stamp" size="md" />} onPress={putStamp} />}
            footer={<>
                <Button colorScheme="primary" width={"1/2"} p="2" borderRadius={0} onPress={getFromCanvas} leftIcon={<Icon as={Ionicons} name="checkmark" size="lg" />}>
                    Aceptar
                </Button><Button colorScheme="primary" p="2" borderRadius={0} width={"1/2"} onPress={clear} leftIcon={<Icon as={Ionicons} name="checkmark" size="lg" />}>
                    Limpiar
                </Button>
            </>
            }
        >
            <View width={width || 350} alignSelf={"center"} borderWidth={1}>
                {Platform.OS == "web" ?
                    <SignatureCanvas
                        ref={signRef}
                        backgroundColor="#ffff"
                        minWidth={1}
                        maxWidth={5}
                        canvasProps={
                            {
                                width: width || 350,
                                height: height || 200,
                            }
                        }

                    />
                    : <View style={{ height: height, }}>
                        <SignatureScreen
                            ref={signRef}
                            onOK={save}
                            webStyle={style}
                        />
                    </View>
                }
            </View>
        </ModalFull>

        <AlertDialog leastDestructiveRef={cancelRef} isOpen={alertDialog != undefined} >
            <AlertDialog.Content>
                <AlertDialog.CloseButton />
                <AlertDialog.Header>{alertDialog?.title}</AlertDialog.Header>
                <AlertDialog.Body>{alertDialog?.message}</AlertDialog.Body>
                <AlertDialog.Footer>
                    <Button ref={cancelRef} onPress={() => setAlertDialog(undefined)}>
                        Cancelar
                    </Button>
                    <Button colorScheme="red" ml={3} onPress={() => { alertDialog?.action(); setAlertDialog(undefined) }}>
                        Aceptar
                    </Button>
                </AlertDialog.Footer>
            </AlertDialog.Content>
        </AlertDialog>

    </ElementBase >
}