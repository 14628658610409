import { StyleSheet, Platform } from 'react-native';
import {customApp} from "../voolks/customApp.json";
const StylesApp = StyleSheet.create({
    button_icon: { paddingLeft: 5, paddingRight: 5},
    fontWhite:{color:"#fff"}
    , headerFontSize: { alignSelf:"flex-start" }
    , bgPrimary: { backgroundColor: customApp.styles.primaryColor }
    , bgSecondary: {backgroundColor: customApp.styles.secondaryColor}
    , colorPrimary: {color: customApp.styles.primaryColor}
    , colorSecondary: {color: customApp.styles.secondaryColor}    
    , lstItemChildWrapText:{height:undefined, marginTop:10, paddingBottom:10}
    , separator:{height:undefined}
    , colorClosed: {color: "#3f51b5"}
    , colorOpen: {color: "#62b1f6"}
    , colorDone: {color: "#d9534f"}
    , icon1: {color: "#3f51b5",fontSize: Platform.OS=="ios" ? 22 : 20}
    , signature: {
        flex: 1,
        margin: 0, minHeight: Platform.OS=="ios"? "90%" : 400, width:"100%"
    }
    , centeredView: {
        flex: 1,
        alignItems: "center",
        marginTop: 22
    }
    , container: {
        flex: 1,
        backgroundColor: '#FFFFFF',
    },
    flex02:{ flex: 0.2 },
    fontSize32: { fontSize: 32 },
    checkbox:{ marginRight: 20, marginTop:8 },
    RNPicker:{ borderWidth: 0, flexDirection: "row", margin: 15, backgroundColor: "#FFF", elevation: 0, paddingRight: 25, marginRight: 10 },
    RNPickerLabel:{
        fontSize: 16, color: "#000",
        textAlign: "left",
        width: "99%",
        padding: 10,
        flexDirection: "row"
    },
    width100per:{ width: "100%" },
    width20px:{ width: 20 },
});

export default StylesApp;