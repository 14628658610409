import { Icon, Text, Pressable } from 'native-base';
import React, { FC, FunctionComponent } from 'react';
import {  } from 'react-native';
import {  Ionicons,  } from "@expo/vector-icons";

import { ElementBase, IElementBaseProps, useInitializeElement } from '..';

export const CheckElement: FunctionComponent<IElementBaseProps> = ({ ...props }) => {    
    const {
        itemsStore, element,
        payload, setPayload, errorList, setErrorList
    } = useInitializeElement(props);

      const Toggle = ()=>{
        return payload.value == true ? <Icon mx="2" size="lg" name="checkbox-outline" as={Ionicons} /> : <Icon mx="2" size="lg" name="square-outline" as={Ionicons} />;
    };

    const changeState = () => {
        if (props.forReview)
            return null;
        setPayload({ ...payload, value: (!payload.value == true) })
    }

    return <Pressable _pressed={{bg: "secondary.600:alpha.20"}} _hover={{bg: "secondary.600:alpha.20"}} onPress={changeState}>
        <ElementBase boxStyle={{ padding: 10 }} errors={errorList} element={element} leftButton={<Toggle />} forReview={props.forReview} >
            <Text>{payload.value}</Text>
        </ElementBase></Pressable>
}