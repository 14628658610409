import { Instance, SnapshotIn, SnapshotOut, flow, types } from "mobx-state-tree"
import { withSetPropAction } from "./helpers/withSetPropAction"
import { withStatusModel } from "./helpers/withStatusModel";
import { customApp } from "../config";

/**
 * Model description here for TypeScript hints.
 */

const SubscriptionsByUserModel = types.model({
  userId: types.identifier,
  subscriptions: types.array(types.frozen<any>()),
  lastSync: types.number
})

export const FormsStoreModel = types
  .model("FormsStore")
  .props({
    subscriptionsByUsersDB: types.array(SubscriptionsByUserModel),
  })
  .actions(withSetPropAction)
  .extend(withStatusModel)
  .views(store => ({
    mySubscriptions: () => {
      let mySubscriptions = store.subscriptionsByUsersDB.find(
        //@ts-ignore
        subscriptions => subscriptions.userId == store.parent.authenticationStore.userIdLogged
      );
      return mySubscriptions || { subscriptions: [] };
    },
  }))
  .actions((store) => ({
    updSubscriptions: (subscriptions) => {
      let mySubscriptions = store.subscriptionsByUsersDB.find(subscriptions => subscriptions.userId == store.parent.authenticationStore.userIdLogged)
      if (mySubscriptions != undefined) {
        mySubscriptions.subscriptions = subscriptions;
        mySubscriptions.lastSync = Date.now();
      } else {
        mySubscriptions = SubscriptionsByUserModel.create({
          userId: store.parent.authenticationStore.userIdLogged,
          subscriptions, lastSync: Date.now()
        })
        store.subscriptionsByUsersDB.push(mySubscriptions);
      }
    },

  })) // eslint-disable-line @typescript-eslint/no-unused-vars
  .actions((store) => ({
    getSubscriptions: flow(function* getSubscriptions(withIntegrations = true) {
      store.setStatus("pending");
      const loadInfoID = store.addLoadingInfo({ title: "Descargando Formularios de la Web", spinner: true })      
      const response = customApp.isPTW == true ? yield store.api().getPTW() : yield store.api().getSubscriptions();      
      if (response && response.status == 200) {
        const json = yield response.json();        
        store.updLoadingInfo({ id: loadInfoID, spinner: false });
        if (customApp.isPTW == true) { 
          const loadInfoID = store.addLoadingInfo({ title: "Sincronizando Permisos", spinner: true })         
          yield store.parent.itemsStore.addItemsPTW(json.response);
          store.updLoadingInfo({ id: loadInfoID, spinner: false });
        }        
        store.updSubscriptions(json.response);
        const loadInfoID2 = store.addLoadingInfo({ title: "Cantidad de departamentos sincronizados: ".concat(store.mySubscriptions().subscriptions.length.toString()) });
        if (withIntegrations)
          // @ts-ignore
          yield store.parent.integrationsStore.downloadOffline(store.mySubscriptions().subscriptions);        
      } else if (response && response.status == 401) {
        store.parent.authenticationStore.signOut();
      }
      store.setStatus("done");
    }),
  })) // eslint-disable-line @typescript-eslint/no-unused-vars

export interface FormsStore extends Instance<typeof FormsStoreModel> { }
export interface FormsStoreSnapshotOut extends SnapshotOut<typeof FormsStoreModel> { }
export interface FormsStoreSnapshotIn extends SnapshotIn<typeof FormsStoreModel> { }
export const createFormsStoreDefaultModel = () => types.optional(FormsStoreModel, {})
