export type BGTASK_TYPE = {
    uploadItems?: boolean,
    uploadItemsNetwork?: string,
    uploadItemsFromTime?: string,
    uploadItemsToTime?: string,
    taskreminderTime?:string,
    taskreminder:boolean
}

export type USER_TYPE = {
    id: string,
    firstName: string,
    lastName: string,
    email: string,
    tenantId: string,
    profileUrl?: string,
    hasProfile: boolean,
    initialRoute: string,
    pushToken?: string,
    signCache?: string,
    stampCache?: string,
    memoryLow: boolean,
    SAML: boolean,
    bgTask?: BGTASK_TYPE,
}

export type USERINFO_TYPE = {
    userToken: string,
    deviceId: string,
    model: string,
    systemVersion: string,
    user: USER_TYPE
}

export type FORMFIELD_TYPE = {
    id: string,
    name?: string,
    label?: string,
    type: string,
    templateData: any,
    validation: any,
}

export type FORMPAGE_TYPE = {
    id: string,
    requires?: any[],
    fields: FORMFIELD_TYPE[],
}

export type PAYLOAD_TYPE = {
    type?: string,
    value?: any,
    valid?: boolean,
    readOnly?:boolean,
    activeRecord?: {
        idx: number,
        record: any
    } | undefined

}


export type LOADINGINFO_TYPE = {
    id?: number,
    title?: string,
    iconName?: string,
    iconFamily?: string,
    spinner?: boolean | undefined
}


export enum ITEM_STATUS {
    draft = "draft", closed = "closed", sent = "sent"
}