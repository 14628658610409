import React, { FC, useState } from "react"
import { observer } from "mobx-react-lite"

import { Box, Button, Divider, FlatList, HStack, Icon, IconButton, Pressable, Spacer, StatusBar, Text, View, VStack } from "native-base"
import { MaterialCommunityIcons, Ionicons, MaterialIcons, Feather} from "@expo/vector-icons";

import { HomeStackParamList, useBackButtonHandler } from "../../navigators"
import { DrawerScreenProps } from "@react-navigation/drawer"
import { useStores } from "../../models"
import { ITEM_STATUS } from "../../voolks/types";
import { shouldTaskAppearToday } from "../../utils/general";
import { Dimensions, Linking, SafeAreaView } from "react-native";
import { getIconItem } from "../../utils/itemCommonFunctions";
import { customApp } from "../../config";


const filterTasksToShow = (tasks: Object[], nextDays: Object[]) => {
  let hasAnyTask: boolean = false;
  console.log("filterTasksToShow")
  for (var i = 0; i < 20; i++) {
    let start = new Date();
    start.setDate(start.getDate() - 10);
    start.setHours(0, 0, 0, 0);
    start.setDate(start.getDate() + i);
    let end = new Date(start.toISOString());
    end.setHours(23, 59, 59, 0);
    nextDays.push({
      start: start,
      end: end,
      tasks: []
    });
  }

  if (tasks == undefined) {
    tasks = [];
    return null;
  }

  tasks?.reduce(function (dates: Object[], task: any) {
    dates.forEach(function (date: any) {
      task.startDate = new Date(task.startDate);
      task.endDate = new Date(task.endDate);
      if (task.repeat) {
        if (shouldTaskAppearToday(task, date.end)) {
          date.tasks.push(task);
          hasAnyTask = true;
          return;
        }
      } else if (task.startDate > date.start && task.endDate < date.end) {
        date.tasks.push(task);
        hasAnyTask = true;
        return;
      }
    });

    return dates;

  }, nextDays);

  return hasAnyTask;
}




export const TasksListScreen: FC<DrawerScreenProps<HomeStackParamList, "TasksList">> = observer(function TasksListScreen({ navigation, route }) {
  const { formsStore, itemsStore, statusStore, tasksStore } = useStores();
  let myTaskDB = tasksStore.myTasks();
  const tasks = myTaskDB?.tasks || [];
  const listRef = React.useRef<any>();
  let taskItemsCounter: any = {}
  let tasksToShow: Object[] = [];
  const scrollOffsetY = React.useRef(0);

  let my_items: any = React.useMemo(() => {
    return itemsStore.myItems({});
  }, [itemsStore.itemsByUsersDB.length]);

  useBackButtonHandler(() => navigation.openDrawer());

  let listToRender = <VStack m="5" justifyContent="center">
    <Text>No tienes tareas asignadas en los próximos 10 días</Text>
    <Divider />
    {!customApp.user ? <></> :
      <View style={{ flexDirection: "row", margin: 30 }}>
        <Text>Para asignarte tareas debes hacerlo mediante la web de administración.</Text>
        <IconButton variant="solid" icon={<Icon size="md" as={Feather} name="external-link" />} onPress={() => { Linking.openURL("https://field.voolks.com"); }} />
      </View>
      }
  </VStack>;

  const scrollToToday = () => {
    listRef.current?.scrollToOffset({
      offset: scrollOffsetY.current,
      animated: true,
    });
  }
  const getItemLayout = (data, index) => {
    return { length: 77, offset: 77 * index, index };
  }
  if (my_items)
    my_items.map((item) => {
      let data = { closed: 0, sent: 0, draft: 0 };
      if (taskItemsCounter[item.taskId] != undefined) {
        data = taskItemsCounter[item.taskId]
      }
      data[item.status]++;
      taskItemsCounter[item.taskId] = data;
    })
  const getRenderItem = ({ item, index }) => {
    let day = item;
    return <View
      key={day.start.getTime()}
      onLayout={(event => {
        if (day.start.toISOString().substr(0, 10) < new Date().toISOString().substr(0, 10)) {
          const layout = event.nativeEvent.layout;
          scrollOffsetY.current = ((index == 0 ? 0 : scrollOffsetY.current) + parseInt(layout.height.toFixed()))
        }
      })}
      bgColor={(day.start.toISOString().substr(0, 10) == new Date().toISOString().substr(0, 10)) ? "secondary.400:alpha.60" : "white"}
    >
      <HStack w="full"  >
        <HStack width="60">
          <VStack m="auto">
            <Text fontSize="lg" bold>{day.start.getDate()}</Text>
            <Text fontSize="xs">{day.start.getWeekDayname(day.start).substr(0, 3)}</Text>
          </VStack>
        </HStack>
        <VStack space={1} py="2" width={statusStore.windowDimensions.width - 80}>
          {day.tasks.map((task: any, idx: number) => {
            return <Pressable key={`pressable${idx}`} _pressed={{ bg: "primary.600:alpha.40" }} _hover={{ bg: "primary.600:alpha.40" }}
              onPress={() => navigation.navigate("TaskDetails", { taskId: task.id, fromTaskList: true })}>
              <Box shadow="1" p="2" borderWidth={0} borderColor="light.100" bgColor="white:alpha.70">
                <VStack>
                  <Text>{task.name}</Text>
                  <HStack alignItems="center">
                    <Text pl="2" fontSize="xs">{taskItemsCounter[task.id] ? taskItemsCounter[task.id].sent : 0}</Text>
                    {getIconItem(ITEM_STATUS.sent, undefined, "xs")}
                    <Text pl="2" fontSize="xs">{taskItemsCounter[task.id] ? taskItemsCounter[task.id].closed : 0}</Text>
                    {getIconItem(ITEM_STATUS.closed, undefined, "xs")}
                    <Spacer />
                    <Icon name="clock" size="xs" as={MaterialCommunityIcons} />
                    <Text pl="1" fontSize="xs">{task.startDate.getHHmm()} - {task.endDate.getHHmm()}</Text>
                  </HStack>
                </VStack>
              </Box>
            </Pressable>
          })
          }
        </VStack>
      </HStack>
      <Divider alignSelf="center" />
    </View>
  }

  if (tasks && filterTasksToShow(tasks, tasksToShow)) {
    const { width: winWidth, height: winHeight } = Dimensions.get('window');
    listToRender = (<SafeAreaView><FlatList style={{ height: winHeight - 100 }}
      data={tasksToShow} ref={listRef}
      onLayout={scrollToToday}
      keyExtractor={(_, index) => index.toString()}
      getItemLayout={getItemLayout}
      renderItem={getRenderItem}
    /></SafeAreaView>)
  }

  let leftButton = <IconButton variant="solid" icon={<Icon size="xl" as={Ionicons} name="arrow-back" />} onPress={navigation.openDrawer} />;
  return (<>
    <StatusBar backgroundColor={"#3700B3"} barStyle="light-content" />
    <Box safeAreaTop bg="primary.400" />
    <HStack key="itemDetails-title" bg="primary.400" px="0" py="0" justifyContent="space-between" alignItems="center" w="100%">
      <HStack key="headerBar" alignItems="center" style={{ flexDirection: 'row', flex: 0.8 }} maxW={statusStore.windowDimensions.width - 140}>
        {leftButton}
        <View style={{ flexDirection: 'row', flex: 1 }}>
          <Text color="white" fontSize="18" style={{ flexWrap: 'wrap', overflow: "hidden" }}>
            Tareas
          </Text>
        </View>
      </HStack>
      <HStack key="secondBar" alignItems="center" style={{ flexDirection: 'row-reverse', flex: 0.2, }}>
        <IconButton variant={"solid"} onPress={tasksStore.getTasks}
          icon={<Icon as={Ionicons} name="refresh-outline" size="xl" />}
        />
        <IconButton variant={"solid"} onPress={scrollToToday}
          icon={<Icon as={MaterialIcons} name="today" size="xl" />}
        />
      </HStack>
    </HStack>

    <VStack key="itemDetails-VStackItems" bg="white" flex={1}>
      {listToRender}
    </VStack>


  </>
  )
})