import React from "react";
import { Icon, Button, View, Text, Box, HStack, Spacer, Badge, Image, Pressable, Menu, Divider, VStack, IconButton } from "native-base"
import { MaterialIcons, Ionicons, SimpleLineIcons, MaterialCommunityIcons } from "@expo/vector-icons";
import { ImageBackground, ImageStyle, Platform, ViewStyle, useWindowDimensions } from "react-native"
import { DrawerContentScrollView } from "@react-navigation/drawer";
import * as FileSystem from 'expo-file-system';
import { useStores } from "../models";
import { customApp } from "../config";
import { onAction, onPatch } from "mobx-state-tree";



export const SideMenu = ((props: any) => {
    const { authenticationStore, formsStore, integrationsStore, tasksStore, itemsStore } = useStores();
    const dimensions = useWindowDimensions();
    const [expandForms, setExpandForms] = React.useState(dimensions.height > 800);
    const [rendered, setRender] = React.useState(<></>);
    const renderMe = () => {
        const isFieldForms = (customApp.user && customApp.user != "");
        const logo_background = require('../../assets/images/blurred_lines.jpeg');
        const route = props.navigation.getState();
        const user = authenticationStore.userInfo.user;
        const subscriptions = JSON.parse(JSON.stringify(formsStore.mySubscriptions().subscriptions)).sort((a, b) => a.name.localeCompare(b.name));
        const source = user.hasProfile ? { uri: (Platform.OS == "web" || true ? user.profileUrl : `file://${FileSystem.documentDirectory}/profile-${authenticationStore.userIdLogged}.jpg?${Date.now()}`) } : require("../../assets/images/logo_circle.png");
        return (
            <View style={{ flex: 1 }} maxHeight={dimensions.height}>
                <View style={$logoContainer}>
                    <ImageBackground source={logo_background} style={$userBackImage}>
                        <HStack alignItems={"center"}>
                            <Image alt="useravaar" source={source} style={$userImage} />
                            <Pressable onPress={() => props.navigation.navigate("Settings", { fromForm: false })} style={{ paddingBottom: 10 }}>
                                <Text style={{ color: "#fff", textAlign: "center" }}>{user?.firstName} {user?.lastName}
                                    <Icon onPress={() => props.navigation.navigate("Settings")} as={MaterialIcons} size={"md"} color="#fff" name="person" />
                                </Text>
                                <Text style={{ color: "#fff", textAlign: "center", }}>{customApp.profileHidden ? null : user?.email} </Text></Pressable>
                        </HStack>
                    </ImageBackground>
                </View>
                <View style={{ marginVertical: 0, flex: 4, }} >
                    <DrawerContentScrollView style={{ flex: 1 }} {...props}>
                        <Box borderBottomWidth="0" borderColor="muted.800" pl={["0", "4"]} pr={["0", "5"]} py="2">
                            <Pressable _pressed={{ bg: "secondary.600:alpha.20" }} _hover={{ bg: "secondary.600:alpha.20" }} onPress={async () => { await formsStore.getSubscriptions(); tasksStore.getTasks() }}>
                                <HStack space={[2, 3]} style={{ paddingLeft: 10 }}>

                                    <Icon name="refresh-outline" as={Ionicons} color={"closed.400"} marginY="2.5" />
                                    <Text marginY="2.5" bold>
                                        Sincronizar
                                    </Text>
                                    <Spacer />
                                    <Menu closeOnSelect={true} w="200" trigger={triggerProps => {
                                        return <Pressable {...triggerProps} _hover={{ bg: "secondary.600:alpha.10" }}>
                                            <Icon as={MaterialIcons} name="more-vert" size="md" color="primary.400" marginX={2.5} marginY="2.5" />
                                        </Pressable>;
                                    }}><Menu.OptionGroup title="Sincronizar" type="radio">
                                            <Menu.Item onPress={() => formsStore.getSubscriptions(false)}>Formularios</Menu.Item>
                                            <Menu.Item onPress={async () => {
                                                integrationsStore.setStatus("pending");
                                                await integrationsStore.downloadOffline(formsStore.mySubscriptions().subscriptions)
                                                integrationsStore.setStatus("done");
                                            }}>Integraciones</Menu.Item>
                                            <Menu.Item onPress={tasksStore.getTasks}>Tareas</Menu.Item>
                                        </Menu.OptionGroup>
                                        <Divider />
                                        <Menu.OptionGroup title="BD Completa" type="radio">
                                            <Menu.Item onPress={itemsStore.upload}>Subir los items cerrados</Menu.Item>
                                            <Menu.Item onPress={async () => {
                                                integrationsStore.setStatus("pending");
                                                await itemsStore.upload({});
                                                await formsStore.getSubscriptions();
                                                integrationsStore.setStatus("done");
                                            }}>Sincronizar y Subir</Menu.Item>
                                        </Menu.OptionGroup>
                                    </Menu>
                                </HStack>
                            </Pressable>
                            <Pressable _pressed={{ bg: "secondary.600:alpha.20" }} _hover={{ bg: "secondary.600:alpha.20" }} onPress={() => { props.navigation.navigate("Settings"); setRender(renderMe()); }}>
                                <HStack space={[2, 3]} justifyContent="center" alignItems={"center"} marginY="2.5" style={{ paddingLeft: 10, paddingRight: 5 }} borderBottomWidth={route.routes[route.index].name == "Settings" ? 2 : 0} borderBottomColor="secondary.600:alpha.50">
                                    <Icon name="person" as={Ionicons} color={"closed.400"} />
                                    <Text bold>
                                        Configuraciones
                                    </Text>
                                    <Spacer />
                                </HStack>
                            </Pressable>
                            <Pressable _pressed={{ bg: "secondary.600:alpha.20" }} _hover={{ bg: "secondary.600:alpha.20" }} onPress={() => { props.navigation.navigate("TasksList"); setRender(renderMe()); }}>
                                <HStack space={[2, 3]} justifyContent="center" alignItems={"center"} marginY="2.5" style={{ paddingLeft: 10, paddingRight: 5 }} borderBottomWidth={route.routes[route.index].name == "TasksList" ? 2 : 0} borderBottomColor="secondary.600:alpha.50">
                                    <Icon name="calendar-outline" as={Ionicons} color={"closed.400"} />
                                    <Text bold>
                                        Tareas
                                    </Text>
                                    <Spacer />
                                </HStack>
                            </Pressable>
                        </Box>
                        <View bgColor={"light.100"} style={{ padding: 12 }} >
                            <Text color={"secondary.400"} style={[{ fontSize: 16 }]}>Departamentos</Text>
                        </View>
                        <View style={{ paddingLeft: 10, paddingRight: 5 }}>
                            {subscriptions.map((dept: any) => {
                                return <Box key={dept.id} borderBottomWidth="0" borderColor="muted.800" px="0">
                                    <Pressable _pressed={{ bg: "secondary.600:alpha.20" }} _hover={{ bg: "secondary.600:alpha.20" }} py="2" onPress={() => { props.navigation.navigate("FormsList", { deptId: dept.id }); setRender(renderMe()); }} >
                                        <HStack space={[2, 3]} justifyContent="center" alignItems={"center"} borderBottomWidth={["FormsList", "ItemsList", "ItemDetails"].includes(route.routes[route.index].name) && route.routes[route.index].params.deptId == dept.id ? 2 : 0} borderBottomColor="secondary.600:alpha.50">
                                            <IconButton variant="ghost" key="actionButton-closeItem" p="0"
                                                icon={<Icon as={Ionicons} color={"closed.400"}
                                                    name={expandForms && ["FormsList", "ItemsList", "ItemDetails"].includes(route.routes[route.index].name) && route.routes[route.index].params.deptId == dept.id ? "caret-down" : "caret-forward"}
                                                />}
                                                onPress={() => setExpandForms(!expandForms && dimensions.height > 800)}
                                            />
                                            <Text width={"70%"} bold>
                                                {dept.name}
                                            </Text>
                                            <Spacer />
                                            <Badge colorScheme={"info"} style={{ height: 25, borderRadius: 10, justifyContent: "center" }}><Text color={"white"}>{dept.forms?.length}</Text></Badge>
                                        </HStack>
                                    </Pressable>
                                    {expandForms && ["FormsList", "ItemsList", "ItemDetails"].includes(route.routes[route.index].name) && route.routes[route.index].params.deptId == dept.id ?
                                        <VStack>
                                            {dept.forms.sort((a, b) => a.name.localeCompare(b.name)).map((form: any) => {
                                                return <Box key={form.id} borderBottomWidth="0" borderColor="muted.800" px="0">
                                                    <Pressable _pressed={{ bg: "secondary.600:alpha.20" }} _hover={{ bg: "secondary.600:alpha.20" }} py="2" onPress={() => { props.navigation.navigate("ItemsList", { deptId: dept.id, formId: form.id, forReview: false }); setRender(renderMe()); }} >
                                                        <HStack marginLeft="25" justifyContent="center" alignItems={"center"} borderBottomWidth={["ItemsList", "ItemDetails"].includes(route.routes[route.index].name) && route.routes[route.index].params.formId == form.id ? 2 : 0} borderBottomColor="secondary.600:alpha.50">
                                                            <Icon name={route.routes[route.index].params.formId == form.id ? "format-list-checks" : ""}
                                                                as={MaterialCommunityIcons} color={"closed.400"} />
                                                            <Text width={"100%"} fontSize={"xs"}>
                                                                {form.name}
                                                            </Text>
                                                        </HStack>
                                                    </Pressable>
                                                    <VStack>

                                                    </VStack>
                                                </Box>
                                            })}
                                        </VStack>
                                        :
                                        <></>}
                                </Box>
                            })}
                        </View>
                    </DrawerContentScrollView>
                </View>
                <Button display={isFieldForms ? "none" : "flex"} colorScheme={"danger"} leftIcon={<Icon as={SimpleLineIcons} name="logout" size="sm" />}
                    onPress={() => authenticationStore.signOut()}>
                    Cerrar Sesion
                </Button>


            </View>)
    };

    React.useEffect(() => {
        setRender(renderMe());
        let _disposerOnPatch = onPatch(formsStore, patch => {
            if ((patch.op == "replace" && patch.path.includes("/lastSync")) || (patch.op == "add" && patch.path.includes("/subscriptionsByUsersDB/") && patch.value?.lastSync != undefined))
                setTimeout(() => setRender(renderMe()), 300);
        })
        return () => {
            _disposerOnPatch();
            _disposerOnPatch = undefined;
        }

    }, [])
    React.useEffect(() => {
        console.log(3)
        setRender(renderMe());
    }, [expandForms])

    return rendered
})

const $userImage: ImageStyle = {
    width: 80,
    height: 80,
    marginVertical: 10,
    borderRadius: 200 / 2,
}

const $logoContainer: ViewStyle = {
    flex: 2, alignItems: "center", maxHeight: 200
}


const $userBackImage: ImageStyle = {
    flex: 1, width: "100%", maxHeight: 200,
    justifyContent: "center",
    alignItems: "center",
}