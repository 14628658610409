import { Text } from 'native-base';
import React, { FC } from 'react';
import { ElementBase, IElementBaseProps,  useInitializeElement } from '..';
import { onPatch } from 'mobx-state-tree';
import moment from "moment"


export const DurationElement: FC<IElementBaseProps> = (({ ...props }) => {
    const {
        itemsStore, element,
        payload, checkAndSetPayload, errorList, setErrorList
    } = useInitializeElement(props, true);

    function getTimeDiff() {
        try {
            const getPayload = itemsStore.getPayloadElement;
            const templateData = element.templateData;
            const initialValue = getPayload(templateData.startElement.id,props.parentId).value
            const endValue = getPayload(templateData.endElement.id,props.parentId).value
            if (!initialValue || !endValue)
                return { success: false, value: null, message: "Valores incompletos" }

            const initialMoment: any = moment(initialValue.substr(0, 19));
            let endMoment = moment(endValue.substr(0, 19));
            if (templateData.same_day) {
                endMoment = moment(initialValue.substr(0, 10).concat(endValue.substr(10, 9)));
            }

            if (initialMoment >= endMoment)
                return { success: false, value: null, message: "El valor del campo fin debe ser mayor al de inicio" }

            let duration = moment.duration(endMoment.diff(initialMoment));
            let result = '';
            if (duration.years() > 0)
                result += duration.years() + ' años';
            if (duration.months() > 0)
                result += ' ' + duration.months() + ' meses';
            if (duration.days() > 0)
                result += ' ' + duration.days() + ' dias';
            else if (templateData.dateComparisonType === 'timestamp' || templateData.dateComparisonType === 'time') {
                if (duration.hours() > 0)
                    result += ' ' + duration.hours() + ' horas';
                if (duration.minutes() > 0)
                    result += ' ' + duration.minutes() + ' minutos';
                if (duration.seconds() > 0)
                    result += ' ' + duration.seconds() + ' segundos';
                if (result.trim() == "")
                    result = '< 1 segundos';
            } else if (templateData.dateComparisonType === 'date') {
                if (duration.days() == 0 && result.trim() == "")
                    result = '0 dias';
            }            
            return { success: true, value: result, message: undefined }
        } catch (e) {
            console.log(e)
            return { success: false, value: null, message: "Error" }
        }
    }
    const handleChange = (result: { success: boolean, value: string, message: string }) => {
        if (result.success)
            checkAndSetPayload(result.value);
        else {
            checkAndSetPayload(null);
            setErrorList([result.message]);
        }
    }

    React.useEffect(() => {
        if (props.forReview != true) {
            handleChange(getTimeDiff());
            let _disposerOnPatch = onPatch(itemsStore, patch => {
                try {
                    if (patch.path.includes(element.templateData.startElement.id) || patch.path.includes(element.templateData.endElement.id))
                        handleChange(getTimeDiff());
                } catch (e) { };
            });

            return () => {
                _disposerOnPatch();
                _disposerOnPatch = undefined;
            }
        }
        return undefined;
    }, [])

    return <ElementBase errors={errorList} element={element} forReview={props.forReview} >

        <Text style={{ color: (element.templateData.textColor ? element.templateData.textColor : "#000") }}>
            {payload.value}
        </Text>

    </ElementBase>
});