import { Icon, IconButton, Spinner, } from 'native-base';
import React, { FunctionComponent } from 'react';
import { ElementBase, IElementBaseProps, initializeElement } from '..';
import { Ionicons } from "@expo/vector-icons";
import { getDataFromIntegration } from '../../utils/itemCommonFunctions';
import { onPatch } from 'mobx-state-tree';
import { useDebouncedCallback } from 'use-debounce';
export const ValidateElement: FunctionComponent<IElementBaseProps> = ({ ...props }) => {
    const {
        itemsStore, element, integrationsStore, checkAndSetPayload, errorList, payload
    } = initializeElement(props);

    const [loadingOptions, setLoadingOptions] = React.useState<boolean>(false);

    const getInfo = useDebouncedCallback(async () => {
        setLoadingOptions(true);
        let data = await getDataFromIntegration(element, itemsStore.getPayloadElement, props.parentId, integrationsStore.getIntegration);
        console.log(data, new Date().toISOString());
        setLoadingOptions(false);
        checkAndSetPayload(data == true);
    }, 500)

    React.useEffect(() => {
        try {
            let _disposerOnPatch = onPatch(itemsStore, patch => {
                if (element.integration && element.integration.dynamicParams) {
                    Object.keys(element.integration.dynamicParams).forEach((v: any) => {
                        let myDependency = element.integration?.dynamicParams[v];
                        if (myDependency != element.id && patch.path.includes(myDependency))
                            element.integration.userMode ? checkAndSetPayload(null) : getInfo();
                    });
                }
            });

            return () => {
                _disposerOnPatch();
                _disposerOnPatch = undefined;
            }
        } catch (e) { }
        return null;
    }, []);

    let btnCallIntegration = loadingOptions ? <Spinner key={"validate".concat(element.id, "Spinner")} size="lg" /> : <></>;
    if (!loadingOptions && element.integration && element.integration.id)
        btnCallIntegration = <IconButton key="btnImportIntegration"
            disabled={props.forReview}
            onPress={getInfo}
            colorScheme={payload.value == undefined ? "primary" : payload.value ? "success" : "danger"}
            icon={<Icon as={Ionicons} name={payload.value == undefined ? "sync-circle" : payload.value ? "checkmark-circle" : "close-circle"} size="lg" />}
        />;

    return <ElementBase errors={errorList} element={element} rigthButton={btnCallIntegration} forReview={props.forReview} >
    </ElementBase>
}