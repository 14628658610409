import * as React from "react";
import { Animated, Dimensions, PermissionsAndroid, Platform, StyleSheet, Text, View } from "react-native";
import DeviceInfo from "../services/deviceInfo"
import messaging from '@react-native-firebase/messaging';

import { customApp } from "../voolks/customApp.json";
import { useStores } from "../models";
import { checkMultiple, PERMISSIONS, requestMultiple } from 'react-native-permissions'


const bootSplashLogo = require("../../assets/images/logo.png");

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: customApp.styles.primaryColor,
  },
  text: {
    fontSize: 24,
    fontWeight: "700",
    margin: 20,
    lineHeight: 30,
    color: "#333",
    textAlign: "center",
  },
  bootsplash: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: customApp.styles.primaryColor,
  },
  logo: {
    height: 120,
    width: "80%",
    maxWidth: 450,
    resizeMode: "contain"
  },
});

export const SplashScreen = (props) => {
  const { itemsStore, formsStore, authenticationStore } = useStores();
  const [bootSplashIsVisible, setBootSplashIsVisible] = React.useState(true);
  const [bootSplashLogoIsLoaded, setBootSplashLogoIsLoaded] = React.useState(false);
  const opacity = React.useRef(new Animated.Value(1));
  const translateY = React.useRef(new Animated.Value(0));



  const autoSync = async () => {
    if (authenticationStore.userIdLogged && customApp.isPTW) {
      await itemsStore.upload({});
      await formsStore.getSubscriptions()
    }
  }

  React.useEffect(() => {
    console.log("SplashScreen", "EFFECT")
    if (Platform.OS == "android")
    requestMultiple([PERMISSIONS.ANDROID.POST_NOTIFICATIONS
      , PERMISSIONS.ANDROID.RECORD_AUDIO, PERMISSIONS.ANDROID.READ_EXTERNAL_STORAGE,
    PERMISSIONS.ANDROID.CAMERA, PERMISSIONS.ANDROID.ACCESS_FINE_LOCATION, PERMISSIONS.ANDROID.ACCESS_COARSE_LOCATION
    ]);

    itemsStore.migrateOldDB();
    if (Platform.OS == "web")
      autoSync()
  }, []);

  React.useEffect(() => {
    console.log("SplashScreen", "bootSplashLogoIsLoaded", "EFFECT")

    if (!(Platform.OS == "web" && customApp.isPTW && !DeviceInfo.deviceId.includes("Chrome")))
      bootSplashLogoIsLoaded && setTimeout(props.hideMe, 3000);
  }, [bootSplashLogoIsLoaded]);

  if (Platform.OS == "android")
    checkMultiple([PERMISSIONS.ANDROID.POST_NOTIFICATIONS
      , PERMISSIONS.ANDROID.RECORD_AUDIO, PERMISSIONS.ANDROID.READ_EXTERNAL_STORAGE,
    PERMISSIONS.ANDROID.CAMERA, PERMISSIONS.ANDROID.ACCESS_FINE_LOCATION, PERMISSIONS.ANDROID.ACCESS_COARSE_LOCATION
    ]);
  if (Platform.OS == "web") {
    Notification.requestPermission().then((permission) => {
      if (permission !== "granted") {
        alert("Debes habilitar las notificaciones")
      }
    });
  }

  return (
    <View style={styles.container}>
      <Text style={styles.text}></Text>
      {bootSplashIsVisible && (
        <Animated.View
          style={[
            StyleSheet.absoluteFill,
            styles.bootsplash,
            { opacity: opacity.current },
          ]}
        >
          <Animated.Image
            source={bootSplashLogo}
            fadeDuration={6000}
            resizeMode="contain"
            onLoadEnd={() => setBootSplashLogoIsLoaded(true)}
            style={[
              styles.logo,
              { transform: [{ translateY: translateY.current }] },
            ]}
          />
          {Platform.OS == "web" && !DeviceInfo.deviceId.includes("Chrome") ? <Text style={{ marginTop: "50px", color: "#fff", fontWeight: "bold" }} >Recomendamos Google Chrome como navegador</Text> : <></>}
        </Animated.View>
      )}
    </View>
  );
};