import { Locale, format, parseISO } from "date-fns"
import I18n from "i18n-js"

import ar from "date-fns/locale/ar-SA"
import ko from "date-fns/locale/ko"
import en from "date-fns/locale/en-US"

type Options = Parameters<typeof format>[2]

export const getLocale = (): Locale => {
  const locale = I18n.currentLocale().split("-")[0]
  return locale === "ar" ? ar : locale === "ko" ? ko : en
}

export function getDayName(date = new Date(), locale = 'es-AR') {
  return date.toLocaleDateString(locale, { weekday: 'long' });
}

export const formatDate = (date: string, dateFormat?: string, options?: Options) => {
  const locale = getLocale()
  const dateOptions = {
    ...options,
    locale,
  }
  return format(parseISO(date), dateFormat ?? "MMM dd, yyyy", dateOptions)
}

declare global {
  interface Date {
    getLocale(): string;
    getHHmm(useTimeSet?: boolean): string;
    getYYYYMMddHHmmss(useTimeSet?: boolean): string;
    getYYYYMMdd(useTimeSet?: boolean): string;
    addTimezoneOffset(): Date;
    addDays(days: number, useThis?: boolean): Date;
    isToday(): boolean;
    clone(): Date;
    isAnotherMonth(date: Date): boolean;
    isWeekend(): boolean;
    isSameDate(date: Date): boolean;
    getWeekDayname(date: Date, locale: string): string
  }
}

export const extendDatePrototipe = () => {

  Date.prototype.getWeekDayname = function (date = new Date(), locale = 'es-AR') {
    return date.toLocaleDateString(locale, { weekday: 'long' });
  }

  Date.prototype.getYYYYMMddHHmmss = function (useTimeSet = true) {
    let str = ""
    if (useTimeSet)
      str = new Date(this.getTime() - this.getTimezoneOffset() * 60000).toISOString().replace("T", " ").substr(0, 19)
    else
      str = new Date(this.getTime()).toISOString().replace("T", " ").substring(0, 19)
    return str;
  }

  Date.prototype.getYYYYMMdd = function (useTimeSet = true) {
    let str = ""
    str = this.getYYYYMMddHHmmss(useTimeSet).substring(0, 10)
    return str;
  }


  Date.prototype.getHHmm = function (useTimeSet = true) {
    let str = ""
    str = this.getYYYYMMddHHmmss(useTimeSet).substring(11, 16)
    return str;
  }

  Date.prototype.addTimezoneOffset = function () {
    let date;
    date = new Date(this.getTime() + this.getTimezoneOffset() * 60000)
    return date;
  }

  Date.prototype.addDays = (days: number): Date => {
    if (!days) return this;
    console.log(this);
    let date: Date = this;
    date.setDate(date.getDate() + days);

    return date;
  };

  Date.prototype.isToday = (): boolean => {
    let today = new Date();
    return this.isSameDate(today);
  };

  Date.prototype.clone = (): Date => {
    return new Date(+this);
  };

  Date.prototype.isAnotherMonth = (date: Date): boolean => {
    return date && this.getMonth() !== date.getMonth();
  };

  Date.prototype.isWeekend = (): boolean => {
    return this.getDay() === 0 || this.getDay() === 6;
  };

  Date.prototype.isSameDate = (date: Date): boolean => {
    return date && this.getFullYear() === date.getFullYear() && this.getMonth() === date.getMonth() && this.getDate() === date.getDate();
  };
}