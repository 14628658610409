// This is the entry point if you run `yarn expo:start`
// If you run `yarn ios` or `yarn android`, it'll use ./index.js instead.
import App from "./app/app.tsx"
import React from "react"
import { registerRootComponent } from "expo"

function useExternalScripts({ url }) {

  React.useEffect(() => {
    const head = document.querySelector("head");
    const script = document.createElement("script");

    script.setAttribute("src", url);
    head.appendChild(script);

    return () => {
      head.removeChild(script);
    };
  }, [url]);
};

function IgniteApp() {
  useExternalScripts({ url: "https://maps.googleapis.com/maps/api/js?key=AIzaSyC5HxR2IAiiLhXIuCQxctsKq7AVp1CaGmI" });
  return <App hideSplashScreen={()=>{}}/>
}
registerRootComponent(IgniteApp)
export default IgniteApp
