import { HStack, Icon, IconButton, Input, Text } from 'native-base';
import React, { FC } from 'react';
import { ElementBase, IElementBaseProps, initializeElement } from '..';
import { onPatch, } from 'mobx-state-tree';
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { getDisplayAndValue } from '../../utils/itemCommonFunctions';
import { useDebouncedCallback } from 'use-debounce';
import { MaterialIcons, Ionicons, } from "@expo/vector-icons";


export const FormulaElement: FC<IElementBaseProps> = (({ ...props }) => {
    const {
        itemsStore, element,
        payload, checkAndSetPayload, errorList,
    } = initializeElement(props);

    const handleChange = (value: string | undefined) => {
        value = value == undefined ? "" : (value != undefined && (!isNaN(Number(value)) || value == '-') ? value : "");
        checkAndSetPayload(value);

    }

    const calculate = () => {
        let othersFieldsArray = [];
        if (element.templateData.fxScope)
            othersFieldsArray = itemsStore.itemActive.payload.toJSON()[element.templateData.fxScope].value
        else {
            othersFieldsArray = [{ ...itemsStore.itemActive.payload.toJSON(), ...(props.parentId ? itemsStore.itemActive.payload.get(props.parentId).activeRecord.record.toJSON() : {}) }];
        }

        try {
            const formula = element.templateData.formula;
            let total = undefined;
            if (Array.isArray(othersFieldsArray)) {
                othersFieldsArray.forEach(function (othersFields: any) {
                    let ecuation = formula.formula;
                    let isFilled = true;
                    formula.variables.forEach((v: any) => {
                        let value: any = "0";
                        if (othersFields[v.dataSource.id] && othersFields[v.dataSource.id].value) {
                            value = othersFields[v.dataSource.id].value;
                            if (othersFields[v.dataSource.id].type == "select") {
                                value = getDisplayAndValue(value).value;
                            }
                            if (othersFields[v.dataSource.id].type == "multiple") {
                                value = getDisplayAndValue(value[0]).value;
                            }
                        }
                        const regex = new RegExp(v.variable, 'g');
                        if (value && !isNaN(value)) {
                            ecuation = ecuation.replace(regex, "(".concat(value,")"));
                        } else {
                            isFilled = false;
                        }
                    });
                    let pay = 0;
                    if (isFilled) {
                        let result = eval(ecuation);
                        if (result != undefined && !isNaN(result)) {
                            if (formula.decimals != undefined && !isNaN(formula.decimals))
                                result = parseFloat(result.toFixed(formula.decimals))
                            else
                                result = parseFloat(result.toFixed(6))

                            if (!isNaN(result)) {
                                pay = result;//tuve que hacer esto porque cuando es cero me lo limpia, al hacer el set.
                            }
                        }
                    }

                    if (element.templateData.fxScopeResume && element.templateData.fxScopeResume == "MAX" && (!total || total < pay))
                        total = pay;
                    else if (element.templateData.fxScopeResume && element.templateData.fxScopeResume == "MIN" && (!total || total > pay))
                        total = pay;
                    else if (!element.templateData.fxScopeResume || !["MAX", "MIN"].includes(element.templateData.fxScopeResume))
                        total = pay + (total || 0);


                }); //othersFieldsArray 
            }
            if (total != undefined && element.templateData.fxScopeResume && element.templateData.fxScopeResume == "AVG") {
                total = total / othersFieldsArray.length;
                if (formula.decimals != undefined && !isNaN(formula.decimals))
                    total = parseFloat(total.toFixed(formula.decimals))
                else
                    total = parseFloat(total.toFixed(6))
            }
            if (total == undefined)
                total = 0;
            console.log("FormulaElement", element.id, "TOTAL FORMULA", total)
            handleChange(total);

        } catch (e) { console.log(e, element.name, "error", othersFieldsArray) }
    }

    const calculateDebouneced = useDebouncedCallback(calculate, 100);

    React.useEffect(() => {
        calculate();
        let _disposerOnPatch = onPatch(itemsStore, patch => {
            let mustRecalculate = false;
            if (element.templateData.fxScope)
                patch.path.includes(element.templateData.fxScope.concat("/value")) && calculateDebouneced();
            else {
                element.templateData.formula.variables.forEach((v: any) => {
                    try{
                        let myDependency = v.dataSource.id
                        if (myDependency != element.id && patch.path.includes(myDependency.concat("/value")))
                            mustRecalculate = true;
                        }catch(e){                            
                            mustRecalculate = false;
                        }
                    
                });
                if (mustRecalculate)
                    calculateDebouneced();
            }


        });

        return () => {
            _disposerOnPatch();
            _disposerOnPatch = undefined;
        }
    }, [])
    const btnRefresh = (<IconButton onPress={calculateDebouneced} icon={<Icon as={Ionicons} name="calculator" size="md" />} />)

    return <ElementBase errors={errorList} element={element} rigthButton={btnRefresh} forReview={props.forReview} >
        {props.forReview ?
            <Text style={{ color: (element.templateData.textColor ? element.templateData.textColor : "#000") }}>
                {payload.value}
            </Text>
            :
            <HStack alignItems="center" justifyItems={"start"} >
                <Input variant="unstyled"
                    isReadOnly
                    keyboardType="numeric"
                    onChangeText={handleChange}
                    placeholder={element.templateData.placeholder || ""}
                    value={payload.value?.toString() || ""}
                    width={'full'}
                    size={"md"}
                    InputLeftElement={<>
                        <Icon as={MaterialCommunityIcons} name="function-variant" />
                    </>}
                />
            </HStack>
        }
    </ElementBase>
});