//Web
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import * as Storage from "../utils/storage"
import React from "react";
import { useStores } from "../models";

const firebaseConfig = {
    apiKey: "AIzaSyBarlPxe-ruqv7O2qFr4FBgsxh6AyCWqeM",
    authDomain: "voolks-field.firebaseapp.com",
    databaseURL: "https://voolks-field.firebaseio.com",
    projectId: "voolks-field",
    storageBucket: "voolks-field.appspot.com",
    messagingSenderId: "114855763477",
    appId: "1:114855763477:web:a3d32958b5f52ea2db781c",
    measurementId: "G-W4Q1S1XRGN"
};
const certAPI = "BN2fxKq_NgS2bhsr1kPq0-FgqKvgYl2rAdJYXB5c7bRRZslPUAL-iXzS9dtUohKoRPnQ7MhwGVw28euvaFQPBHo";

export const useFirebaseMessaging = (saveToken) => {
    const { authenticationStore } = useStores()
    const [notif, setNotif] = React.useState<any>()
    let messaging;
    console.log("useFirebaseMessaging");

    React.useEffect(() => {
        try {
            const appFB = initializeApp(firebaseConfig);
            messaging = getMessaging(appFB);
            Notification.requestPermission().then(permission => {
                if (permission === "denied") {
                    console.log("Permission wasn't granted. Allow a retry.");
                    return;
                } else if (permission === "default") {
                    console.log("The permission request was dismissed.");
                    return;
                }
                return getToken(messaging, { vapidKey: certAPI }).then(saveToken)
            });
            onMessage(messaging, payload => {
                if (payload.data.additionalData && payload.data.additionalData.includes("itemId")) {
                    Storage.save("must_sync", true);
                    Storage.save("notif-".concat(JSON.parse(payload.data.additionalData).itemId), payload);
                }
                setNotif(payload)
            });
        } catch (e) {
            console.log("useFirebaseMessaging", e)
        }
    }, [authenticationStore.userIdLogged]);

    return { firebaseMessage: notif, firebaseMessageClean: () => setNotif(undefined) }
}