import { Box, HStack, Icon, Spacer, Text, VStack } from 'native-base';
import React, { FunctionComponent } from 'react';
import { Ionicons } from "@expo/vector-icons";
import { useStores } from '../../models';
import { PAYLOAD_TYPE } from '../../voolks/types';
import { onAction } from 'mobx-state-tree';
import { useDebouncedCallback } from 'use-debounce';
import { useNavigation } from '@react-navigation/native';
import { customApp } from '../../config';

export interface IElementBaseProps {
    errors?: string[],
    element: any,
    rigthButton?: any,
    leftButton?: any,
    forReview?: boolean,
    boxStyle?: any,
    parentId?: string,
    hideTitleBar?: boolean,
    borderBottomWidth?: number,
}

export const getIconHasError = (hasError: boolean) => {
    return (
        hasError ?
            <Icon as={Ionicons} name="close-circle" size="lg" color="danger.500" />
            :
            <></>
    )
}


export const initPayloadValid = (element, payload, setPayload, getErrorElement) => {
    console.log(element.name, element.type, element.id, payload.inactiveGroup)
    if (payload.valid == null || payload.inactiveGroup) {
        let errorElement = getErrorElement(element, payload.value);
        setPayload({ ...payload, valid: errorElement.valid, inactiveGroup: false });    
    }
}

export const useInitializeElement = (props, withUpdPayloadElement = true,
    parseValueFromPopulate = ((value, element, itemStore) => value)) => {
    const element = props.element;
    //console.log(props.element.type, props.element.id, props.parentId, "initializeElement")
    const { itemsStore, statusStore, integrationsStore, authenticationStore } = useStores();

    const [payload, setPayload] =
        React.useState<PAYLOAD_TYPE>(itemsStore.getPayloadElement(element.id, props.parentId));
    const [errorList, setErrorList] = React.useState<string[]>(itemsStore.getErrorElement(element, payload?.value).errorList)
    const [modalVisible, setModalVisible] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const firstRun = React.useRef(true)
    const navigation = useNavigation();
    props.forReview=props.forReview || payload.readOnly;
    const updDebounced = useDebouncedCallback(() => {
       
        itemsStore.updPayloadElement(element.id, payload, props.parentId, element.templateData?.closeRecord);
        if(element.templateData?.closeRecord){
            const ItemsListScreenName = customApp.isPTW ? "PTWHome" : "ItemsList";
            // @ts-ignore
            navigation.navigate(ItemsListScreenName,navigation.getState().routes.find(k=>k.name==ItemsListScreenName).params);
        } 
    }, ["text", "number"].includes(element.type) ? 500 : 0);

    React.useEffect(() => {
        initPayloadValid(element, payload, setPayload, itemsStore.getErrorElement);
    }, [])
    if (withUpdPayloadElement)
        React.useEffect(() => {
            if (firstRun.current)
                firstRun.current = false;
            else{                
                updDebounced()
            }

        }, [payload]);

    React.useEffect(() => {
        let _disposerOnAction = onAction(statusStore, async call => {
            if (call.name == "reloadPayloadElementFromPopulate" && Object.values(call.args[1]).includes(element.id)) {
                let value = itemsStore.getPayloadElement(element.id, props.parentId).value;
                value = await parseValueFromPopulate(value, element, itemsStore);
                checkAndSetPayload(value);
            }
            if (call.name == "reloadPayloadElement" && call.args[0].find(el => el.fieldId == element.id)) {
                let value = call.args[0].find(el => el.fieldId == element.id).value;
                checkAndSetPayload(value);
            }
        })
        return () => {
            _disposerOnAction();
            _disposerOnAction = undefined;
        }
    }, []);

    const checkAndSetPayload = (payloadValue, activeRecord = undefined) => {
        if(payload.readOnly)
            return null;
        let errorElement = itemsStore.getErrorElement(element, payloadValue);
        setPayload({ ...payload, value: payloadValue, valid: errorElement.valid, activeRecord: (activeRecord || payload.activeRecord) });
        console.log(errorElement.errorList);
        setErrorList(errorElement.errorList);
        return errorElement;
    }

    return {
        itemsStore, statusStore, integrationsStore, authenticationStore,
        element,
        payload, setPayload, checkAndSetPayload,
        errorList, setErrorList,
        modalVisible, setModalVisible,
        loading, setLoading,
    }
}


export const initializeElement = useInitializeElement;

export const ElementBase: FunctionComponent<IElementBaseProps> = ({ ...props }) => {
    const element = props.element;
    //console.log("ElementBase",element.type, element.name, element.id);
    let errors: any[] = []
    if (props.errors?.length > 0) {
        errors = props.errors.map((error, idx) => {
            return (<Box key={`error${idx}`} ><Text fontWeight="bold" color={"red.500"} px="10" py="3">{error}</Text></Box>)
        })
    }

    return element.type == "group" ? <>{props.children}</> : (
        <Box
            display={(element.templateData?.hidden ? "none" : "flex")}
            borderBottomWidth={props.borderBottomWidth == undefined ? 1 : props.borderBottomWidth}
            borderBottomColor="gray.300"
            my="2"
            mx="1"
            paddingTop={15}
            //paddingRight={2}
            paddingX={2}
            backgroundColor={element.templateData?.backgroundColor || "#fff"}
            style={props.boxStyle}
        >{props.hideTitleBar ? <></> : <HStack alignItems={"center"}>
            <Box key="leftButtonsSection" style={{ maxWidth: 120, margin: 0, flexDirection: "row-reverse" }} >{props.leftButton}</Box>
            <Box key="NameSection" flexShrink={1}><Text bold color={element.templateData?.textColor || "#000"}>{element.name ?? element.label}</Text></Box>
            <Spacer></Spacer>
            <Box key="RightButtonSection" style={{ maxWidth: 120, margin: 0, flexDirection: "row-reverse" }} >{getIconHasError(errors.length > 0)}{props.rigthButton}</Box>
        </HStack>
            }
            {props.children}
            {props.forReview != true && errors.length > 0 ? <VStack borderTopWidth={1} borderColor="danger.500">{errors}</VStack> : <></>}
        </Box>
    );
};