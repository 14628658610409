import React, { FC, FunctionComponent } from "react"
import { observer } from "mobx-react-lite"

import { SwipeRow } from 'react-native-swipe-list-view';


import { AlertDialog, Badge, Box, Button, Checkbox, HStack, Icon, IconButton, Pressable, ScrollView, Spacer, StatusBar, Text, View, VStack } from "native-base"
import { MaterialIcons, Ionicons, MaterialCommunityIcons, FontAwesome5 } from "@expo/vector-icons";

import { HomeStackParamList, useBackButtonHandler } from "../../navigators"
import { DrawerScreenProps } from "@react-navigation/drawer"
import { useStores } from "../../models"
import { ITEM_STATUS } from "../../voolks/types";
import { getIconItem, getItemTitle, openPDF } from "../../utils/itemCommonFunctions";

export const ItemsListScreen: FC<DrawerScreenProps<HomeStackParamList, "ItemsList">> = observer(function FormsListScreen({ navigation, route }) {
  const fcName = "ItemsListScreen";
  const { formsStore, itemsStore, statusStore, tasksStore } = useStores();
  const [alertDialog, setAlertDialog] = React.useState(undefined);
  const [showCheck, setShowCheck] = React.useState<boolean>(false);
  const [itemsSelected, setItemsSelected] = React.useState<any[]>([]);
  const department = formsStore.mySubscriptions().subscriptions.find((item: any) => item.id == route.params.deptId);
  if (department == undefined) {
    navigation.navigate("Settings");
    return <><Text></Text></>
  }
  const form = department.forms.find((form: any) => form.id == route.params.formId);
  if (form == undefined) {
    navigation.navigate("FormsList", { deptId: department.id });

    return <><Text></Text></>
  }
  const taskId = route.params.taskId;
  const tasks = taskId != undefined ? tasksStore.myTasks()?.tasks : [];
  const task: any = tasks?.find((item: any) => item.id == taskId)
  const swipeRows: any[] = [];
  const lastPressedBtn = React.useRef(0);
  let swipeRow_selected: any;
  //let someToUpload: number = 0;
  let my_items: any = React.useMemo(() => {
    return itemsStore.myItems({ deptId: department.id, formId: form.id }).reverse();
  }, [itemsStore.itemsByUsersDB.length, route.params.formId]);
  const goBack = () => {
    if (taskId)
      navigation.navigate("TaskDetails", { taskId: taskId, fromTaskList: taskId != undefined });
    else
      navigation.navigate("FormsList", { deptId: department.id });

  }

  useBackButtonHandler(goBack);

  const newItem = async () => {
    console.log(fcName, "newItem", "init");
    if ((new Date().getTime() - lastPressedBtn.current) < 3000)
      return;
    lastPressedBtn.current = new Date().getTime();
    await itemsStore.new(department.id, form.id, taskId)
    _openItem();
  };

  const openItem = async (itemSelected: any = undefined) => {
    if ((new Date().getTime() - lastPressedBtn.current) < 3000)
      return;
    lastPressedBtn.current = new Date().getTime();
    _openItem(itemSelected);

  }

  const _openItem = async (itemSelected: any = undefined) => {
    console.log(fcName, "_openItem", "Init");
    if (itemSelected)
      await itemsStore.activateItem(itemSelected)
    if (itemsStore.itemActiveIDX != undefined) {
      let preValues = (task?.payload ?? []).reduce((fields, metadata) => {
        if (metadata?.fieldId)
          fields[metadata.fieldId] = metadata.value;
        return fields;
      }, {});

      await itemsStore.makePayloadFromFormPages(form.pages, preValues);
      navigation.navigate("ItemDetails", {
        itemStartedAt: new Date().getTime(),
        formId: form.id,
        deptId: department.id,
        forReview: itemsStore.itemActive.status != ITEM_STATUS.draft.toString(),
        taskId: taskId,
        pageActive: 0,
        rnd: new Date().getTime()
      })
    }
    console.log(fcName, "_openItem", "Fin");
  }

  const removeItemsDialog = async (item: any = undefined) => {
    let lstIdx: number[] = [];
    let alert = { title: "", message: "" };
    if (item != undefined) {
      let itemIdx: number = itemsStore.getIDXofItem(item)
      if (itemIdx > -1) {
        lstIdx.push(itemIdx);
      }
      alert.title = "Borrar registro";
      alert.message = `¿Estas seguro de borrar el registro  ${getItemTitle(form, item)}?`;
    } else {
      let lstTitles: string[] = [];
      itemsSelected.forEach((item) => {
        let itemIdx: number | undefined = itemsStore.getIDXofItem(item)
        if (itemIdx != undefined) {
          lstTitles.push(getItemTitle(form, item));
          lstIdx.push(itemIdx)
        }
      })
      alert.title = "Borrar multiples registros";
      alert.message = `¿Estas seguro de borrar los registros  ${lstTitles.join(", ")}?`;
    }
    if (lstIdx.length > 0) {
      setAlertDialog({
        ...alert,
        action: () => {
          swipeRows.map(async (row) => {
            await row?.closeRow();
          });
          lstIdx.sort((a, b) => { return b - a; }).forEach(idx => itemsStore.removeItem(idx));
          setShowCheck(false);
        }
      })
    }
  }

  const checkAll = async () => {
    let selItems = Array.from(itemsSelected);
    await my_items.map(async (item: any) => {
      if (isItemSelected(item)) {
        selItems.splice(selItems.indexOf(item), 1)
      } else {
        selItems.push(item);
      }
    });
    setItemsSelected(selItems);
  }

  const isItemSelected = (item: any) => {
    return itemsSelected.includes(item)
  }
  const selectItem = (item: any) => {
    console.log(new Date().toISOString(), "selectItem IN");
    let selItems = Array.from(itemsSelected);
    if (isItemSelected(item)) {
      selItems.splice(selItems.indexOf(item), 1)
    } else {
      selItems.push(item);
    }
    setItemsSelected(selItems);
    console.log(new Date().toISOString(), "selectItem OUT");
    return selItems;
  }
  const ItemsListRows: FunctionComponent = () => {
    return <>
      {my_items
        .map((item: any, idx: number) => {
          return (
            <SwipeRow key={`SwipeRow${idx}`} leftOpenValue={110} rightOpenValue={-55}
              ref={(c) => { swipeRows[idx] = c; }}
              onRowOpen={() => {
                if (swipeRow_selected && swipeRow_selected !== swipeRows[idx]) { swipeRow_selected.closeRow(); }
                swipeRow_selected = swipeRows[idx];
              }}

            >
              <View style={{ minHeight: 45, flexWrap: "wrap", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                <View ><IconButton isDisabled={item.status != ITEM_STATUS.sent} onPress={() => openPDF(itemsStore, item)} w="55" borderRadius={0} bgColor={"success.500"} icon={<Icon as={FontAwesome5} name="file-pdf" size="md" color="white" />} /></View>
                <View ><IconButton isDisabled={item.status != ITEM_STATUS.sent} onPress={() => openPDF(itemsStore, item, true)} w="55" borderRadius={0} bgColor={"yellow.500"} icon={<Icon as={Ionicons} name="mail" size="md" color="white" />} /></View>
                <Spacer></Spacer>
                <View ><IconButton w="55" borderRadius={0} bgColor={"danger.500"}
                  icon={<Icon as={Ionicons} name="trash-outline" size="md" color="white" />}
                  onPress={() => removeItemsDialog(item)}
                /></View>
              </View>
              <View borderBottomWidth={1} borderBottomColor={"light.300"} style={{ minHeight: 45, flexWrap: "wrap", backgroundColor: "white", justifyContent: "center", }}  >
                <Pressable px="3" _pressed={{ bg: "secondary.600:alpha.20" }} _hover={{ bg: "secondary.600:alpha.20" }}
                  onPress={() => openItem(item)} style={{ flexDirection: "row" }}>
                  <HStack py="3" width={"full"} alignItems="center">
                    {getIconItem(item.status)}
                    <Text px="3" fontSize="md">{getItemTitle(form, item)}</Text>
                    {item.taskId && taskId == undefined ?
                      <IconButton w="55" borderRadius={0} colorScheme="secondary" icon={<Icon as={MaterialIcons} name="event" size="lg" />}
                        onPress={() => { navigation.navigate("TaskDetails", { taskId: item.taskId, fromTaskList: false }) }} />
                      :
                      <></>
                    }
                    <Spacer></Spacer>
                    {showCheck ?
                      (<Pressable onPress={() => { selectItem(item) }} ><Box p="2">
                        {isItemSelected(item) ? <Icon mx="2" size="lg" name="checkbox-outline" as={Ionicons} /> : <Icon mx="2" size="lg" name="square-outline" as={Ionicons} />}
                      </Box>
                      </Pressable>)
                      :
                      <Icon name="arrow-forward" as={Ionicons} size="md" />
                    }
                  </HStack>
                </Pressable>
              </View>

            </SwipeRow>

          )
        })}
    </>;

  }
  const someToUpload =
    my_items.reduce((counter, item) => {
      return counter + (item.status == ITEM_STATUS.closed ? 1 : 0)
    }, 0);

  const cancelRef = React.useRef();
  let leftButton = <IconButton variant={"solid"}
    icon={<Icon size="xl" as={Ionicons} name="arrow-back" />}
    accessibilityHint="Mantener apretado para desplegar menú lateral"
    onPress={goBack} />;
  return (<>
    <StatusBar backgroundColor={"#3700B3"} barStyle="light-content" />
    <Box safeAreaTop bg="primary.400" />
    <HStack bg="primary.400" px="0" py="0" justifyContent="space-between" alignItems="center" w="100%">
      <HStack alignItems="center" maxW={statusStore.windowDimensions.width - 140}>
        {leftButton}
        <Text color="white" fontSize="18" ml={1} >
          {form.name}
        </Text>
      </HStack>
      <HStack>
        {someToUpload > 0 ?
          <VStack>
            <Badge // bg="red.400"
              colorScheme="danger" rounded="full" mb={-5} mr={0}
              zIndex={1}
              variant="solid" alignSelf="flex-end" _text={{
                fontSize: "2xs"
              }}>
              {someToUpload}
            </Badge>
            <IconButton variant={"solid"} onPress={() => itemsStore.upload({ deptId: department.id, formId: form.id })}
              icon={<Icon as={MaterialCommunityIcons} name="cloud-upload" size="xl" />}
            />
          </VStack>
          : null
        }
        {showCheck ? <IconButton variant={"solid"} onPress={() => checkAll()}
          icon={<Icon as={MaterialCommunityIcons} name="checkbox-multiple-marked" size="xl" />}
        /> : <IconButton variant={"solid"} onPress={newItem}
          icon={<Icon as={Ionicons} name="add" size="xl" />}
        />}
      </HStack>
    </HStack>
    {task ?
      <HStack key={`r2`} bgColor={"light.200"} px="5" py="2" my="0" justifyContent="flex-start" alignItems="center" w="100%" borderBottomWidth={1} borderBottomColor={"light.300"} >
        <Icon name="event" size="lg" mr="3" color={"secondary.400"} as={MaterialIcons} />
        <Text fontSize="md" color="secondary.400"> {task.name}</Text>
      </HStack>
      :
      <></>
    }
    <VStack bg="white" flex={1}>
      {my_items.length == 0 ? <><HStack alignItems="center" height={"100%"} >
        <Button colorScheme={"secondary"} w="100%" leftIcon={<Icon as={Ionicons} name="add" size="xl" />}
          onPress={newItem}>
          INGRESAR NUEVO REGISTRO
        </Button>
      </HStack>
      </>
        :
        <>
          <ScrollView><ItemsListRows /></ScrollView>
          <Spacer />
          <HStack>
            <Spacer />
            {showCheck && itemsSelected.length > 0 ?
              <IconButton variant={"outline"} size="lg" borderRadius={"full"} m="3" colorScheme="danger" onPress={() => removeItemsDialog()}
                icon={<Icon as={Ionicons} name="checkmark" />}
              />
              :
              <></>}
            <IconButton variant={showCheck ? "solid" : "outline"} size="lg" borderRadius={"full"} m="3" colorScheme="danger" onPress={() => setShowCheck(!showCheck)}
              icon={<Icon as={Ionicons} name="trash-outline" />}
            /></HStack>
        </>
      }
    </VStack>
    {alertDialog == undefined ? <></> :
      <AlertDialog leastDestructiveRef={cancelRef} isOpen={alertDialog != undefined} >
        <AlertDialog.Content>
          <AlertDialog.CloseButton />
          <AlertDialog.Header>{alertDialog?.title}</AlertDialog.Header>
          <AlertDialog.Body>{alertDialog?.message}</AlertDialog.Body>
          <AlertDialog.Footer>
            <Button ref={cancelRef} onPress={() => setAlertDialog(undefined)}>
              Cancelar
            </Button>
            <Button colorScheme="red" ml={3} onPress={() => { alertDialog?.action(); setAlertDialog(undefined) }}>
              Aceptar
            </Button>
          </AlertDialog.Footer>
        </AlertDialog.Content>
      </AlertDialog>
    }

  </>
  )
})