import { Alert, Platform } from 'react-native'

export const urlFileToBase64 = async (urlFile) =>{
    const data = await fetch(urlFile);
        const blob = await data.blob();
        const reader = new FileReader();
        
        return await (new Promise((resolve,reject)=>{
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
                resolve(reader.result);
            };
        }))    
}

export const convertImageToBase64 = async (imgUrl, callback) => {
    if (Platform.OS == "web") {
        const image = new Image();
        image.crossOrigin = 'anonymous';
        image.onload = () => {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            canvas.height = image.naturalHeight;
            canvas.width = image.naturalWidth;
            ctx.drawImage(image, 0, 0);
            const dataUrl = canvas.toDataURL();
            callback && callback(dataUrl)
        }
        image.src = imgUrl;
    } else {       
        const data = await fetch(imgUrl);
        const blob = await data.blob();
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
            const base64data = reader.result;            
            callback && callback(base64data)
        };
    }
}


export const shouldTaskAppearToday = (task: any, selectedDate: Date) => {
    enum weekDays { 'sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday' };
    let checkDate: Date = new Date(selectedDate);// checkDate.setHours(23, 59, 59);
    let startDate: Date = new Date(task.startDate);    
    let endRepeat = task.repeatData.repeatEnds ? new Date(task.repeatData.repeatTill) : null;

    if (endRepeat) {
        endRepeat.setHours(23, 59, 59);
    }

    if (!task.repeat) {
        return true;
    }

    let daysDiff = (new Date(checkDate.toLocaleDateString("af")).getTime() - new Date(startDate.toLocaleDateString("af")).getTime() )/ (1000 * 3600 * 24);  //Math.ceil((checkDate.valueOf() - startDate.valueOf()) / (1000 * 3600 * 24))
    if (daysDiff < 0) {
        
        return false;
    }

    //if repeat ends, we check if we should show it...
    if (endRepeat && (endRepeat.valueOf() - checkDate.valueOf()) < 0) {
        return false;
    }

    if (task.repeatData.repeatType === 'daily') {
        let diff = daysDiff % task.repeatData.repeatEvery;
        //if the diff between days passed and repeat every is exact, we know we have to show the task.
        if (diff==0) {
            return true;
        }
        return false;
    }

    if (task.repeatData.repeatType === 'weekly') {
        //we check to see if selectedDate week day is true or false...
        if (task.repeatData.repeatWeekDays[weekDays[checkDate.getDay()]]) {
            return true;
        }
        return false;
    }
}



const alertPolyfill = (title, description, options, extra) => {
    const result = window.confirm([title, description].filter(Boolean).join('\n'))

    if (result) {
        const confirmOption = options.find(({ style }) => style !== 'cancel')
        confirmOption && confirmOption.onPress()
    } else {
        const cancelOption = options.find(({ style }) => style === 'cancel')
        cancelOption && cancelOption.onPress()
    }
}

export const alert = Platform.OS === 'web' ? alertPolyfill : Alert.alert