import { Box, Heading, HStack, Icon, IconButton, Input, Modal, Spacer, Spinner, StatusBar, Text, TextArea, View } from 'native-base';
import React, { FC } from 'react';
import { ElementBase, IElementBaseProps, initializeElement, ModalFull } from '..';
import { BarCodeScanner } from 'expo-barcode-scanner';
import { StyleSheet } from 'react-native';
import { Ionicons, } from "@expo/vector-icons";
import { Camera, CameraType } from 'expo-camera';
import BarcodeMask from 'react-native-barcode-mask';
import { getDataFromIntegration } from '../../utils/itemCommonFunctions';

export const QrElement: FC<IElementBaseProps> = (({ ...props }) => {
    const { itemsStore, element, integrationsStore,
        payload, setPayload,
        errorList, setErrorList,
        modalVisible, setModalVisible,
        loading, setLoading,statusStore
    } = initializeElement(props);

    const [scanned, setScanned] = React.useState(false);

    const handleChange = async (value: string | undefined) => {
        value = value == undefined ? "" : value.toString();
        let errorElement = itemsStore.getErrorElement(element, value);
        setErrorList(errorElement.errorList)
        setPayload({ ...payload, value: value, valid: errorElement.valid });
        setModalVisible(false);
        if (modalVisible && element.integration?.id) { //Solo autocompleta ante escaneo de codigo
            setLoading(true);
            let data = await getDataFromIntegration(element, itemsStore.getPayloadElement, props.parentId, integrationsStore.getIntegration);                        
            itemsStore.populatePayload(data, element.integration.populateValues, props.parentId);
            setTimeout(() => {
                statusStore.reloadPayloadElementFromPopulate(data, element.integration.populateValues, props.parentId);
                setLoading(false);
            }, 200);
           
        }
    }

    const handleBarCodeScanned = ({ type, data }) => {
        handleChange(data);

    };

    const actionButton = props.forReview ? undefined : loading ? <Spinner size="lg" /> : <IconButton onPress={() => { setModalVisible(!modalVisible); setScanned(false); }} icon={<Icon as={Ionicons} name="barcode-outline" size="md" />} />;
    return <ElementBase errors={errorList} element={element} forReview={props.forReview} rigthButton={actionButton} >
        {props.forReview ?
            <Text style={{ color: (element.templateData.textColor ? element.templateData.textColor : "#000") }}>
                {payload.value}
            </Text>
            :
            <Box>
                <Box><Input variant="unstyled"
                    keyboardType={"default"}
                    color={element.templateData.textColor || "#000"}
                    onChangeText={handleChange}
                    placeholder={element.templateData.placeholder || ""}
                    value={payload.value?.toString() || ""} size="md"
                /></Box>

                {modalVisible && <ModalFull
                    visible={modalVisible}
                    title="Escanear código"
                    closeButton_onPress={() => { setModalVisible(!modalVisible); }}                                        
                >
                    <View style={styles.container}>
                        <Camera style={styles.camera} type={CameraType.back}
                            onBarCodeScanned={scanned ? undefined : handleBarCodeScanned}
                        ><BarcodeMask /></Camera>
                    </View>
                </ModalFull>}

                
            </Box>
        }
    </ElementBase>
});

const styles = StyleSheet.create({
    container: {

        flex: 1,
        justifyContent: 'center', width: "100%"
    },
    camera: {
        flex: 1, alignItems: "flex-end"

    },
    buttonContainer: {
        flex: 1,
        flexDirection: 'row',
        backgroundColor: 'transparent',
        margin: 64,
    },
    button: {
        flex: 1,
        alignSelf: 'flex-end',
        alignItems: 'center',
    },
    text: {
        fontSize: 24,
        fontWeight: 'bold',
        color: 'white',
    },
});