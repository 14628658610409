import React, { FC, useState } from "react"
import { observer } from "mobx-react-lite"
import { BackHandler, ImageBackground, Platform, ScrollView, ViewStyle } from "react-native"

import { Badge, Box, Center, CheckIcon, Container, Heading, HStack, Icon, IconButton, Pressable, Select, Spacer, StatusBar, Text, View, VStack } from "native-base"
import { MaterialIcons, Ionicons, SimpleLineIcons, FontAwesome5, MaterialCommunityIcons } from "@expo/vector-icons";

import { DrawerScreenProps } from "@react-navigation/drawer"
import { useStores } from "../../models"
import { HomeStackParamList, useBackButtonHandler } from "../../navigators"


export const FormsListScreen: FC<DrawerScreenProps<HomeStackParamList, "FormsList">> = observer(function FormsListScreen({ navigation, route }) {
  const { authenticationStore, formsStore, statusStore, itemsStore } = useStores();
  const user = authenticationStore.userInfo.user;
  const department = formsStore.mySubscriptions().subscriptions.find((item: any) => route.params?.deptId ? item.id == route.params.deptId : true);
  useBackButtonHandler(() => navigation.openDrawer())
  if (department == undefined) {
    navigation.openDrawer()
    return <></>;
  }
  let items = itemsStore.myItems({ deptId: department.id });
  let my_items_closed = items?.filter((item: any) => { if (item.deptId == department.id && item.status == "closed") return item });
  let my_items_draft = items?.filter((item: any) => { if (item.deptId == department.id && item.status == "draft") return item });

  let leftButton = <IconButton variant={"solid"}
    icon={<Icon size="xl" as={MaterialIcons} name="menu" />}
    onPress={() => navigation.openDrawer()} />;
  return (<>

    <StatusBar backgroundColor={"#3700B3"} barStyle="light-content" />
    <Box safeAreaTop bg="primary.400" />
    <HStack bg="primary.400" px="0" py="0" justifyContent="space-between" alignItems="center" w="100%">
      <HStack alignItems="center" maxW={statusStore.windowDimensions.width - 140} >
        {leftButton}
        <Text color="white" fontSize="18" >
          {department.name}
        </Text>
      </HStack>
      <HStack>
        {my_items_closed.length > 0 ?
          <VStack marginRight={2}>
            <Badge
              colorScheme="danger" rounded="full" mb={-5} mr={-1}
              zIndex={1}
              variant="solid" alignSelf="flex-end" _text={{
                fontSize: "2xs"
              }}>
              {my_items_closed.length}
            </Badge>
            <IconButton variant={"solid"} onPress={() => itemsStore.upload({ deptId: department.id })}
              icon={<Icon as={MaterialCommunityIcons} name="cloud-upload" size="xl" />}
            />
          </VStack>
          : null
        }
        <IconButton variant={"solid"} onPress={() => formsStore.getSubscriptions()}
          icon={<Icon as={Ionicons} name="refresh-outline" size="xl" />}
        />
      </HStack>
    </HStack>
    <VStack bg="white" flex={1} shadow={"1"} >
      <HStack bgColor={"light.200"} px="5" py="2" my="0" justifyContent="flex-start" alignItems="center" w="100%" borderBottomWidth={1} borderBottomColor={"light.300"} >
        <Text fontSize="14" color="secondary.400">Formularios Disponibles</Text>
      </HStack><ScrollView >
        {[].concat(department.forms).sort((a, b) => { return a.name.toUpperCase() < b.name.toUpperCase() ? -1 : 1 }).map((form: any, idx: number) => {
          let items_closed = my_items_closed?.filter((item: any) => { if (item.formId == form.id) return item }).length
          let items_draft = my_items_draft?.filter((item: any) => { if (item.formId == form.id) return item }).length
          return (
            <Box key={form.id} px="0" >
              <Pressable _pressed={{ bg: "secondary.600:alpha.20" }} _hover={{ bg: "secondary.600:alpha.20" }} onPress={() => navigation.navigate("ItemsList", { deptId: department.id, formId: form.id, forReview: false })} >
                <HStack py="2" space={[2, 3]} px={2} justifyContent="center" alignItems={"center"} borderBottomWidth={1} borderBottomColor={"light.200"}>
                  <Text width={"70%"} fontSize={"md"} style={{ flexWrap: "wrap", justifyContent: "center", }} >
                    {form.name}
                  </Text>
                  <Spacer />
                  {items_draft > 0 ? <Badge bgColor="opened.400" borderRadius={"full"} size="2xs"><Text fontSize="xs" color={"white"}>{items_draft}</Text></Badge> : <></>}
                  {items_closed > 0 ? <Badge bgColor="closed.400" borderRadius={"full"} size="2xs"><Text fontSize="xs" color={"white"}>{items_closed}</Text></Badge> : <></>}
                  <Icon name="arrow-forward" as={Ionicons} size="md" />
                </HStack>
              </Pressable>
            </Box>)
        })}
      </ScrollView>
    </VStack>

  </>
  )
})

const $root: ViewStyle = {
  flex: 1,
}
