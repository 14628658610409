import { Box, HStack, Icon, IconButton, Input, Pressable, Spacer, Spinner, Switch, Text, VStack } from 'native-base';
import React, { FunctionComponent } from 'react';

import { MaterialIcons, Ionicons, } from "@expo/vector-icons";
import { ElementBase, IElementBaseProps, initializeElement } from '..';
import { getGPSCoords } from '../../utils/itemCommonFunctions';

export const GeoElement: FunctionComponent<IElementBaseProps> = ({ ...props }) => {
    const {
        itemsStore, element,
        payload, setPayload,
        errorList, setErrorList,
        loading, setLoading
    } = initializeElement(props);

    const getGPS = async () => {
        if(props.forReview)
            return null;
        setLoading(true);
        try {
            let location = await getGPSCoords();
            let errorElement = itemsStore.getErrorElement(element, location ? location : undefined);
            setErrorList(errorElement.errorList)
            setPayload({ ...payload, value: location, valid: errorElement.valid });
            setLoading(false);
        } catch (e) {
            console.log("GeoElement","getGPS",e);
            let errorElement = itemsStore.getErrorElement(element, undefined);
            setErrorList(errorElement.errorList.concat(["No tiene permiso al uso de la Geolocalizacion"]))
            setPayload({ ...payload, value: undefined, valid: errorElement.valid });
            setLoading(false);
        }
    }
    const toggle = props.forReview ? undefined : loading ? <Spinner size="sm" /> : (<IconButton onPress={getGPS} icon={<Icon as={MaterialIcons} name="my-location" size="md" />} />)
    return <ElementBase errors={errorList} element={element} rigthButton={toggle} forReview={props.forReview} >
        <Pressable _pressed={{ bg: "secondary.600:alpha.20" }} _hover={{ bg: "secondary.600:alpha.20" }} onPress={getGPS}>
            <HStack>
                <Icon as={Ionicons} mr="3" name="location" size="md" />
                <Text color={element.templateData?.textColor || "#000"}>
                    {payload?.value ? `${payload.value.coords?.latitude},${payload.value.coords?.longitude}` : ' '}
                </Text>
            </HStack>
        </Pressable>
    </ElementBase>
}