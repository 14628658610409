import { Instance, SnapshotIn, SnapshotOut, flow, types } from "mobx-state-tree"
import { withSetPropAction } from "./helpers/withSetPropAction"
import { tasksByUserModel } from "./helpers/typesModels";
import { withStatusModel } from "./helpers/withStatusModel";

/**
 * Model description here for TypeScript hints.
 */
export const TasksStoreModel = types
  .model("TasksStore")
  .props({
    tasksByUserDB: types.array(tasksByUserModel),
  })
  .actions(withSetPropAction)
  .views((store) => ({
    myTasks: () => {
      console.log("Mytasks",store.tasksByUserDB.length)
      if(store.tasksByUserDB.length==0){
        return { tasks: [] };
      }
      let mytasks = store.tasksByUserDB
        .filter(item => (
          //@ts-ignore
          item.userId == store.parent.authenticationStore.userIdLogged
        ));
      return JSON.parse(JSON.stringify(mytasks || [{ tasks: [] }]))[0];
    },
  })) // eslint-disable-line @typescript-eslint/no-unused-vars
  .actions(withSetPropAction)
  .extend(withStatusModel)
  .actions(store => ({
    updTasks: (tasks) => {
      tasks = tasks.map(task => {
        task.formName = task.form?.name;
        task.deptId = task.form?.departmentId;
        console.log("ACA");
        delete task.form
        return { ...task }
      })
      let myTasks = store.tasksByUserDB.find(_tasksUser => _tasksUser.userId == store.parent.authenticationStore.userIdLogged)
      if (myTasks != undefined) {
        myTasks.tasks = tasks;
        myTasks.lastSync = Date.now();
      } else {
        myTasks = tasksByUserModel.create({
          userId: store.parent.authenticationStore.userIdLogged,
          tasks, lastSync: Date.now()
        })
        store.tasksByUserDB.push(myTasks);
      }
      store.setProp("tasksByUserDB", store.tasksByUserDB.toJSON());
    },
  }))
  .actions((store) => ({
    getTasks: flow(function* getTasks() {
      store.setStatus("pending");
      const loadInfoID = store.addLoadingInfo({ title: "Descargando Tareas asignadas", spinner: true })
      const response = yield store.api().getTasks();
      if (response && response.status == 200) {
        const json = yield response.json();
        store.updTasks(json)
        store.updLoadingInfo({ id: loadInfoID, spinner: false });
      } else if (response && response.status == 401) {
        store.parent.authenticationStore.signOut();
      }
      store.setStatus("done");
    }),
  })) // eslint-disable-line @typescript-eslint/no-unused-vars

export interface TasksStore extends Instance<typeof TasksStoreModel> { }
export interface TasksStoreSnapshotOut extends SnapshotOut<typeof TasksStoreModel> { }
export interface TasksStoreSnapshotIn extends SnapshotIn<typeof TasksStoreModel> { }
export const createTasksStoreDefaultModel = () => types.optional(TasksStoreModel, {})
