import React, { FC } from "react"
import { observer } from "mobx-react-lite"
import "react-native-gesture-handler"; //this should be the first import in your code

import { PermissionsAndroid, Platform } from 'react-native';
import { HomeNavigator, useNavigationPersistence, } from "../navigators"
import { NavigationContainer } from "@react-navigation/native";
import { navigationRef, } from "../navigators/navigationUtilities"

import * as storage from "../utils/storage"

export const NAVIGATION_PERSISTENCE_KEY = "HOME_NAVIGATION_STATE"

const checkPermissions = async () => {
  try {    
    if (Platform.OS != "ios") {      
      await PermissionsAndroid.check(PermissionsAndroid.PERMISSIONS.CAMERA).then(async val => {
        if (!val)
          await PermissionsAndroid.request(PermissionsAndroid.PERMISSIONS.CAMERA);
      });
      await PermissionsAndroid.check(PermissionsAndroid.PERMISSIONS.RECORD_AUDIO).then(async val => {
        if (!val)
          await PermissionsAndroid.request(PermissionsAndroid.PERMISSIONS.RECORD_AUDIO);
      });
      await PermissionsAndroid.check(PermissionsAndroid.PERMISSIONS.READ_EXTERNAL_STORAGE).then(async val => {
        if (!val)
          await PermissionsAndroid.requestMultiple([PermissionsAndroid.PERMISSIONS.READ_EXTERNAL_STORAGE,]);
      });
      await PermissionsAndroid.check(PermissionsAndroid.PERMISSIONS.ACCESS_FINE_LOCATION).then(async val => {
        if (!val)
          await PermissionsAndroid.requestMultiple([PermissionsAndroid.PERMISSIONS.ACCESS_FINE_LOCATION, PermissionsAndroid.PERMISSIONS.ACCESS_COARSE_LOCATION,]);
      });
      await PermissionsAndroid.check(PermissionsAndroid.PERMISSIONS.ACCESS_COARSE_LOCATION).then(async val => {
        if (!val)
          await PermissionsAndroid.requestMultiple([PermissionsAndroid.PERMISSIONS.ACCESS_FINE_LOCATION, PermissionsAndroid.PERMISSIONS.ACCESS_COARSE_LOCATION,]);
      });
    }
  } catch (e) {
    console.log(e);
  }
}

// @ts-ignore
export const HomeScreen: FC = observer(function HomeScreen(_props) {
  const {
    initialNavigationState,
    onNavigationStateChange,
    isRestored: isNavigationStateRestored,
  } = useNavigationPersistence(storage, NAVIGATION_PERSISTENCE_KEY)

  if (!isNavigationStateRestored ) return null    
  checkPermissions();
  
  return <NavigationContainer  ref={navigationRef} initialState={initialNavigationState}
   onStateChange={onNavigationStateChange} ><HomeNavigator/></NavigationContainer>;
  


});
