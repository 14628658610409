/**
 * The app navigator (formerly "AppNavigator" and "MainNavigator") is used for the primary
 * navigation flows of your app.
 * Generally speaking, it will contain an auth flow (registration, login, forgot password)
 * and a "main" flow which the user will use once logged in.
 */
import {
  DarkTheme,
  DefaultTheme,
  NavigationContainer,
} from "@react-navigation/native"
import { createNativeStackNavigator } from "@react-navigation/native-stack"
import { StackScreenProps } from "@react-navigation/stack"
import { observer } from "mobx-react-lite"
import React from "react"
import { useColorScheme } from "react-native"
import Config from "../config"
import { customApp } from "../config";

import { useStores } from "../models"
import {
  HomeScreen, LoginScreen,
} from "../screens"
import { navigationRef, useBackButtonHandler } from "./navigationUtilities"
import { Box, HStack, Icon, IconButton, ScrollView, Spinner, StatusBar, Text, VStack } from "native-base"
import { MaterialIcons, Ionicons, MaterialCommunityIcons, FontAwesome5 } from "@expo/vector-icons";
export type AppStackParamList = {
  Login: undefined,
  Home: undefined,
}

/**
 * This is a list of all the route names that will exit the app if the back button
 * is pressed while in that screen. Only affects Android.
 */
//const exitRoutes = Config.exitRoutes

export type AppStackScreenProps<T extends keyof AppStackParamList> = StackScreenProps<AppStackParamList, T>

const Stack = createNativeStackNavigator<AppStackParamList>()

interface NavigationProps extends Partial<React.ComponentProps<typeof NavigationContainer>> { }

export const AppNavigator = observer(function AppNavigator(props: NavigationProps) {
  const colorScheme = useColorScheme()
  const { authenticationStore, formsStore,statusStore,itemsStore } = useStores();
  if (authenticationStore.userIdLogged == "") {
    if (customApp.user && customApp.user != "") {
      setTimeout(() => authenticationStore.signIn({ username: customApp.user, password: customApp.pass }), 500);
      return <><Spinner></Spinner></>;
    }
    return <LoginScreen />

  } else {
    const user = authenticationStore.userInfo.user;
    const subscriptions = JSON.parse(JSON.stringify(formsStore.mySubscriptions().subscriptions));
    if (subscriptions.length > 0)
      return <HomeScreen />
    return <>
    <StatusBar backgroundColor={"#3700B3"} barStyle="light-content" />
    <Box safeAreaTop bg="primary.400" />
    <HStack bg="primary.400" px="0" py="0" justifyContent="space-between" alignItems="center" w="100%">
      <HStack alignItems="center" maxW={statusStore.windowDimensions.width - 140}>
        <VStack>
          <Text color="white" fontSize="18" m="2" >
            {customApp.displayName}
          </Text>          
        </VStack>
      </HStack>
      </HStack>       
      <ScrollView>
        <VStack alignItems="center">
        <Spinner m="10"/>
        <Text>Sin Formularios asignados</Text>
        <IconButton variant="ghost" onPress={async () => {  await itemsStore.upload({}); formsStore.getSubscriptions();}}
            icon={<Icon as={MaterialCommunityIcons} name="cloud-sync-outline" size="xl" />}
          />    
          </VStack>     
      </ScrollView>
      <HStack alignItems={"center"}>
        <IconButton variant={"light"}
          size="md"
          colorScheme="danger" onPress={authenticationStore.signOut}
          icon={<Icon as={MaterialIcons} name="logout" />}
        />
        <Text>{user?.firstName} {user?.lastName}</Text>
      </HStack>
    </>;
  }

})
