
import React from "react"
import { FormulaElement, MediaElement, CheckElement, DateTimeElement, DurationElement, ElementBase, GeoElement, MultipleElement, NumberElement, QrElement, TextElement, TimestampElement, TitleElement, GroupElement, SimpleListElement, PopulateElement, SignatureElement, ValidateElement } from "../../../components"
import {Text} from "native-base";

export const FieldsList = ({ itemsStore, fields, parentId = undefined, forReview = false }) => {
    console.log("FieldsList", "parentId",parentId)
    forReview = forReview == true;

    const putFields = (fields: any[], parentId: string = undefined) => {
        //console.log("ItemDetailsScreen", "putFields", "parentId", parentId)
        const payload = itemsStore.itemActive.payload;
        return fields?.map((field: any, idx: number) => {
            //console.log("ItemDetailsScreen", "putFields", "parentId", parentId, field.type,field.id)
            if (!forReview
                || field.type == 'checkbox' || field.type == 'group' || field.type == 'simplelist' || field.type == 'title'
                || (payload.get(field.id) && JSON.stringify(payload.get(field.id).value) != "" && payload.get(field.id).value != null)) {
                if (field.isArchived)
                    return (null);
                let fieldType = field.type == "sendemail" ? (field.templateData.asSelect ? "select" : "text") : field.type;
                switch (fieldType) {
                    case 'time':
                    case 'date':
                        return <DateTimeElement parentId={parentId} element={field} key={"DateTimeElement".concat(field.id)} forReview={forReview} />
                    case 'duration':
                        return <DurationElement parentId={parentId} element={field} key={"DateTimeElement".concat(field.id)} forReview={forReview} />
                    case 'checkbox':
                        return <CheckElement parentId={parentId} element={field} key={"CheckElement".concat(field.id)} forReview={forReview} />
                    case 'select':
                    case 'multiple':
                        return <MultipleElement parentId={parentId} element={field} key={"MultipleElement".concat(field.id)} forReview={forReview} />
                    case 'timestamp':
                        return <TimestampElement parentId={parentId} element={field} key={"TimestampElement".concat(field.id)} forReview={forReview} />
                    case 'geo':
                        return <GeoElement parentId={parentId} element={field} key={"GeoElement".concat(field.id)} forReview={forReview} />
                    case 'text':
                        return <TextElement parentId={parentId} element={field} key={"TextElement".concat(field.id)} forReview={forReview} />
                    case 'qr':
                        return <QrElement parentId={parentId} element={field} key={"TextElement".concat(field.id)} forReview={forReview} />
                    case 'docs':
                    case 'video':
                    case 'photo':
                        return <MediaElement parentId={parentId} element={field} key={"MediaElement".concat(field.id)} forReview={forReview} />
                    case 'number':
                        return <NumberElement parentId={parentId} element={field} key={"NumberElement".concat(field.id)} forReview={forReview} />
                    case 'formula':
                        return <FormulaElement parentId={parentId} element={field} key={"FormulaElement".concat(field.id)} forReview={forReview} />
                    case 'group':
                        //console.log("ItemsDetailsScreen", "putField recall For nodes in Group", field.id, field.name);
                        return field.nodes?.length > 0 ? (<GroupElement parentId={parentId} element={field} key={"GroupElement".concat(field.id)} forReview={forReview} >
                            {
                                putFields(field.nodes, parentId)
                            }
                        </GroupElement>) : null;
                    case 'simplelist':
                        //console.log("ItemsDetailsScreen", "putField recall For nodes in SimpleList");
                        return field.nodes?.length > 0 ? (<SimpleListElement parentId={parentId} element={field} key={"SimpleListElement".concat(field.id)} forReview={forReview} >
                            {
                                putFields(field.nodes, field.id)
                            }
                        </SimpleListElement>) : null;
                    case 'title':
                        return <TitleElement  parentId={parentId} element={field} key={"TitleElement".concat(field.id)} />
                        
                    case 'populate':
                        return <PopulateElement parentId={parentId} element={field} key={"PopulateElement".concat(field.id)} forReview={forReview} />
                    case 'validate':
                        return <ValidateElement parentId={parentId} element={field} key={"ValidateElement".concat(field.id)} forReview={forReview} />
                    case 'sign':
                        return <SignatureElement parentId={parentId} element={field} key={"SignatureElement".concat(field.id)} forReview={forReview} />
                    default:
                        return <ElementBase errors={[]} element={field} key={"ElementBase".concat(field.id)} />
                }
            }
            return null;

        });
    }

    return <>
        {putFields(fields, parentId)}
    </>
}