import { Instance, SnapshotIn, SnapshotOut, types, getParent, flow } from "mobx-state-tree"

import { withSetPropAction } from "./helpers/withSetPropAction"
import { withStatusModel } from "./helpers/withStatusModel";

import { USERINFO_TYPE, BGTASK_TYPE } from "../voolks/types"
import { Platform } from "react-native";
import * as FileSystem from 'expo-file-system';
import APIParameters from "../voolks/APIParameters";
import DeviceInfo from "../services/deviceInfo"
import { customApp } from "../config";
import * as Storage from "../utils/storage"
import { custom } from "mobx-state-tree/dist/internal";

const bgTaskModel = types.model({
  uploadItems: types.optional(types.boolean, false),
  uploadItemsNetwork: types.maybeNull(types.string),
  uploadItemsFromTime: types.maybeNull(types.string),
  uploadItemsToTime: types.maybeNull(types.string),
  taskreminderTime: types.optional(types.string, "5"),
  taskreminder: types.optional(types.boolean, false)
});

const userModel = types.model({
  id: types.string,
  firstName: types.maybeNull(types.string),
  lastName: types.maybeNull(types.string),
  email: types.string,
  tenantId: types.string,
  profileUrl: types.maybeNull(types.string),
  hasProfile: types.boolean,
  initialRoute: types.maybeNull(types.string),
  pushToken: types.maybeNull(types.string),
  signCache: types.maybeNull(types.string),
  stampCache: types.maybeNull(types.string),
  memoryLow: types.boolean,
  SAML: types.boolean,
  bgTask: types.maybeNull(bgTaskModel),
})

const userInfoModel = types.model({
  userToken: types.string,
  deviceId: types.string,
  model: types.maybeNull(types.string),
  systemVersion: types.maybeNull(types.string),
  user: types.maybeNull(userModel),
  mustUploadProfileImage: types.optional(types.boolean, false)
});

export const AuthenticationStoreModel = types
  .model("AuthenticationStore")
  .props({
    userIdLogged: types.optional(types.string, ""),
    userInfo: types.maybeNull(userInfoModel),
    UsersInfoHistory: types.array(userInfoModel),
  })
  .actions(withSetPropAction)
  .actions((store) => ({
    getUserInfoHistory: async (userId: string) => {
      return store.UsersInfoHistory.find(user => user.user.id == userId)
    },
    updateUserInfoHistory: async (userInfo: USERINFO_TYPE) => {
      let repo = store.UsersInfoHistory //JSON.parse(JSON.stringify(store.UsersInfoHistory));
      let localUserInfoIdx = repo.findIndex(localUserInfo => localUserInfo.user.id == userInfo.user.id);
      if (localUserInfoIdx >= 0)
        repo.splice(localUserInfoIdx, 1);
      repo.push(JSON.parse(JSON.stringify(userInfo)));
      store.setProp("UsersInfoHistory", repo);
    },
  }))
  .actions((store) => ({
    setUserInfo: async (userInfo: USERINFO_TYPE) => {
      store.setProp("userInfo", userInfo);
      store.updateUserInfoHistory(userInfo);
    },
  }))
  // eslint-disable-line @typescript-eslint/no-unused-vars
  .extend(withStatusModel)
  .actions((store) => ({
    saveFile: flow(function* saveFile(fileName: string, dataUri: string) {
      store.setStatus("pending");
      if (Platform.OS == "web") {
        fileName = `WEB-${fileName}`;
        let resp = yield store.api().uploadFile(fileName, dataUri)
        if (resp.status != 200)
          throw (new Error("No se puedo guardar el archivo en el servidor"));
        let respBody = yield resp.json();
        let container = respBody.result.files[fileName][0].container
        fileName = store.api().urlDownloadFile(fileName, container);
      } else {
        fileName = FileSystem.documentDirectory.concat(fileName);
        if (dataUri.substring(0, 5) == "data:") {
          yield FileSystem.writeAsStringAsync(
            fileName,
            dataUri.substring(dataUri.indexOf("base64,") + 7),
            { encoding: FileSystem.EncodingType.Base64 }
          )
        } else if (dataUri.includes(FileSystem.cacheDirectory)) {
          yield FileSystem.moveAsync({ from: dataUri, to: fileName });
        } else {
          yield FileSystem.copyAsync({ from: dataUri, to: fileName });
        }
      }
      store.setStatus("done");
      return { uri: fileName };
    }),
    removeFile: flow(function* removeFile(uri: string) {
      try {
        if (Platform.OS == "web") {
          const resp = store.api().deleteFile(uri);
        } else {
          yield FileSystem.deleteAsync(uri);
        }
      } catch (e) {
        console.error(e)
      }
    }),
  }))
  .actions((store) => ({
    saveLogin: flow(function* saveLogin(json: any) {
      let storedInfo = yield store.getUserInfoHistory(json.user.id);
      let userInfo: USERINFO_TYPE;
      
      if (storedInfo) {
        userInfo = JSON.parse(JSON.stringify(storedInfo));
        userInfo.userToken = json.id ?? json.userToken;
        userInfo.deviceId = DeviceInfo.deviceId;
        userInfo.model = DeviceInfo.deviceModel;
        userInfo.systemVersion = DeviceInfo.systemVersion;
        userInfo.user.firstName = json.user.firstName ?? userInfo.user.firstName;
        userInfo.user.lastName = json.user.lastName ?? userInfo.user.lastName;
        userInfo.user.pushToken = undefined;
        userInfo.user.SAML = json.user.SAML ?? userInfo.user.SAML;
        userInfo.user.initialRoute = json.user.initialRoute ?? userInfo.user.initialRoute;
        userInfo.user.memoryLow = true;
      } else {
        userInfo = {
          userToken: json.id || json.userToken,
          deviceId: DeviceInfo.deviceId,
          model: DeviceInfo.deviceModel,
          systemVersion: DeviceInfo.systemVersion,
          user: {
            id: json.user.id,
            firstName: json.user.firstName,
            lastName: json.user.lastName,
            email: json.user.email,
            profileUrl: undefined,
            tenantId: json.user.tenantId,
            hasProfile: false,
            pushToken: undefined,
            initialRoute: "Settings",
            SAML: json.user.SAML || false,
            memoryLow: true
          }
        }
      }
      if (json.user.profileUrl) {
        if (Platform.OS != "web") {
          let result = yield FileSystem.downloadAsync(
            json.user.profileUrl,
            `${FileSystem.documentDirectory}profile-${userInfo.user.id}.${json.user.profileUrl.substring(json.user.profileUrl.lastIndexOf("."))}`,
          );

          userInfo.user.profileUrl = result.uri;
        }
        userInfo.user.profileUrl = json.user.profileUrl;
        userInfo.user.hasProfile = true;
      }
      userInfo.user.bgTask = userInfo.user.bgTask || { uploadItems: false, uploadItemsFromTime: "00", uploadItemsToTime: "23", uploadItemsNetwork: "onlyWifi", taskreminder: (customApp.isPTW != true), taskreminderTime: "5" };
      userInfo.user.initialRoute = userInfo.user.initialRoute || "Settings";
      console.log("setUserInfo A")
      userInfo.user.pushToken = undefined;
      store.setUserInfo(userInfo);
      console.log("setUserInfo B")
      store.setProp("userIdLogged", userInfo.user.id);
      return true;
    }),
    updateinitialRoute: (initialRoute: string) => {
      let userInfo = { ...store.userInfo, }
      userInfo.user.initialRoute = initialRoute;
      store.setUserInfo(userInfo)
    },
    updateBgTask: (_bgTask: BGTASK_TYPE) => {
      console.log(_bgTask.taskreminder);
      let userInfo = { ...store.userInfo, }
      userInfo.user.bgTask.uploadItems = _bgTask.uploadItems ?? userInfo.user.bgTask.uploadItems;
      userInfo.user.bgTask.uploadItemsNetwork = _bgTask.uploadItemsNetwork ?? userInfo.user.bgTask.uploadItemsNetwork;
      userInfo.user.bgTask.uploadItemsFromTime = _bgTask.uploadItemsFromTime ?? userInfo.user.bgTask.uploadItemsFromTime;
      userInfo.user.bgTask.uploadItemsToTime = _bgTask.uploadItemsToTime ?? userInfo.user.bgTask.uploadItemsToTime;
      userInfo.user.bgTask.taskreminderTime = _bgTask.taskreminderTime ?? userInfo.user.bgTask.taskreminderTime;
      userInfo.user.bgTask.taskreminder = _bgTask.taskreminder ?? userInfo.user.bgTask.taskreminder;

      store.setUserInfo(userInfo)
    },
    saveSignCache: flow(function* saveSignCache(signCacheUri: string) {
      let userInfo = { ...store.userInfo, }
      if (signCacheUri == undefined) {
        yield store.removeFile(userInfo.user.signCache);
        userInfo.user.signCache = undefined;
      } else {
        let fileName = `${store.userIdLogged}-signCache.jpg`;
        let finalUri = yield store.saveFile(fileName, signCacheUri)
        userInfo.user.signCache = finalUri?.uri.concat("?", new Date().getTime());
      }
      store.setUserInfo(userInfo)
    }),
    saveStampCache: flow(function* saveStampCache(stampCacheUri: string) {
      let userInfo = { ...store.userInfo, }
      if (stampCacheUri == undefined) {
        yield store.removeFile(userInfo.user.stampCache);
        userInfo.user.stampCache = undefined;
      } else {
        let fileName = `${store.userIdLogged}-stampCache.jpg`;
        let finalUri = yield store.saveFile(fileName, stampCacheUri)
        userInfo.user.stampCache = finalUri?.uri.concat("?", new Date().getTime());
      }
      store.setUserInfo(userInfo)
    }),
    mustUploadProfileImage: async (uploadNow: boolean, uri: string = "") => {
      let userInfo = JSON.parse(JSON.stringify(store.userInfo));
      userInfo.mustUploadProfileImage = !uploadNow;
      if (uploadNow && uri != "") {
        let extensionFile = uri.substring(uri.lastIndexOf("."))
        if (Platform.OS == "web")
          extensionFile = uri.substring(11, uri.lastIndexOf(";base64"))
        console.log(extensionFile);
        const destination = `profile-uid-${store.userIdLogged}.${extensionFile}`;
        let resp = await store.api().uploadFile(destination, `${uri}`);
        let responseBody = await resp.json();
        let container = responseBody.result.files[destination][0].container
        let profileUrl = APIParameters().downloadFile(container, destination).concat("?").concat(Date.now().toString())
        await store.api().updMember({ "profileUrl": profileUrl });
        userInfo.user.profileUrl = profileUrl
      }
      store.setUserInfo(userInfo)
    },
    updPushToken: flow(function* updPushToken(token: string,forced=false) {
      console.log("updPushToken",store.userIdLogged ,token)
      if (store.userIdLogged == undefined || store.userIdLogged == "")
        return;
      let userInfo = JSON.parse(JSON.stringify(store.userInfo));
      if (forced || userInfo.user && userInfo.user.pushToken != token){
        console.log("updPushToken","ACTUALIZAR")
        let response = yield store.api().getInstallations();
        if (response.status == 200) {
          let oldInstallations = yield response.json();
          oldInstallations.forEach((i: any) => {
            if (i.deviceId && (!customApp.user || (i.deviceId == DeviceInfo.deviceId && i.isPTW == customApp.isPTW)))
              store.api().delInstallation(i.id);
          });
          let newInstallation = {
            "appId": "FieldMobileApp",
            "packagename": customApp.packagename,
            "version": customApp.version,
            "isPTW": customApp.isPTW,
            "created": new Date(),
            "deviceToken": token,
            "deviceType": Platform.OS,
            "deviceId": DeviceInfo.uniqueId,
            "userId": userInfo.user.id,
            "memberId": userInfo.user.id,
          };
          response = yield store.api().newInstallation(newInstallation);
        }
        userInfo.user.pushToken = token;
        console.log("userInfo.user.pushToken",userInfo.user.pushToken)
        store.setUserInfo(userInfo);
      }
    }),
  }))
  .actions((store) => ({
    signSAML: flow(function* signIn(data: any) {
      console.log("OK signSAML")
      yield store.saveLogin(data)
      yield store.parent.formsStore.getSubscriptions();
      if (customApp.isPTW != true)
        yield store.parent.tasksStore.getTasks();
    }),
    signIn: flow(function* signIn(data: any) {
      store.setStatus("pending");
      //const api = new Api();
      const response = yield store.api().signIn(data.username, data.password)
      //@ts-ignore
      const json = yield response.json();
      //@ts-ignore
      if (response.status === 200) {
        yield Storage.removeItem("v3-HOME_NAVIGATION_STATE");
        yield store.saveLogin(json)
        yield store.parent.formsStore.getSubscriptions();
        if (customApp.isPTW != true)
          yield store.parent.tasksStore.getTasks();

      } else {
        let message = ('Error intentando ingresar');
        if (json.error.code === 'USER_DEVICE_NOT_FREE') {
          message = "Usuario conectado en otro dispositivo";
        } else if (json.error.code === 'LOGIN_FAILED') {
          message = "Usuario - Clave incorrecto"; //('error.LOGIN_FAILED');
        }
        store.setFlashMessage(message);
        console.log(message);
        store.userIdLogged = undefined;
      }
      store.setStatus("done");
    }),
    signOut: flow(function* signOut() {
      store.setStatus("pending");
      store.addLoadingInfo({ title: "Cerrando sesion...", spinner: true })
      const response = yield store.api().signOut()
      store.setProp("userIdLogged", undefined)        
      console.log(response.status)
      //@ts-ignore
      if (response.status != 500)
        store.setFlashMessage("Sesion cerrada", 10000);
      else
        store.setFlashMessage("Caduco su sesion o fue deslogueado por el administrador", 10000);
      if (customApp.isPTW) {
        store.userInfo=null;
        store.UsersInfoHistory.splice(0);
        store.parent.itemsStore.removeAll();        
        yield Storage.clearAll();
      }
      store.setStatus("done");

    })

  })) // eslint-disable-line @typescript-eslint/no-unused-vars

export interface AuthenticationStore extends Instance<typeof AuthenticationStoreModel> { }
export interface AuthenticationStoreSnapshotOut extends SnapshotOut<typeof AuthenticationStoreModel> { }
export interface AuthenticationStoreSnapshotIn extends SnapshotIn<typeof AuthenticationStoreModel> { }
export const createAuthenticationStoreDefaultModel = () => types.optional(AuthenticationStoreModel, {})
