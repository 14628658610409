import { Button, HStack, Icon, Input, Pressable, Spacer, Text, View, IconButton, ScrollView, VStack, Spinner } from 'native-base';
import React, { FunctionComponent } from 'react';
import { Ionicons, MaterialCommunityIcons } from "@expo/vector-icons";
import { ElementBase, IElementBaseProps, ModalFull, initializeElement } from '..';
import { RNPicker } from "../base/RNPicker";

import { getDataFromIntegration, getDisplayAndValue } from '../../utils/itemCommonFunctions';
import { TextInput } from 'react-native-gesture-handler';
import { Platform } from 'react-native';
import { onPatch } from 'mobx-state-tree';
import { conditionIsGo } from '../../utils/conditionIsGo';
import { useDebouncedCallback } from 'use-debounce';

export const MultipleElement: FunctionComponent<IElementBaseProps> = ({ ...props }) => {
    const fcName = "MultipleElement";
    //console.log(fcName, "render")

    const {
        itemsStore, integrationsStore, element, statusStore, checkAndSetPayload,
        payload, setPayload,
        errorList,
        modalVisible, setModalVisible
    } = initializeElement(props);

    const asSelect = (element.type == 'select' || element.type == 'sendemail1')
    const [options, setOptions] = React.useState<string[]>([]);
    const [renderedNoModal, setRenderNoModal] = React.useState(<></>);

    const [loadingOptions, setLoadingOptions] = React.useState<boolean>(false);
    const [filterActived, setFilterActived] = React.useState<string>("");

    const handlePressed = (val: string | string[]) => {
        if (props.forReview)
            return;
        if (!element.templateData.onlyOne && !asSelect) {
            let pay = [];
            if (payload.value && Array.isArray(payload.value))
                pay = payload.value.slice();
            if (pay.includes(val))
                pay.splice(pay.indexOf(val), 1);
            else if (val.toString().substring(0, 6) == "OTHER|" && pay.find(v => v.substr(0, 6) == val.toString().substring(0, 6)))
                pay.splice(pay.findIndex(v => v.substr(0, 6) == val.toString().substring(0, 6)), 1);
            else
                pay.push(val);
            val = pay;
        }else if(element.templateData.optionsAsButtons && payload.value==val)
            val=undefined;
        checkAndSetPayload(val);
    }

    const isSelectedOther = (): boolean => {
        if (payload.value == undefined)
            return false;
        if (Array.isArray(payload.value))
            return payload.value.reduce((hasOther, itemSelected) => hasOther || itemSelected.toString().substring(0, 6) == "OTHER|", false);
        console.log("payload.value", payload.value)
        return payload.value.toString().substring(0, 6) == "OTHER|"
    };

    const handleTextOther = (val: string) => {
        val = val || "";
        let value;
        if (Array.isArray(payload.value))
            value = payload.value.map((itemSelected) => {
                //MG.console.log(itemSelected, itemSelected.substring(0, 6).toUpperCase() == "OTHER|")
                return itemSelected.substring(0, 6).toUpperCase() == "OTHER|" ? "OTHER|##".concat(val || "").concat("##") : itemSelected
            });
        else
            value = payload.value.substring(0, 6).toUpperCase() == "OTHER|" ? "OTHER|##".concat(val.concat("##"))
                : payload.value;
        setPayload({ ...payload, value });
    }

    const textOtherDisplay = () => {
        let val = Array.isArray(payload.value) ? payload.value : [payload.value];
        return val.reduce((text, itemSelected, idx) => {
            if (text != "")
                return text;
            if (itemSelected.substring(0, 8).toUpperCase() == "OTHER|##")
                return itemSelected.substring(8, itemSelected.length - 2);
            if (itemSelected.substring(0, 6).toUpperCase() == "OTHER|")
                text = "";
            return text;
        }, "");

    }

    const displayPayload = (): string => {
        if (payload.value == undefined)
            return "";
        if (Array.isArray(payload.value))
            return payload.value.reduce((valueString, itemSelected) => valueString.concat(getDisplayAndValue(itemSelected).display).concat(", "), "");
        return getDisplayAndValue(payload.value.toString()).display;

    };

    const setDefaultValue = () => {
        //MG.console.log(fcName, "setDefaultValue")
        let _data = options;
        if (!payload.value || (Array.isArray(payload.value) && payload.value.length == 0))
            _data.map(item => {
                if (getDisplayAndValue(item).default)
                    handlePressed(item);
            });
    }

    const putOptions = (data: string[]) => {
        console.log(fcName, "putOptions")
        setOptions(element.templateData.sort ? [].concat(data).sort() : data);
    }

    const reloadOptions = async () => {
        console.log(fcName, "reloadOptions")
        let payload = itemsStore.getPayloadElement(element.id, props.parentId);
        if (props.forReview || loadingOptions)
            return;
        const { id, templateData, integration, name, label } = element;

        let opts: any = [];
        if (integration?.id) {
            setLoadingOptions(true);
            opts = await getDataFromIntegration(element, itemsStore.getPayloadElement, props.parentId,
                integrationsStore.getIntegration);
            setLoadingOptions(false);
        } else if (element.isDinamic && templateData.dynamicOptions?.length > 0) {
            //console.log(fcName, "reloadOptions", "setLoading")
            opts = conditionIsGo(element.templateData.dynamicOptions, itemsStore.itemActive.toJSON(), props.parentId);
        } else
            opts = element.templateData.options;
        if (!Array.isArray(opts))
            opts = [];
        putOptions(opts);

        if (Array.isArray(payload.value)) {
            let mustClean = false;
            payload.value.forEach(value => {
                if (value && !(opts.includes(value) || value.includes("OTHER|##")))
                    mustClean = true;
            })
            if (mustClean)
                setPayload({ ...payload, value: null });
        } else {
            if (payload.value && !(opts.includes(payload.value) || payload.value.toString().includes("OTHER|##")))
                setPayload({ ...payload, value: null });
        }
        console.log(fcName, "reloadOptions", "FIN")
    }

    const reloadOptionsDebouneced = useDebouncedCallback(reloadOptions, 500);

    React.useEffect(() => {
        try {
            //console.log(fcName, "UseEffect[]");
            options.length == 0 && !element.integration?.userMode && reloadOptions();
            let _disposerOnPatch = onPatch(itemsStore, patch => {
                let mustReloadOptions = false;
                if (element.integration && element.integration.userMode != true && element.integration.dynamicParams) {
                    Object.keys(element.integration.dynamicParams).forEach((v: any) => {
                        let myDependency = element.integration?.dynamicParams[v];
                        if (myDependency != element.id && patch.path.includes(myDependency))
                            mustReloadOptions = true;
                    });
                }
                if (element.isDinamic && element.templateData.dynamicOptions) {
                    element.templateData.dynamicOptions
                        .forEach(dynamicOption => {
                            dynamicOption.conditions.forEach(condition => {
                                if (JSON.stringify(condition).includes(patch.path.split("/").slice(-2, -1).toString()))
                                    mustReloadOptions = true;
                            });
                        });
                }
                if (mustReloadOptions) {
                    console.log("MultipleElement", "onPatch", "MustReloadOptions=true")
                    reloadOptionsDebouneced();
                    setFilterActived("");
                }
            });

            return () => {
                _disposerOnPatch();
                _disposerOnPatch = undefined;
            }
        } catch (e) { }
    }, [undefined, props.forReview]);

    React.useEffect(() => {
        try {
            !(payload.value == null && options.length == 0) && setDefaultValue()
        } catch (e) { }
    }, [options])

    const showSelect = () => {
        let _options = Array.isArray(options) ? options : [];
        return <RNPicker testID="rnpicker-MultipleEl"
            data={(_options || [])?.map((item, idx) => ({ id: idx.toString(), name: getDisplayAndValue(item).display, item }))}
            defaultValue={true}
            showSearchBar={(_options || []).length > 10} searchBarPlaceHolder={"Buscar....."}
            selectedText={payload.value ? getDisplayAndValue(payload.value.toString()).display : undefined}
            selectedValue={(index: number, item: { id: string, name: string, item: string }) => handlePressed(item.item)}
        />
    }

    const showButtonsChecks = () => {
        //MG.console.log("showButtonsChecks", element.name)
        const isOptionSelected = (option): boolean => {
            if (payload?.value == undefined)
                return false;
            if (Array.isArray(payload.value))
                return payload.value.includes(option) || (option.substring(0, 6) == "OTHER|" && payload.value.toString().includes("OTHER|"));
            return payload.value == option || (option.substring(0, 6) == "OTHER|" && payload.value.toString().includes("OTHER|"));
        }

        const dataSource = (options || []).filter((opt) => getDisplayAndValue(opt).display.toLowerCase().includes(filterActived.toLowerCase()))
        return dataSource.map((opt, idx) => {
            if (element.templateData.optionsAsButtons) {
                return (<Button size="md"
                    bgColor={isOptionSelected(opt) ? "primary.400" : "white"} mx="1" borderColor="primary.400" borderWidth="1"
                    _hover={{ bg: "secondary.600:alpha.20" }} _pressed={{ bg: "secondary.600:alpha.20" }} my="1"
                    key={`btn${idx}`}
                    onPress={() => handlePressed(opt)}  >
                    <Text color={isOptionSelected(opt) ? "white" : "primary.400"} >{getDisplayAndValue(opt).display.toUpperCase()}</Text>
                </Button>)
            } else {
                return (<Pressable py="2" key={`opt${idx}`} onPress={() => handlePressed(opt)}
                    style={{
                        flex: 1,
                        flexDirection: "row",
                        alignItems: "center",
                        width: "100%",
                    }}
                    _pressed={{ bg: "secondary.600:alpha.20" }} _hover={{ bg: "secondary.600:alpha.20" }}
                >
                    {isOptionSelected(opt) == true ? <Icon mx="2" size="lg" name="checkbox-outline" as={Ionicons} /> : <Icon mx="2" size="lg" name="square-outline" as={Ionicons} />}
                    <Text px="2">{getDisplayAndValue(opt).display}</Text>
                </Pressable>)
            }
        });
    }

    const renderNoModal = () => {
        return (asSelect && !element.templateData.optionsAsButtons ?
            showSelect() :
            (element.templateData.optionsAsButtons) ?
                <HStack flexWrap="wrap">{showButtonsChecks()}</HStack>
                : <VStack>{showButtonsChecks()}</VStack>)
    }

    React.useEffect(() => {
        if ((element.templateData.noModal && options?.length < 20) || asSelect)
            setTimeout(() => setRenderNoModal(renderNoModal()), 5);
    }, [options, payload])

    let btnCallIntegration = loadingOptions ? <Spinner size="lg" /> : <></>;
    if (!props.forReview && !loadingOptions && element.integration && element.integration.id && element.integration.userMode)
        btnCallIntegration = <IconButton key="btnImportIntegration" size="lg" onPress={reloadOptions} icon={<Icon as={Ionicons} name="sync-circle" size="lg" />} />;

    return <ElementBase errors={errorList} element={element} forReview={props.forReview} rigthButton={btnCallIntegration}>
        {!props.forReview ?
            <>
                {(element.templateData.noModal && options?.length < 20) || asSelect ?
                    renderedNoModal
                    :
                    <>
                        <HStack>
                            <Input isReadOnly
                                style={{ color: (element.templateData.textColor || "#000") }}
                                placeholder={element.templateData.placeholder || ""}
                                value={displayPayload()}
                                width="90%"
                                borderWidth="0" size="md"
                            />
                            <Spacer></Spacer>
                            <IconButton icon={<Icon as={MaterialCommunityIcons} name="checkbox-multiple-marked" size="lg" color="primary.400" />} onPress={() => { setModalVisible(!modalVisible) }} />
                        </HStack>
                        {modalVisible && <ModalFull
                            visible={modalVisible}
                            title="Elija las opciones"
                            closeButton_onPress={() => { setModalVisible(false); setFilterActived(""); }}
                            footer={<>
                                <Button colorScheme={"blue"}
                                    width={"full"} p="2"
                                    borderRadius={0}
                                    onPress={() => { setModalVisible(false); setFilterActived(""); }}
                                    leftIcon={<Icon as={Ionicons} name="checkmark" size="lg" />}>
                                    Aceptar
                                </Button>
                            </>
                            }><View width="100%" height="100%" bgColor={"white"} alignSelf={"center"} borderWidth={1}>
                                <HStack>
                                    {options.length < 20 ? <></>
                                        :
                                        <TextInput underlineColorAndroid={"transparent"}
                                            placeholder={"Buscar..."}
                                            onChangeText={setFilterActived}
                                            returnKeyType={"done"}
                                            blurOnSubmit={true}
                                            style={{
                                                color: "black",
                                                padding: 5,
                                                marginTop: Platform.OS == "ios" ? 10 : 0,
                                                marginBottom: Platform.OS == "ios" ? 10 : 0,
                                                alignSelf: "center",
                                                flex: 1, borderRadius: 5, backgroundColor: "#fff",
                                                borderColor: '#ccc',
                                                borderWidth: 1,
                                                height: 40,
                                                width: statusStore.windowDimensions.width * 0.9
                                            }}
                                        />

                                    }
                                </HStack>
                                <ScrollView>
                                    {showButtonsChecks()}
                                </ScrollView></View>
                        </ModalFull>
                        }
                    </>
                }
                {isSelectedOther() ?
                    <Input keyboardType={"default"} style={{ color: (element.templateData.textColor || "#000") }} onChangeText={handleTextOther}
                        value={textOtherDisplay() || ""}
                        placeholder={"Escriba aqui otra Opcion"}
                        size={"md"}
                    />
                    : null}
            </>
            :
            <Text>{displayPayload()}</Text>
        }
    </ElementBase>
}