import { Instance, SnapshotOut, setLivelinessChecking, types } from "mobx-state-tree"
import { TasksStoreModel } from "./TasksStore"
import { ItemsStoreModel } from "./ItemsStore"
import { IntegrationsStoreModel } from "./IntegrationsStore"
import { FormsStoreModel } from "./FormsStore"
import { AuthenticationStoreModel } from "./AuthenticationStore"
import { StatusStoreModel } from "./StatusStore"

/**
 * A RootStore model.
 */
export const RootStoreModel = types.model("RootStore").props({
  tasksStore: types.optional(TasksStoreModel, {} as any),
  authenticationStore: types.optional(AuthenticationStoreModel, {} as any),
  itemsStore: types.optional(ItemsStoreModel, {} as any),
  statusStore: types.optional(StatusStoreModel, {} as any),
  integrationsStore: types.optional(IntegrationsStoreModel, {} as any),
  formsStore: types.optional(FormsStoreModel, {} as any),
})
setLivelinessChecking("error");
/**
 * The RootStore instance.
 */
//@ts-ignore
export interface RootStore extends Instance<typeof RootStoreModel> { }
/**
 * The data of a RootStore.
 */
export interface RootStoreSnapshot extends SnapshotOut<typeof RootStoreModel> { }

