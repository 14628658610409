import React, { FC, useState, useRef, useEffect } from "react";
import {
    StyleSheet,
    Dimensions,
    View,
    Text,
    TouchableOpacity,
    SafeAreaView,
    Platform,
} from "react-native";
import { Camera, CameraType, FlashMode, VideoStabilization } from "expo-camera";
import { Video } from "expo-av";
import { Box, Icon, IconButton } from "native-base";
import { MaterialIcons, Ionicons, MaterialCommunityIcons, FontAwesome5 } from "@expo/vector-icons";
import { PinchGestureHandler } from 'react-native-gesture-handler';

const WINDOW_HEIGHT = Dimensions.get("window").height;

const closeButtonSize = Math.floor(WINDOW_HEIGHT * 0.032);
const captureSize = Math.floor(WINDOW_HEIGHT * 0.09);


export interface ICameraUIProps {
    onCallback: Function, onRequestClose: Function, forVideo?: boolean
}


export const CameraUI: FC<ICameraUIProps> = (({ ...props }) => {
    const [hasPermission, setHasPermission] = useState(null);
    const [cameraType, setCameraType] = useState(CameraType.back);
    const [flashMode, setFlashMode] = useState(FlashMode.off);
    const [zoom, setZoom] = useState(0);

    const [isPreview, setIsPreview] = useState(false);
    const [isCameraReady, setIsCameraReady] = useState(false);
    const [isVideoRecording, setIsVideoRecording] = useState(false);
    const [videoSource, setVideoSource] = useState(null);
    const [elapsed, setElapsed] = React.useState<number>(0);
    const [started, setStarted] = React.useState<number>(0);
    const cameraRef = useRef();
    let _recordingTimer = null;

    useEffect(() => {
        (async () => {
            try{
            const { status } = await Camera.getCameraPermissionsAsync();
            console.log(status)
            if(status!=="granted")
                await Camera.requestCameraPermissionsAsync();
            console.log("Necesito CAMARA")
            if (props.forVideo) await Camera.getMicrophonePermissionsAsync();
            setHasPermission(status === "granted");
            }catch(e){
                console.log(e);
                if(Platform.OS=="web"){
                    await Camera.requestCameraPermissionsAsync();
                    setHasPermission(true);
                }
                
            }
        })();
    }, []);

    const onCameraReady = () => {
        setIsCameraReady(true);
    };

    const takePicture = async () => {
        if (cameraRef.current) {
            const options = { quality: 0.7, base64: true, skipProcessing: true, width: 1800 };
            //@ts-ignore
            const data = await cameraRef.current.takePictureAsync(options);
            const source = data.uri;
            if (source) {
                props.onRequestClose();
                props.onCallback(data);
            }
        }
    };
    const changeZoom = (event) => {
        if (event.nativeEvent.scale > 1 && zoom < 1) {
            setZoom(zoom + 0.005);
        }
        if (event.nativeEvent.scale < 1 && zoom > 0) {
            setZoom(zoom - 0.005);
        }
    };

    const recordVideo = async () => {
        if (cameraRef.current) {
            try {
                const options = { quality: Camera.Constants.VideoQuality["480p"], maxDuration: 60,videoBitrate: 9*100*1000,VideoStabilization:VideoStabilization.off };
                // @ts-ignore
                const videoRecordPromise = cameraRef.current.recordAsync(options);
                setStarted(new Date().getTime());
                if (videoRecordPromise) {
                    setIsVideoRecording(true);
                    const data = await videoRecordPromise;
                    const source = data.uri;
                    if (source) {
                        setIsVideoRecording(false);
                        clearInterval(_recordingTimer);
                        setElapsed(0)
                        console.log("video source", source);
                        props.onRequestClose();
                        props.onCallback(data);
                    }
                }
            } catch (error) {
                console.warn(error);
            }
        }

    };

    React.useEffect(() => {
        if (isVideoRecording && _recordingTimer == null) {
            _recordingTimer = setInterval(() => {
                setElapsed(((new Date().getTime() - started) / 1000));
            }, 1000);
        }
    }, [isVideoRecording]);

    const stopVideoRecording = () => {
        console.log("stopRecording")
        if (cameraRef.current) {
            // @ts-ignore
            cameraRef.current.stopRecording();
        }
    };

    const switchCamera = () => {
        if (isPreview) {
            return;
        }
        setCameraType((prevCameraType) =>
            prevCameraType === CameraType.back
                ? CameraType.front
                : CameraType.back
        );
    };

    const cancelPreview = async () => {
        // @ts-ignore
        await cameraRef.current.resumePreview();
        setIsPreview(false);
        setVideoSource(null);

    };

    const renderCancelPreviewButton = () => (
        <TouchableOpacity onPress={cancelPreview} style={styles.closeButton}>
            <View style={[styles.closeCross, { transform: [{ rotate: "45deg" }] }]} />
            <View
                style={[styles.closeCross, { transform: [{ rotate: "-45deg" }] }]}
            />
        </TouchableOpacity>
    );

    const renderVideoPlayer = () => (
        <Video
            source={{ uri: videoSource }}
            shouldPlay={true}
            style={styles.media}
        />
    );

    const renderVideoRecordIndicator = () => (
        <View style={styles.recordIndicatorContainer}>
            <Icon as={MaterialCommunityIcons} name="record-rec"
                size={"lg"} color="red.400" />
            <Text style={styles.recordTitle}>{`Grabando ${elapsed.toFixed(0)}'`}</Text>
        </View>
    );

    const renderCaptureControl = () => (
        <View style={styles.control}>
            <IconButton display={props.forVideo ? "none" : "flex"}
                onPress={() => { setFlashMode(flashMode === FlashMode.off ? FlashMode.on : FlashMode.off); }}
                icon={<Icon as={Ionicons} name={flashMode === FlashMode.off ? "md-flash-off" : 'md-flash'}
                    size={"xl"} color="white" />}
            />
            <IconButton display={!props.forVideo ? "none" : "flex"}
                onPress={() => { setFlashMode(flashMode === FlashMode.off ? FlashMode.torch : FlashMode.off); }}
                icon={<Icon as={Ionicons} name="md-flashlight-outline"
                    size={"xl"} color={flashMode === FlashMode.off ? "white" : 'yellow.400'} />}
            />
            <IconButton display={props.forVideo ? "none" : "flex"}
                onPress={takePicture}
                icon={<Icon as={MaterialIcons} name="camera"
                    size="6xl" color="white" />}
            />
            <IconButton display={props.forVideo && !isVideoRecording ? "flex" : "none"}
                onPress={recordVideo}
                icon={<Icon as={Ionicons} name="radio-button-on-sharp"
                    size="6xl" color="red.400" />}
            />
            <IconButton display={props.forVideo && isVideoRecording ? "flex" : "none"}
                onPress={stopVideoRecording}
                icon={<Icon as={Ionicons} name="stop-circle-outline"
                    size="6xl" color="red.400" />}
            />
            <IconButton
                disabled={isVideoRecording}
                onPress={switchCamera}
                icon={<Icon as={Ionicons} name="camera-reverse"
                    size={"xl"} color="white" />}
            />

        </View>
    );

    if (hasPermission === null) {
        return <View><Text>Accediendo a la camara...</Text></View>;
    }

    if (hasPermission === false) {
        return <Text style={styles.text}>No access to camera</Text>;
    }

    return (
        <>
            <PinchGestureHandler onGestureEvent={(event) => changeZoom(event)}>
                <Camera
                    ref={cameraRef}
                    style={styles.container}
                    type={cameraType}
                    ratio="16:9"
                    zoom={zoom}
                    
                    flashMode={flashMode}
                    onCameraReady={onCameraReady}
                    onMountError={(error) => {
                        console.log("camera error", error);
                    }}
                /></PinchGestureHandler>
            <IconButton style={styles.closeButton} size="lg" variant="solid" borderRadius={"full"} m="0" colorScheme="black" onPress={() => props.onRequestClose()}
                icon={<Icon as={Ionicons} name="close" />} />
            <View style={styles.container}>
                {isVideoRecording && renderVideoRecordIndicator()}
                {videoSource && renderVideoPlayer()}
                {isPreview && renderCancelPreviewButton()}
                {!videoSource && !isPreview && renderCaptureControl()}
            </View>
        </>
    );
});

const styles = StyleSheet.create({
    container: {
        ...StyleSheet.absoluteFillObject,
    },
    closeButton: {
        position: "absolute",
        top: 35,
        right: 15,
        height: closeButtonSize,
        width: closeButtonSize,
        borderRadius: Math.floor(closeButtonSize / 2),
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#c4c5c4",
        opacity: 0.7,
        zIndex: 2,
    },
    media: {
        ...StyleSheet.absoluteFillObject,
    },
    closeCross: {
        width: "68%",
        height: 1,
        backgroundColor: "black",
    },
    control: {
        position: "absolute",
        flexDirection: "row",
        bottom: 10,
        width: "100%",
        alignItems: "center",
        justifyContent: "space-around",
    },
    capture: {
        backgroundColor: "#f5f6f5",
        borderRadius: 5,
        height: captureSize,
        width: captureSize,
        marginHorizontal: 31,
    },
    recordIndicatorContainer: {
        flexDirection: "row",
        position: "absolute",
        top: 25,
        alignSelf: "center",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "transparent",
        opacity: 0.7,
    },
    recordTitle: {
        fontSize: 14,
        color: "#ffffff",
        textAlign: "center",
    },
    recordDot: {
        borderRadius: 3,
        height: 6,
        width: 6,
        backgroundColor: "#ff0000",
        marginHorizontal: 5,
    },
    text: {
        color: "#fff",
    },
});