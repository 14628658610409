import { IObservableValue, observable } from "mobx";
import { getParent } from "mobx-state-tree";
import { RootStore } from "../RootStore";
import { Api } from "../../services/api"
import { LOADINGINFO_TYPE } from "../../voolks/types";

export type StatusType = "idle" | "pending" | "done" | "error";

export const withStatusModel = (store) => {
  return {
    views: {
      get parent(): RootStore {
        return getParent(store)
      },
    },
    actions: {
      addLoadingInfo(info:LOADINGINFO_TYPE){
        return store.parent.statusStore.addLoadingInfo(info);
      },
      updLoadingInfo(info:LOADINGINFO_TYPE){
        return store.parent.statusStore.updLoadingInfo(info);
      },
      setStatus(value: StatusType) {
        store.parent.statusStore.setStatus(value);
        if(value=="done"){
          store.parent.statusStore.clearLoadingInfo()
        }
      },
      api(): Api {
        let parent: RootStore = getParent(store);
        if (parent.authenticationStore?.userIdLogged != "")
          return new Api(parent.authenticationStore.userInfo.userToken,
            parent.authenticationStore.userInfo.user.id,
            parent.authenticationStore.userInfo.user.tenantId,          
            undefined, parent.authenticationStore.signOut, ((resource, response) => {
              store.resetStatus();              
              store.setFlashMessage("Fallo la conexion")
              throw (new Error('Fallo la conexión:'.concat(resource)))
            })
          );
        return new Api();
      },
      resetStatus() {
        store.parent.statusStore.resetStatus("idle");
      },
      setFlashMessage(msg: string, ms: number = 3000) {        
        store.parent.statusStore.setFlashMessage(msg, ms);
      }
    },
  };
};