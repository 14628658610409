import { observer } from "mobx-react-lite"
import React, { FC } from "react"
import { Image, ImageStyle, Pressable, ViewStyle, Modal, Platform, Dimensions } from "react-native"

import { WebView } from 'react-native-webview';
import { WebView as WebViewForWeb } from 'react-native-web-webview';
import { Input, Icon as Icon2, Button, View, Text, Spacer } from "native-base"
import { MaterialIcons } from "@expo/vector-icons";
import { ScrollView } from "react-native-gesture-handler"

import { useStores } from "../models"
//import { customApp } from "../voolks/customApp.json";
import { customApp } from "../config";
import StylesApp from "./StylesApp"
import { RegisterFormScreen, ResetForm } from "./RegisterFormScreen"

const welcomeLogo = require("../../assets/images/logo.png")


// @ts-ignore
export const LoginScreen: FC = observer(function LoginScreen(_props
) {
  const { authenticationStore, statusStore } = useStores();
  const [resetFormVisible, setResetFormVisible] = React.useState(false);
  const [webViewVisible, setWebViewVisible] = React.useState(false);
  const [registerFormVisible, setRegisterFormVisible] = React.useState(false);

  let inputPWD_ref = React.createRef()

  if (customApp.user && customApp.user != "") {
    authenticationStore.signIn({ username: customApp.user, password: customApp.pass });
    return <View></View>;
  }

  const focusPass = () => {
    // @ts-ignore
    inputPWD_ref.focus();
  }

  const [data, setData] = React.useState({
    username: '',
    password: '',
    check_textInputChange: false,
    secureTextEntry: true,
    isValidUser: false,
    isValidPassword: false,
  });

  const handlePasswordChange = (val: string) => {
    setData({
      ...data,
      password: val,
      isValidPassword: (val.trim().length >= 3)
    });
  }

  const handleUserChange = (val: string) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    val = val.toLowerCase().trim();
    if (val.trim().length >= 4 && re.test(val)) {
      setData({
        ...data,
        username: val,
        check_textInputChange: true,
        isValidUser: true
      });
    } else {
      setData({
        ...data,
        username: val,
        check_textInputChange: false,
        isValidUser: false
      });
    }
  }

  const updateSecureTextEntry = () => {
    setData({
      ...data,
      secureTextEntry: !data.secureTextEntry
    });
  }

  const WebView_onMessage = (message: any) => {
    authenticationStore.signSAML(JSON.parse(message.nativeEvent.data));
  }

  const web_OpenLoginSAML = () => {
    if (Platform.OS == "web") {
      const params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=600,height=600`;
      const url = `${customApp.baseUrl}sso/saml/loginWebApp?urlMS=${customApp.loginSAML?.url}`;
      const newWindow = window.open(url, "Login Ms Azure", params);
      window.addEventListener('message', (event) => {
        if (event.data?.user) {
          authenticationStore.signSAML(event.data);
          newWindow.close();
        }
      });
    } else {
      setWebViewVisible(true);
    }
  }

  const html = `
  <html>
  <head></head>
  <body>
    <h1>Loading Login Provider...</h1>
    <script>
      setTimeout(function () {        
        document.location.href='${customApp.loginSAML?.url}';
      }, 100)
    </script>
  </body>
  </html>
`;



  return (
    <View style={[$container, StylesApp.bgPrimary, { height: (statusStore.windowDimensions.height - 20) }]}>
      <View style={[$topContainer]}>
        <Image style={$welcomeLogo} source={welcomeLogo} resizeMode="contain" />
      </View>
      {(customApp.user && customApp.user != "") ?
        <Text>Ingresando...</Text>
        :
        <ScrollView>
          <View style={[$middleContainer]} >
            <View style={{ alignContent: "center", alignItems: "center" }}>
              <Input borderWidth={data.isValidUser || data.username.length == 0 ? 0 : 1} borderColor="red.600" color="#fff" placeholderTextColor={"#fff"} w="90%"
                autoComplete="email" autoFocus={true} fontSize="xl"
                InputLeftElement={<Icon2 as={<MaterialIcons name="person-outline" />} size="xl" ml="2" color="#fff" />}
                placeholder="Email"
                onChangeText={(val) => handleUserChange(val)}
                onSubmitEditing={focusPass}
              />
            </View>
            <View style={{ alignContent: "center", alignItems: "center" }} my="10">
              <Input ref={ref => inputPWD_ref = ref} marginTop={5} borderWidth={data.isValidPassword || data.password.length == 0 ? 0 : 1} borderColor="red.600" color="#fff" placeholderTextColor={"#fff"} w="90%"
                autoComplete="password" fontSize="xl"
                secureTextEntry={data.secureTextEntry}
                onSubmitEditing={() => authenticationStore.signIn({ username: data.username, password: data.password })}
                onChangeText={(val) => handlePasswordChange(val)}
                InputLeftElement={<Icon2 as={<MaterialIcons name="lock-outline" />} size="xl" ml="2" color="#fff" />}
                InputRightElement={<Pressable onPress={updateSecureTextEntry}>
                  <Icon2 as={<MaterialIcons name={!data.secureTextEntry ? "visibility" : "visibility-off"} />} size={"xl"} mr="2" color="muted.400" />
                </Pressable>}
                placeholder="Password" />
            </View>
            <View style={{ flex: 2, flexDirection: "row", justifyContent: "flex-end" }}>
              {customApp.loginSAML == undefined || customApp.loginSAML.url == undefined ?
                <View />
                :
                <Button key='logiSAML-btn' variant="solid" width="1/2" size={"lg"} colorScheme="primary.500"
                  onPress={web_OpenLoginSAML}>
                  {customApp.loginSAML?.title}
                </Button>
              }
              <Button variant="solid" width="1/2" colorScheme="primary.500" size={"lg"} disabled={!(data.isValidUser && data.isValidPassword)} fontWeight={"extraBlack"}
                onPress={() => authenticationStore.signIn({ username: data.username, password: data.password })}              >
                LOGIN
              </Button>

            </View>

            <View style={[{ flex: 1, flexDirection: 'column', justifyContent: "flex-end", borderWidth: 0, }]} mt="20" >
              <View style={[{ flex: 2, flexDirection: 'row', justifyContent: "flex-end", borderWidth: 0, }]}>
                {customApp.hideResetPassword ?
                  <View />
                  :
                  <Button onPress={() => { setResetFormVisible(true) }} colorScheme="primary.500">OLVIDE MI CONTRASEÑA</Button>
                }
                {customApp.hideRegisterButton ? <View />
                  : <Button onPress={() => { setRegisterFormVisible(true) }} colorScheme="primary.500">REGISTRARME</Button>
                }
              </View>
              <View style={[{ flex: 2, flexDirection: 'row-reverse', borderWidth: 0 }]}>
                <Text color="white">{customApp.version} </Text>
              </View>
            </View>
          </View>
        </ScrollView>
      }
      <View>
        <Modal visible={registerFormVisible} onRequestClose={() => setRegisterFormVisible(false)}>
          <View backgroundColor={"primary.500"} style={{ padding: 5, flexDirection: "row", justifyContent: "space-between", maxHeight: 40 }}>
            <View width={"80%"}>
              <Text color={"primary.0"} bold={true} fontSize="lg">Registrarme</Text>
            </View>
            <View><Icon2 onPress={() => setRegisterFormVisible(false)} as={<MaterialIcons name="close" />} size={"lg"} mr="2" color="white" /></View>
          </View>
          <ScrollView>
            <RegisterFormScreen callBack={() => { setRegisterFormVisible(false) }} ></RegisterFormScreen>
          </ScrollView>
        </Modal>
        <Modal visible={resetFormVisible} onRequestClose={() => setResetFormVisible(false)}>
          <View backgroundColor={"primary.500"} style={{ padding: 5, flexDirection: "row", justifyContent: "space-between", maxHeight: 40 }}>
            <View width={"80%"}>
              <Text color={"primary.0"} bold={true} fontSize="lg">Reestablecer contraseña</Text>
            </View>
            <View><Icon2 onPress={() => setResetFormVisible(false)} as={<MaterialIcons name="close" />} size={"lg"} mr="2" color="white" /></View>
          </View>
          <ScrollView>
            <ResetForm callBack={() => { setResetFormVisible(false) }} ></ResetForm>
          </ScrollView>
        </Modal>

        <Modal visible={webViewVisible} onRequestClose={() => setWebViewVisible(false)}>
          <View backgroundColor={"primary.500"} style={{ padding: 5, flexDirection: "row", justifyContent: "space-between", maxHeight: 40 }}>
            <View width={"80%"}>
              <Text color={"primary.0"} bold={true} fontSize="lg">{customApp.loginSAML?.title}</Text>
            </View>
            <View><Icon2 onPress={() => setWebViewVisible(false)} as={<MaterialIcons name="close" />} size={"lg"} mr="2" color="white" /></View>
          </View>
          {Platform.OS != "web" ?
            <WebView
              source={{ html }}
              onMessage={WebView_onMessage}
            />
            :
            <WebViewForWeb
              startInLoadingState={true}
              source={{ html: `<h1>Loading Login Provider...</h1><script>document.location.href='${customApp.loginSAML?.url}';</script>` }}
              style={{ marginTop: 20, minHeight: 600 }}
              onMessage={WebView_onMessage}
              hideStatusBar={false}
              hideAddressBar={false}
              hideActivityIndicator={false}
            />
          }
        </Modal>


      </View>

    </View>
  )
})


const $container: ViewStyle = {
  flex: 1, width: "100%",
  minHeight: 520,
  flexDirection: "column",
  justifyContent: "flex-end",

}

const $topContainer: ViewStyle = {
  flexShrink: 1,
  flexGrow: 1,
  flexBasis: "25%",
  justifyContent: "center",
}
const $middleContainer: ViewStyle = {
  flexShrink: 1,
  flexGrow: 1,
  flexBasis: "25%",
  justifyContent: "center",
}


const $welcomeLogo: ImageStyle = {
  height: 120,
  width: "80%",
  maxWidth: 450,
  resizeMode: "center", padding: 0,
  //justifyContent: "space-around",
  alignSelf: "center",

}


