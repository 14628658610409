import { Box, HStack, Icon, Input, Spacer, Switch, Text, VStack, View } from 'native-base';
import React, { FunctionComponent, useCallback, useEffect } from 'react';
import { ElementBase, IElementBaseProps, initializeElement } from '..';
import { onPatch } from 'mobx-state-tree';
import { useDebouncedCallback } from 'use-debounce';

export const GroupElement: FunctionComponent<IElementBaseProps> = ({ ...props }) => {
    const {
        itemsStore, element, statusStore,
    } = initializeElement(props);
    const [show, setShow] = React.useState<boolean>(!element.requires || !element.requires.length);

    const checkCondition = React.useCallback(async () => {
        try {
            console.log("verifica condicion", "checkCondition")
            const missingCondition = element.requires?.some((requisite: any) => (itemsStore.getPayloadElement(requisite.elementId,props.parentId)).value !== requisite.expectedValue);
            setShow(!missingCondition);
            if (missingCondition) {
                setTimeout(() => {
                    let data = [];
                    let newPayload = {};
                    element.nodes.forEach((node: any) => {
                        if(["populate","validate"].includes(node.type))
                            return;
                        newPayload[node.id] = { value: null, inactiveGroup: missingCondition };
                        data.push({ fieldId: node.id, payloadElement: { value: null, inactiveGroup: missingCondition } })
                    });
                    itemsStore.updPayloadElements(data, props.parentId);
                }, 1000);
            }

        } catch (e) {
            console.log("GroupElement","checkCondition", "", element.id, e)
        }
    }, []);
    const checkConditionDebouneced = useDebouncedCallback(checkCondition, 50);

    React.useEffect(() => {
        setTimeout(checkCondition,5);
        let _disposerOnPatch = onPatch(itemsStore, patch => {
            let changedSomeRequired = false;
            element.requires.forEach(requisite => {
                if (patch.path.includes(requisite.elementId))
                    changedSomeRequired = true
            });
            if (changedSomeRequired) {
                console.log("verifica condicion")
                checkConditionDebouneced();
            }
        });

        return () => {
            _disposerOnPatch();
            _disposerOnPatch = undefined;
        }
    }, [])
  



    React.useEffect(() => console.log("GroupElement", element.id, "SHOW change", show), [show])
    return true ?
        <ElementBase borderBottomWidth={0} element={element} forReview={props.forReview} hideTitleBar={true} >
            <View display={show ? "flex" : "none"}>{show ? props.children : null}</View>
        </ElementBase> : <></>
}
