import React, { FC, useState } from "react"
import { observer } from "mobx-react-lite"

import { Box, Button, FlatList, HStack, Icon, IconButton, Pressable, Spacer, StatusBar, Text, View, VStack } from "native-base"
import { MaterialCommunityIcons, Ionicons, MaterialIcons, } from "@expo/vector-icons";

import { HomeStackParamList, useBackButtonHandler } from "../../navigators"
import { DrawerScreenProps } from "@react-navigation/drawer"
import { useStores } from "../../models"
import { ITEM_STATUS } from "../../voolks/types";
import { shouldTaskAppearToday } from "../../utils/general";
import { Dimensions, Linking, Platform, SafeAreaView } from "react-native";
import { getIconItem } from "../../utils/itemCommonFunctions";
import { ScrollView } from "react-native-gesture-handler";




export const TaskDetailsScreen: FC<DrawerScreenProps<HomeStackParamList, "TaskDetails">> = (function TaskDetailsScreen({ navigation, route }) {
  const { formsStore, itemsStore, statusStore, tasksStore } = useStores();
  const tasks = tasksStore.myTasks()?.tasks || [];
  let taskItemsCounter: any = {}
  const task: any = tasks.find(t => t.id == route.params.taskId)
  const department = formsStore.mySubscriptions().subscriptions.find((item: any) => item.id == task.deptId);

  let my_items: any = React.useMemo(() => {
    console.log("my_ITEMS regenerate");
    return itemsStore.myItems({});
  }, [itemsStore.itemsByUsersDB.length]);


  const goBack = () => {
    if (route.params.fromTaskList)
      navigation.navigate("TasksList");
    else
      navigation.navigate("ItemsList", { deptId: department.id, formId: task.formId, forReview: false })
  }

  useBackButtonHandler(goBack);

  if (my_items)
    my_items.map((item) => {
      let data = { closed: 0, sent: 0, draft: 0 };
      if (taskItemsCounter[item.taskId] != undefined) {
        data = taskItemsCounter[item.taskId]
      }
      data[item.status]++;
      taskItemsCounter[item.taskId] = data;
    })

  const openItemList = () => {
    navigation.navigate("ItemsList", { deptId: department.id, formId: task.formId, taskId: task.id, forReview: false })

  }


  let leftButton = <IconButton variant="solid" icon={<Icon size="xl" as={Ionicons} name="arrow-back" />} onPress={goBack} />;
  return (<>
    <StatusBar backgroundColor={"#3700B3"} barStyle="light-content" />
    <Box safeAreaTop bg="primary.400" />
    <HStack key="itemDetails-title" bg="primary.400" px="0" py="0" justifyContent="space-between" alignItems="center" w="100%">
      <HStack key="headerBar" alignItems="center" style={{ flexDirection: 'row', flex: 0.8 }} maxW={statusStore.windowDimensions.width - 140}>
        {leftButton}
        <View style={{ flexDirection: 'row', flex: 1 }}>
          <Text color="white" fontSize="18" style={{ flexWrap: 'wrap', overflow: "hidden" }}>
            Tarea
          </Text>
        </View>
      </HStack>
      <HStack key="secondBar" alignItems="center" style={{ flexDirection: 'row-reverse', flex: 0.2, }}>
        <IconButton variant={"solid"} onPress={openItemList}
          icon={<Icon as={Ionicons} name="documents" size="xl" />}
        />

      </HStack>
    </HStack>

    <VStack key="itemDetails-VStackItems" bg="white" flex={1}>
      <HStack key={`r1`} bg="secondary.400:alpha.30" px="5" py="2" my="0" justifyContent="center" alignItems="center" w="100%" borderBottomWidth={1} borderBottomColor={"light.300"} >
        <Text pl="2" fontSize="md">{taskItemsCounter[task.id] ? taskItemsCounter[task.id].sent : 0}</Text>
        {getIconItem(ITEM_STATUS.sent, undefined, "md")}
        <Text pl="2" fontSize="md">{taskItemsCounter[task.id] ? taskItemsCounter[task.id].closed : 0}</Text>
        {getIconItem(ITEM_STATUS.closed, undefined, "md")}
        <Text pl="2" fontSize="md">{taskItemsCounter[task.id] ? taskItemsCounter[task.id].draft : 0}</Text>
        {getIconItem(ITEM_STATUS.draft, undefined, "md")}
      </HStack>
      <HStack key={`r2`} bgColor={"light.200"} px="5" py="2" my="0" justifyContent="flex-start" alignItems="center" w="100%" borderBottomWidth={1} borderBottomColor={"light.300"} >
        <Icon name="event" size="lg" mr="3" color={"secondary.400"} as={MaterialIcons} />
        <Text fontSize="md" color="secondary.400"> {task.name}</Text>
      </HStack>
      <ScrollView>
        <HStack key={`r3`} px="5" py="2" my="0" justifyContent="flex-start" alignItems="center" w="100%" borderBottomWidth={1} borderBottomColor={"light.300"} >
          <Icon name="clock" size="md" mr="3" color={"primary.700"} as={MaterialCommunityIcons} />
          <Text fontSize="md">{new Date(task.startDate).getHHmm()} - {new Date(task.endDate).getHHmm()}</Text>
        </HStack>
        <Pressable key={`pressableForm`} _pressed={{ bg: "primary.600:alpha.40" }} _hover={{ bg: "primary.600:alpha.40" }}
          onPress={openItemList}>
          <HStack px="5" py="2" my="0" justifyContent="flex-start" alignItems="center" w="100%" borderBottomWidth={1} borderBottomColor={"light.300"} >
            <Icon name="document-text" size="md" mr="3" color={"primary.700"} as={Ionicons} />
            <VStack>
              <Text fontSize="md">{task.formName}</Text>
              <Text sub>{department.name}</Text>
            </VStack>
            <Spacer />
            <Icon name="arrow-forward" as={Ionicons} size="md" />
          </HStack>
        </Pressable>

        <HStack key={`r4`} px="5" py="2" my="0" justifyContent="flex-start" alignItems="center" w="100%" borderBottomWidth={1} borderBottomColor={"light.300"} >
          <Icon name="caret-forward" size="md" mr="3" color={"primary.700"} as={Ionicons} />
          <Text fontSize="md">{task.description}</Text>
        </HStack>
        <HStack key={`r5`} display={task.client ? "flex" : "none"} bgColor={"light.200"} px="5" py="2" my="0" justifyContent="flex-start" alignItems="center" w="100%" borderBottomWidth={1} borderBottomColor={"light.300"} >
          <Icon name="office-building-marker" size="md" mr="3" color={"secondary.400"} as={MaterialCommunityIcons} />
          <Text fontSize="md" color="secondary.400"> Sitio</Text>
        </HStack>
        <HStack key={`r6`} px="5" py="2" my="0" justifyContent="flex-start" alignItems="center" w="100%" borderBottomWidth={1} borderBottomColor={"light.300"} >
          <Icon name="person" size="md" mr="3" color={"primary.700"} as={Ionicons} />
          <Text fontSize="md">{task.client.name}</Text>
        </HStack>
        {task.client.phone ?
          <Pressable key={`r7`} _pressed={{ bg: "primary.600:alpha.40" }} _hover={{ bg: "primary.600:alpha.40" }}
            onPress={() => { Linking.openURL(`tel:${task.client.phone}`) }}>
            <HStack key={`r7`} px="5" py="2" my="0" justifyContent="flex-start" alignItems="center" w="100%" borderBottomWidth={1} borderBottomColor={"light.300"} >
              <Icon name="call" size="md" mr="3" color={"primary.700"} as={Ionicons} />
              <Text fontSize="md">{task.client.phone ?? "N/A"}</Text>
            </HStack>
          </Pressable>
          : <></>
        }
        {task.client.email ?
          <Pressable key={`r8`} _pressed={{ bg: "primary.600:alpha.40" }} _hover={{ bg: "primary.600:alpha.40" }}
            onPress={() => { Linking.openURL(`mailto:${task.client.email}`) }}>
            <HStack key={`r8`} px="5" py="2" my="0" justifyContent="flex-start" alignItems="center" w="100%" borderBottomWidth={1} borderBottomColor={"light.300"} >
              <Icon name="mail" size="md" mr="3" color={"primary.700"} as={Ionicons} />
              <Text fontSize="md">{task.client.email ?? "N/A"}</Text>
            </HStack>
          </Pressable>
          : <></>
        }
        {task.client.address ?
          <Pressable key={`r9`} _pressed={{ bg: "primary.600:alpha.40" }} _hover={{ bg: "primary.600:alpha.40" }}
            onPress={() => { Linking.openURL(`https://www.google.com.ar/maps/search/${encodeURIComponent(task.client.address)}`) }}>
            <HStack key={`r9`} px="5" py="2" my="0" justifyContent="flex-start" alignItems="center" w="100%" borderBottomWidth={1} borderBottomColor={"light.300"} >
              <Icon name="map-sharp" size="md" mr="3" color={"primary.700"} as={Ionicons} />
              <Text fontSize="md">{task.client.address ?? ""}</Text>
            </HStack>
          </Pressable>
          : <></>
        }
        {task.client.coordinates?.lat && task.client.coordinates?.lng ?
          <Pressable key={`r10`} _pressed={{ bg: "primary.600:alpha.40" }} _hover={{ bg: "primary.600:alpha.40" }}
            onPress={() => {
              const scheme = Platform.select({ ios: 'maps:0,0?q=', android: 'geo:0,0?q=' });
              const latLng = `${task.client.coordinates.lat},${task.client.coordinates.lng}`;
              const label = task.client.name;
              const url = Platform.select({
                ios: `${scheme}${label}@${latLng}`,
                android: `${scheme}${latLng}(${label})`
              });
              Linking.openURL(url);
            }}>
            <HStack px="5" py="2" my="0" justifyContent="flex-start" alignItems="center" w="100%" borderBottomWidth={1} borderBottomColor={"light.300"} >
              <Icon name="location" size="md" mr="3" color={"primary.700"} as={Ionicons} />
              <Text fontSize="md">{JSON.stringify(task.client.coordinates.lat) ?? ""},{JSON.stringify(task.client.coordinates.lng) ?? ""}</Text>
            </HStack>
          </Pressable>
          : <></>
        }
        <HStack key={`r11`} display={task.payload.length > 0 ? "flex" : "none"} bgColor={"light.200"} px="5" py="2" my="0" justifyContent="flex-start" alignItems="center" w="100%" borderBottomWidth={1} borderBottomColor={"light.300"} >
          <Icon name="details" size="md" mr="3" color={"secondary.400"} as={MaterialIcons} />
          <Text fontSize="md" color="secondary.400"> Información Adicional</Text>
        </HStack>
        {task.payload.map((info, idx) => (
          <HStack key={`rinfo${idx}`} px="5" py="2" my="0" justifyContent="flex-start" alignItems="center" w="100%" borderBottomWidth={1} borderBottomColor={"light.300"} >
            <Icon name="information" size="md" mr="3" color={"primary.700"} as={Ionicons} />
            <Text fontSize="md">{info.key}: {info.value}</Text>
          </HStack>
        ))
        }
      </ScrollView>
      <HStack><Button colorScheme={"primary"} w="100%" height={"50"}
        leftIcon={<Icon as={Ionicons} name="documents" size="xl" />} variant="solid" size="full" onPress={openItemList}>VER FORMULARIOS</Button>
      </HStack>
    </VStack>
  </>
  )
})