import { Box, HStack, Icon, IconButton, Spacer, StatusBar, Text } from 'native-base';
import React, { FunctionComponent } from 'react';
import { Modal, View, } from 'react-native';
import { Ionicons,  } from "@expo/vector-icons";

export interface IModalFullProps {
    visible?: boolean
    title?: string,
    rightButtons?: any,
    closeButton_onPress?: any
    footer?: any
}


export const ModalFull: FunctionComponent<IModalFullProps> = ({ ...props }) => {
    
    return (
        <Modal visible={props.visible} animationType='slide' transparent={true} onRequestClose={props.closeButton_onPress}>
            <StatusBar backgroundColor={"#3700B3"} barStyle="light-content" />
            <Box safeAreaTop bg="primary.400" p="1"/>
            <View style={{ flex: 1, justifyContent: "center", alignItems: "center", backgroundColor: "transparent" }}>
                <View style={{ backgroundColor: "white", width: "100%", height: "100%", padding: 0, borderRadius: 10 }}>
                    <HStack my="0" bgColor="primary.400" width="full" alignItems={"center"} px="3">
                        <HStack key="headerBar" alignItems="center" style={{ flexDirection: 'row', flex: 0.7 }}>
                            <Text color="white" fontSize="20" fontWeight="bold" style={{ flexWrap: 'wrap', overflow: "hidden" }}>{props.title ?? ""}</Text>
                        </HStack>
                        <HStack key="secondBar" alignItems="flex-end" style={{ flexDirection: 'row', flex: 0.3, }}>
                            <Spacer></Spacer>
                            {props.rightButtons}
                            {props.closeButton_onPress == undefined ?
                                <></>
                                :
                                <><IconButton variant="solid" onPress={props.closeButton_onPress}
                                    icon={<Icon as={Ionicons} name="close" color={"white"} size="md" />} />
                                    
                                </>
                            }
                        </HStack>
                    </HStack>
                    <View style={{ flex: 1, justifyContent: "center", alignItems: "center", backgroundColor: "#eee" }}>
                        {props.children}
                    </View>
                    <HStack w="100%">
                        {props.footer}
                    </HStack>
                </View>
            </View>
        </Modal>
    );
};