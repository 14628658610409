import React, { FC } from "react"
import { observer } from "mobx-react-lite"
import { Box, HStack, Icon, IconButton, ScrollView, Spacer, StatusBar, Text, View, VStack } from "native-base"
import { MaterialIcons, Ionicons, } from "@expo/vector-icons";
import { HomeStackParamList, useBackButtonHandler } from "../../navigators"
import { DrawerScreenProps } from "@react-navigation/drawer"
import { useStores } from "../../models"
import { ITEM_STATUS } from "../../voolks/types";
import { getIconItem, getItemTitle, } from "../../utils/itemCommonFunctions";
import { GetActionButtonsFC } from "./sections/GetActionButtonsFC";
import { FieldsList } from "./sections/FieldsList";
import { customApp } from "../../config";
import * as Storage from "../../utils/storage";
import { Pressable } from "react-native";



export const ItemDetailsScreen: FC<DrawerScreenProps<HomeStackParamList, "ItemDetails">> = (function ItemDetailsScreen({ navigation, route }) {
  const TAG_NAME = "ItemDetailsScreen";
  const [shouldHide, setShouldHide] = React.useState(false);
  const [hasNotif, setHasNotif] = React.useState(undefined);
  const [formView, setFormView] = React.useState<any>(<VStack></VStack>);
  const ItemsListScreenName = customApp.isPTW ? "PTWHome" : "ItemsList";
  const FormsListScreenName = customApp.isPTW ? "PTWHome" : "FormsList";

  if (shouldHide)
    return <></>;
  const { formsStore, itemsStore, statusStore } = useStores();
  const department = React.useMemo(() => formsStore.mySubscriptions().subscriptions.find((item: any) => item.id == route.params.deptId), []);
  if (department == undefined) {
    navigation.navigate("Settings");
    return <><Text></Text></>
  }
  const form = department.forms.find((form: any) => form.id == route.params.formId);
  if (form == undefined) {
    navigation.navigate("FormsList", { deptId: department.id });
    return <><Text></Text></>
  }
  const taskId = route.params.taskId;

  const [pageActive, setPageActive] = React.useState<number>(route.params.pageActive ?? 0);
  if (itemsStore.itemActiveIDX == undefined || itemsStore.itemActive == null || itemsStore.itemActive.payload == null) {
    navigation.navigate(FormsListScreenName);
    return <></>;
  }

  if (route.params.forReview != true && itemsStore.itemActive.status != "draft")
    // @ts-ignore
    navigation.navigate(ItemsListScreenName, { deptId: department.id, formId: form.id, taskId });

  const saveAndGo = async ({ goTo = "", itemStatus = undefined, forReview = false } = {}) => {
    if (itemStatus != undefined)
      await itemsStore.saveItemActive(itemStatus);
    if (goTo == "") {
      goTo = ItemsListScreenName;
      const itemStatus = itemsStore.itemActive.status;
      if (itemStatus == ITEM_STATUS.draft && route.params.forReview) {
        goTo = "ItemDetails";
        forReview = false;
      }
    }
    setPageActive(0);
    // @ts-ignore
    navigation.navigate(goTo, { deptId: department.id, formId: form.id, forReview, taskId })
  }

  useBackButtonHandler(() => pageActive == 0 ? saveAndGo() : setPageActive(pageActive - 1));

  let leftButton = <IconButton variant="solid" icon={<Icon size="xl" as={Ionicons} name="arrow-back" />} onPress={() => saveAndGo({ itemStatus: (itemsStore.itemActive.status) })} />;
  const IconItemStatus: FC = observer(function iconItemStatus() {
    return getIconItem(itemsStore.itemActive.status, undefined, "sm");
  })

  const makeForm = () => {
    console.log(TAG_NAME, "makeForm")
    setFormView(<>
      <StatusBar backgroundColor={"#3700B3"} barStyle="light-content" />
      <Box safeAreaTop bg="primary.400" />
      <HStack key="itemDetails-title" bg="primary.400" px="0" py="0" justifyContent="space-between" alignItems="center" w="100%">
        <HStack key="headerBar" alignItems="center" style={{ flexDirection: 'row', flex: 0.8 }} maxW={statusStore.windowDimensions.width - 140}>
          {leftButton}
          <View style={{ flexDirection: 'row', flex: 1 }}>
            <Text color="white" fontSize="18" style={{ flexWrap: 'wrap', overflow: "hidden" }}>
              {form.name}
            </Text>
          </View>
        </HStack>
        <HStack key="secondBar" alignItems="center" style={{ flexDirection: 'row-reverse', flex: 0.2, }}>
          <GetActionButtonsFC itemsStore={itemsStore}
            pageActive={pageActive}
            navigation={navigation}
            setShouldHide={setShouldHide}
            form={form}
            saveAndGo={saveAndGo}
            forReview={route.params.forReview}
          />
          {form.pages.length > 1 ?
            <>
              <IconButton variant="solid" key="actionButton-prevPage"
                isDisabled={pageActive == (form.pages.length - 1)}
                onPress={() => { setPageActive(pageActive + 1); console.log("pageActive pressed ", pageActive) }}
                icon={<Icon as={Ionicons} name="caret-forward" size="xl" />} />
              <IconButton variant="solid" key="actionButton-nextPage"
                isDisabled={pageActive == 0} onPress={() => setPageActive(pageActive - 1)}
                icon={<Icon as={Ionicons} name="caret-back" size="xl" />} />
            </>
            : <></>
          }

        </HStack>
      </HStack>
      <HStack key="itemDetails-subTitle" bg="primary.300" px="2" py="1" alignItems="center" w="100%">
        <Text><IconItemStatus></IconItemStatus></Text><Text color={"white"} fontSize="2xs">{getItemTitle(form, itemsStore.itemActive)} </Text>
        <Spacer />
        {itemsStore.itemActive.taskId ? <Icon name="event" size="md" color={"secondary.400"} as={MaterialIcons} /> : <></>}
        {hasNotif != undefined ? <Pressable onPress={() => { statusStore.setAlertMessage(hasNotif.notification.title, hasNotif.notification.body) }}>
          <Icon as={Ionicons} name="notifications-circle" size="md" color="yellow.400" />
        </Pressable> : <></>}
      </HStack>
      <ScrollView key="itemDetails-scrollItems">
        <VStack key="itemDetails-VStackItems" bg="white" flex={1}>
          <FieldsList itemsStore={itemsStore}
            fields={form.pages[pageActive]?.fields}
            forReview={route.params.forReview} />
        </VStack>
      </ScrollView>

    </>)
  }

  const checkNotif = async () => {
    if (itemsStore.itemActive.id) {
      const data = await Storage.load("notif-".concat(itemsStore.itemActive.id));
      setHasNotif(data);
      console.log("notif", data);
    }
  }


  React.useEffect(() => {
    makeForm();
  }, [pageActive, route.params, hasNotif])

  React.useEffect(() => {
    checkNotif();
    /* let _disposerOnAction = onAction(itemsStore, async call => {
      if (call.name == "saveItemActive") {
        if (route.params.forReview != true && itemsStore.itemActive.status != "draft")
          // @ts-ignore
          navigation.navigate(ItemsListScreenName, { deptId: department.id, formId: form.id, taskId });
      }
    })
    return () => {
      _disposerOnAction();
      _disposerOnAction = undefined;
    } */
  }, []);

  return (formView)
})