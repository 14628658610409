//IndexDB para la Web

export const SimpleIDB = {
    myDBName: "Voolks",
    myStoreName: "Field",
    async initialize() {
        return await (new Promise((resolve, reject) => {
            let request = indexedDB.open(SimpleIDB.myDBName, 1)
            let needUpgraded = false;
            request.onupgradeneeded = function () {
                const db = request.result;
                let store = db.createObjectStore(SimpleIDB.myStoreName)
                needUpgraded = true;

            }
            request.onerror = function () {
                reject(request.error)
            }
            request.onsuccess = function () {
                if (needUpgraded && window.localStorage.length>0 && window.localStorage["v3-migrateToIndexDB"]!="1") {
                    const db = request.result;
                    let tx = db.transaction(SimpleIDB.myStoreName, 'readwrite')
                    let st = tx.objectStore(SimpleIDB.myStoreName)
                    for (const key of Object.keys(window.localStorage)) {
                        console.log(key);
                        st.add(localStorage[key], key);
                    }
                    window.localStorage.setItem("v3-migrateToIndexDB","1");
                }
                resolve(this)
            }
        }))
    },

    getString(key) {
        return new Promise((resolve, reject) => {
            let oRequest = indexedDB.open(SimpleIDB.myDBName, 1);
            oRequest.onsuccess = function () {
                let db = oRequest.result
                let tx = db.transaction(SimpleIDB.myStoreName, 'readonly')
                let st = tx.objectStore(SimpleIDB.myStoreName)
                let gRequest = st.get(key)
                gRequest.onsuccess = function () {
                    resolve(gRequest.result)
                }
                gRequest.onerror = function () {
                    reject(gRequest.error)
                }
            }
            oRequest.onerror = function () {
                reject(oRequest.error)
            }
        })
    },

    set(key, value) {
        return new Promise((resolve, reject) => {
            let oRequest = indexedDB.open(SimpleIDB.myDBName)
            oRequest.onsuccess = function () {
                let db = oRequest.result
                let tx = db.transaction(SimpleIDB.myStoreName, 'readwrite')
                let st = tx.objectStore(SimpleIDB.myStoreName)
                const objectStoreRequest1 = st.delete(key);
                const objectStoreRequest = st.add(value, key);
                objectStoreRequest.onsuccess = (event) => {
                    resolve(null);
                }
                objectStoreRequest.onerror = (e) => {
                    console.log(e, objectStoreRequest.error)
                }
            }
            oRequest.onerror = function () {
                reject(oRequest.error)
            }
        })
    },
    delete(key) {
        return new Promise((resolve, reject) => {
            let oRequest = indexedDB.open(this.myDBName)
            oRequest.onsuccess = function () {
                let db = oRequest.result
                let tx = db.transaction(SimpleIDB.myStoreName, 'readwrite')
                let st = tx.objectStore(SimpleIDB.myStoreName)
                const objectStoreRequest = st.delete(key);
                objectStoreRequest.onsuccess = (event) => {
                    resolve(null);
                }
            }
            oRequest.onerror = function () {
                reject(oRequest.error)
            }
        })
    },
    clearAll() {
        return new Promise((resolve, reject) => {
            let oRequest = indexedDB.open(this.myDBName)
            oRequest.onsuccess = function () {
                let db = oRequest.result
                let tx = db.transaction(SimpleIDB.myStoreName, 'readwrite')
                let st = tx.objectStore(SimpleIDB.myStoreName)
                const objectStoreRequest = st.clear();
                objectStoreRequest.onsuccess = (event) => {
                    resolve(null);
                }
            }
            oRequest.onerror = function () {
                reject(oRequest.error)
            }
        })
    }
}