import React, { FC } from "react"
import { observer } from "mobx-react-lite"
import { Alert, View, ViewStyle } from "react-native"
import { useStores } from "../models"
import { Button, HStack, Icon, Input, Text } from "native-base"
import { MaterialIcons, MaterialCommunityIcons } from "@expo/vector-icons";

import { api } from "../services/api"


export const RegisterFormScreen = observer(function RegisterFormScreen(props: {callBack: Function}) {
  const { statusStore } = useStores()
  const [data, setData] = React.useState({
    firstName: '', lastName: '', tenantName: '', passwordVer: '',
    password: '',
    email: '',
    secureTextEntry: true,
    isValidUser: false,
    isValidPassword: false
  });

  const handleData = (field: string, value: string) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    data[field] = value.trim();
    data.isValidPassword = (data.password.trim().length >= 3 && data.password.trim() == data.passwordVer.trim())
    data.isValidUser = (data.firstName.trim().length >= 4 && data.lastName.trim().length >= 4 && data.tenantName.trim().length >= 4 && data.email.trim().length >= 4 && re.test(data.email));
    setData({ ...data })
  }

  const sendData = async () => {
    statusStore.setStatus("pending");
    let response = await api.registerMe(data);
    statusStore.setStatus("done");
    switch (response.status) {
      case 200://OK
        Alert.alert("Bienvenido a Field",
          `Se ha enviado un correo para validar su cuenta. Ingrese a su cliente de correo y confirme la cuenta para poder hacer ingreso al sistema `,
          [{
            text: "Cerrar", onPress: () => {
              props.callBack(data);
            }
          }]

        )
        break;
      case 400://ya existente
        Alert.alert("Correo existente",
          `La cuenta de email ya se encuentra registrado en el sistema, intente recuperar la clave o contactese a info@voolks.com`,
          [{
            text: "Cerrar", onPress: () => {
              props.callBack(data);
            }
          }]
        )
        break;
      case 500://error
        Alert.alert("Error",
          `Se ha producido un error y no se pudo generar el alta, comuniquese a info@voolks.com`,
          [{
            text: "Cerrar", onPress: () => {
            }
          }]
        )
    }

  }


  return (
    <View style={{ margin: 10, }}>
      <Text>Nombre de la compañia</Text>
      <HStack>
        <Input borderWidth={0} borderBottomWidth={1} width={"100%"} onChangeText={(value) => { handleData("tenantName", value) }}
          value={data.tenantName}
        /><Icon as={<MaterialIcons name="error" />} color={data.tenantName.length > 4 ? "#fff" : "#ff0101"} />
      </HStack>
      <Text>Nombre</Text>
      <HStack>
        <Input borderWidth={0} borderBottomWidth={1} width={"100%"} onChangeText={(value) => { handleData("firstName", value) }}
          value={data.firstName}
        /><Icon as={<MaterialIcons name="error" />} color={data.firstName.length > 4 ? "#fff" : "#ff0101"} />
      </HStack>
      <Text>Apellido</Text>
      <HStack>
        <Input borderWidth={0} borderBottomWidth={1} width={"100%"} onChangeText={(value) => { handleData("lastName", value) }}
          value={data.lastName}
        /><Icon as={<MaterialIcons name="error" />} color={data.lastName.length > 4 ? "#fff" : "#ff0101"} />
      </HStack>
      <Text>Email</Text>
      <HStack>
        <Input borderWidth={0} borderBottomWidth={1} width={"100%"} onChangeText={(value) => { handleData("email", value) }} keyboardType={"email-address"}
          value={data.email}
        /><Icon as={<MaterialIcons name="person" />} color={data.email.length > 4 ? "#46bd03" : "#ff0101"} />
      </HStack>
      <Text>Contraseña</Text>
      <HStack>
        <Input borderWidth={0} borderBottomWidth={1} width={"100%"} secureTextEntry={true} onChangeText={(value) => { handleData("password", value) }}
          value={data.password}
        /><Icon as={<MaterialCommunityIcons name="form-textbox-password" />} color={data.isValidPassword ? "#46bd03" : "#ff0101"} />
      </HStack>
      <Text>Verificar Contraseña</Text>
      <HStack>
        <Input borderWidth={0} borderBottomWidth={1} width={"100%"} secureTextEntry={true} onChangeText={(value) => { handleData("passwordVer", value) }}
          value={data.passwordVer}
        /><Icon as={<MaterialCommunityIcons name="form-textbox-password" />} color={data.isValidPassword ? "#fff" : "#ff0101"} />
      </HStack>
      {data.firstName.trim().length >= 4 && data.lastName.trim().length >= 4 && data.tenantName.trim().length >= 4 ? null : <Text style={{ color: "red" }}>* Debes completar todos los campos. 4 caracteres mínimo</Text>}
      {data.password.trim().length >= 4 ? null : <Text style={{ color: "red" }}>* La contraseña debete tener 4 caracteres mínimo</Text>}
      {data.password == data.passwordVer ? null : <Text style={{ color: "red" }}>* No coiniciden las contraseñas</Text>}
      {data.isValidUser ? null : <Text style={{ color: "red" }}>* Email inválido</Text>}
      <Button colorScheme={!(data.isValidUser && data.isValidPassword) ? "gray" : "primary"} testID='login-btn' disabled={!(data.isValidUser && data.isValidPassword)} accessibilityLabel="Login" onPress={sendData} style={[{ marginTop: 50 },]}>
        <Text style={{ color: "#FFF" }}>Enviar</Text>
      </Button>
    </View>
  )
})


export const ResetForm = observer(function ResetForm(props: {callBack: Function}) {
  const { statusStore } = useStores()
  const [data, setData] = React.useState({
    email: '',
    isValidUser: false,
  });

  const handleData = (field: string, value: string) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    data[field] = value.trim();
    data.isValidUser = (data.email.trim().length >= 4 && re.test(data.email));
    setData({ ...data })
  }

  const sendData = async () => {
    statusStore.setStatus("pending");
    let response = await api.resetPassword(data);
    console.log(response)
    statusStore.setStatus("done");
    let msg = "";
    switch (response.status) {
      case 200://OK
        msg = `Se ha enviado un correo con instrucciones para reestablecer su contraseña`;
        break;
      case 500://error
        msg = `Se ha producido un error y no se pudo generar el alta, comuniquese a info@voolks.com`;
    }
    props.callBack();
    statusStore.setFlashMessage(msg, 3000);
  }



  return (
    <View style={{ margin: 10, }}>
      <Text>Email</Text>
      <HStack>
        <Input borderWidth={0} borderBottomWidth={1} width={"100%"} onChangeText={(value) => { handleData("email", value) }} keyboardType={"email-address"}
          value={data.email}
          onSubmitEditing={() => { if (data.isValidUser) sendData(); }}
        /><Icon as={<MaterialIcons name="person" />} color={data.email.length > 4 ? "#46bd03" : "#ff0101"} />
      </HStack>

      {data.isValidUser ? null : <Text style={{ color: "red" }}>* Email inválido</Text>}
      <Button colorScheme={!(data.isValidUser) ? "gray" : "primary"}
        testID='login-btn' disabled={!(data.isValidUser)}
        accessibilityLabel="Enviar" onPress={sendData} style={[{ marginTop: 50 },]}>
        <Text style={{ color: "#FFF" }}>Enviar</Text>
      </Button>
    </View>
  )
})

const $root: ViewStyle = {
  flex: 1,
}
