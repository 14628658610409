import { Box, HStack, Icon, IconButton, Pressable, Image, Spinner, Text, View } from 'native-base';
import React, { FC } from 'react';
import { Alert, Platform, StyleSheet } from 'react-native';
import { MaterialIcons, Ionicons, MaterialCommunityIcons, AntDesign } from "@expo/vector-icons";
import * as ImageManipulator from 'expo-image-manipulator';
import * as ImagePicker from 'expo-image-picker';
import * as ImageMarker from "react-native-image-marker"
import * as FileSystem from 'expo-file-system';
import RNVideoHelper from 'react-native-video-helper';
import { ResizeMode, Video } from 'expo-av';
import PhotoEditor from 'react-native-photo-editor';
import * as DocumentPicker from 'expo-document-picker';
import OpenFile from 'react-native-files-viewer';
import { getGPSCoords } from '../../utils/itemCommonFunctions';
import { ElementBase, IElementBaseProps, useInitializeElement, ModalFull } from '..';
import { CameraUI } from './CameraUI';

export const addTextToImage = async (new_image: { uri: string, height: number, width: number }, text: string) => {

    if (Platform.OS == "web") {
        return new Promise((resolve, reject) => {
            var htmlImage = document.createElement('img');
            htmlImage.onload = function () {
                var canvas = document.createElement("canvas");
                canvas.height = new_image.height;
                canvas.width = new_image.width;
                var ctx = canvas.getContext('2d');
                // @ts-ignore
                ctx.drawImage(this, 0, 0);
                var randomtxt = text;
                ctx.fillStyle = "#000"
                ctx.fillRect(0, new_image.height - 14, new_image.width, 14);
                ctx.font = "12px Arial";
                ctx.fillStyle = "#fff"
                ctx.fillText(randomtxt, 2, new_image.height - 2);
                var dataURL = canvas.toDataURL("image/jpeg", 0.7);
                new_image.uri = dataURL;
                resolve(dataURL);
            };
            htmlImage.src = new_image.uri;
        })
    } else {
        console.log("addTextToImage", new_image.uri);
        return await ImageMarker.default.markText({
            src: new_image.uri,
            text: text,
            position: ImageMarker.Position.bottomRight,
            color: '#FFFFFF',
            fontName: 'Arial-BoldItalicMT',
            fontSize: 24,
            textBackgroundStyle: {
                type: ImageMarker.TextBackgroundType.stretchX,
                paddingX: 10,
                paddingY: 10,
                color: '#000'
            },
            scale: 1,
            quality: 100
        }).then(async (res) => {
            console.log("addTextToImage2", res);
            new_image.uri = `file://${res}`;
            return `file://${res}`;
        }).catch((err) => {
            console.log(err);
            return new_image.uri;
        });

    }
}

export const MediaElement: FC<IElementBaseProps> = (({ ...props }) => {
    const { itemsStore, statusStore, element, payload, checkAndSetPayload, errorList, modalVisible, setModalVisible,
        loading, setLoading } = useInitializeElement(props);
    const forVideo: boolean = element.type == "video";
    const forDocs: boolean = element.type == "docs";
    const [mediaList, setMediaList] = React.useState<any>((<Text>-</Text>));

    React.useEffect(() => {
        makeMediaList();
    }, [payload]);

    const handleChange = (value: [{ uri: string }] | undefined) => {
        checkAndSetPayload([].concat(value));
        setModalVisible(false);
    }

    const removeMedia = async (idx: number) => {
        if (loading)
            return;
        let mediaList: [{ uri: string }] = (payload.value || []).slice(0);
        await itemsStore.removeFilePayload(mediaList[idx].uri)
        mediaList.splice(idx, 1);
        handleChange(mediaList);
    }

    const openMedia = async (uri: string) => {
        if (props.forReview || Platform.OS == "web" || element.type == "docs") {
            try {
                OpenFile?.openDoc(
                    {
                        url: uri,
                        fileName: element.id || element.label,
                        cache: false,
                        // @ts-ignore
                        fileType: uri.substring(uri.lastIndexOf(".") + 1)
                    },
                    console.log
                );
            } catch (e) {
                Alert.alert("error abriendo el archivo.")
            }
            return;
        }

        let filePath = uri.substring(7);
        PhotoEditor.Edit({
            path: filePath
            , hiddenControls: ["save", "sticker",]
            , onDone: (imagePath) => {
                makeMediaList();
            },
        });
    };

    const makeMediaList = async (payloadValue = payload.value) => {
        let list = [];
        console.log("MediaElement", "payloadValue", payloadValue)
        for (let idx in payloadValue || []) {
            let tmp = JSON.parse(JSON.stringify(payloadValue[idx])); //Por migracion
            let mediaName: { uri: string } = { uri: tmp.uri ?? tmp }
            mediaName.uri = mediaName.uri
            mediaName.uri =  ["file://", "https:/"].includes(mediaName.uri.substring(0, 7)) ? mediaName.uri : "file://".concat(mediaName.uri);
            console.log("MediaElement", "mediaName", mediaName)
            let x = payloadValue[idx];
            list.push(<HStack key={"media-".concat(element.id, "-row-", idx.toString())}>
                {forVideo ?
                    <Video source={mediaName} resizeMode={ResizeMode.CONTAIN} style={{ width: "100%", height: 150, maxHeight: 150, flex: 1, backgroundColor: "#000", marginTop: 2 }}
                        useNativeControls
                    />
                    : <Pressable onPress={() => { openMedia(mediaName.uri) }}
                        style={{ minHeight: forDocs ? 50 : 150, flex: 1, flexDirection: "row", alignItems: "center", }}>
                        {forDocs ?
                            <>
                                <Icon mx="2" as={MaterialCommunityIcons} name="file-eye-outline" size="md" /><Text>{(mediaName.uri.substring(mediaName.uri.lastIndexOf("docs")))}</Text>
                            </>
                            :
                            <Image alt={"image".concat(idx)} key={"image".concat(idx)} source={{uri:mediaName.uri.concat("?t=",new Date().getTime().toString())}} width="100%" height="150"
                                style={{ height: 150, flex: 1, resizeMode: "contain", backgroundColor: "#000", marginTop: 2 }} />
                        }
                    </Pressable>
                }
                <IconButton display={props.forReview ? "none" : "flex"} key={"delete".concat(idx)} onPress={() => { removeMedia(parseInt(idx)); }} colorScheme="danger" icon={<Icon as={Ionicons} name="trash" size="md" />} />
            </HStack>)
        }
        setMediaList([].concat(list))
    }

    React.useEffect(() => {
        makeMediaList();
    }, [props.forReview]);

    const callBack = async (response: any, fromGallery: boolean = false) => {
        //@ts-ignore
        let files: [{ uri: string }] = [].concat(payload.value || []);
        let mbOriginal: number = 0;
        setLoading(true);
        try {
            if (!Array.isArray(response))
                response = [response]
            for (let fileIdx in response) {
                let file = response[fileIdx];
                let uriSaved: { uri: string };
                let fileName: string = `${element.type}-${element.id}-${new Date().getTime()}`;
                let manipResult;
                switch (element.type) {
                    case "photo":
                        let resizeTo = file.width < 1800 && file.height < 1800 ?
                            []
                            :
                            [{ resize: file.width > file.height ? { width: 1800 } : { height: 1800 } }];
                        manipResult = await ImageManipulator.manipulateAsync(
                            file.localUri || file.uri, resizeTo,
                            { compress: 0.7, format: ImageManipulator.SaveFormat.JPEG }
                        );
                        if (!fromGallery) {
                            let legend = "";
                            if (element.templateData.dateTimeStamp)
                                legend = (new Date().getYYYYMMddHHmmss());
                            if (element.templateData.gpsStamp) {
                                let location = await getGPSCoords();
                                //console.log("location", location);
                                if (location?.coords != undefined) {
                                    legend = legend.concat(` - GPS lat ${location.coords.latitude} lng ${location.coords.longitude}`);
                                } else {
                                    legend += " - GPS N/A";
                                }
                            }
                            if (legend != "")
                                await addTextToImage(manipResult, legend);
                        }
                        fileName = fileName.concat(".jpg");
                        break;
                    case "video":
                        fileName = Platform.OS == "web" ? fileName = fileName.concat(".", file.uri.substring(file.uri.indexOf("/") + 1, file.uri.indexOf(";"))) : fileName.concat(file.uri.substring(file.uri.lastIndexOf(".")));
                        let stat = Platform.OS == "web" ? { size: 0 } : await FileSystem.getInfoAsync(file.uri);
                        mbOriginal = (stat.size / 1024 / 1024);
                        manipResult = { uri: file.uri };
                        if (mbOriginal > 7) {
                            console.log("Inicio Compresion", mbOriginal)
                            statusStore.setStatus("pending")
                            statusStore.addLoadingInfo({ title: file.uri.substring(file.uri.lastIndexOf("/") + 1) })
                            statusStore.addLoadingInfo({ title: "Comprimiendo archivo".concat(" ", (mbOriginal).toFixed(2), "MB"), spinner: true })

                            manipResult = await new Promise((resolve, reject) => {
                                RNVideoHelper.compress(file.uri, { startTime: 0, endTime: 60, quality: 'low', defaultOrientation: 0 })
                                    .then(async compressedUri => {
                                        compressedUri = "file://".concat(compressedUri);
                                        manipResult = { uri: compressedUri };
                                        stat = await FileSystem.getInfoAsync(compressedUri);
                                        console.log("Inicio Compresion", stat)
                                        statusStore.setStatus("done")
                                        resolve(manipResult);
                                    });
                            })
                        }
                        break;
                    case "docs":
                        fileName = Platform.OS == "web" ? fileName = fileName.concat(file.name.substring(file.name.lastIndexOf("."))) : fileName.concat(file.uri.substring(file.uri.lastIndexOf(".")));
                        const limitMB = 10
                        mbOriginal = parseInt((parseFloat(file.size) / 1024 / 1024).toFixed(3));
                        if (mbOriginal > limitMB) {
                            throw new Error(`Archivo demasiado grande, tamaño ${mbOriginal}MB. Limite ${limitMB}MB`);
                        }
                        manipResult = file;
                        break;
                }
                uriSaved = await itemsStore.saveFilePayload(fileName, manipResult.uri);                
                files.push(uriSaved);
                setLoading(false);
                handleChange(files);
            };
            setLoading(false);
        } catch (e) {
            setLoading(false);
            console.log("MediaElement2", "callBack", e);
            setLoading(false);
            if(e.message=="Sin permiso al GPS")
                itemsStore.setFlashMessage("Sin permiso al GPS, no puede procesar la imagen")
        }
    }
    
    const pickMediaFile = async () => {
        // No permissions request is necessary for launching the image library
        let result = forDocs ? await DocumentPicker.getDocumentAsync() : await ImagePicker.launchImageLibraryAsync({
            mediaTypes: forVideo ? ImagePicker.MediaTypeOptions.Videos : ImagePicker.MediaTypeOptions.Images,
            allowsEditing: false, allowsMultipleSelection: true,
            aspect: [4, 3],
            quality: 1,
        });
        console.log(result)
        // @ts-ignore
        if ((forDocs && result.type == "success") || (!forDocs && !result.cancelled)) {
            // @ts-ignore
            callBack(result.selected || result, true)
        }
    };

    const actionButton = props.forReview ?
        undefined
        :
        <HStack key="actionButtons" justifyContent="center"
            display={
                (element.validation.limit || 0) == 0
                    || (payload.value || []).length < element.validation.limit
                    ? "flex" : "none"}
        >{loading ?
            <><Spinner key="lsSpinnerLoading" size="sm" /></>
            : forDocs ?
                <IconButton key="btnLibrary"
                    onPress={() => { pickMediaFile(); }}
                    icon={<Icon as={AntDesign} name="addfile" size="md" />}
                />
                : <>
                    <IconButton display={forVideo && Platform.OS == "web" ? "none" : "flex"} key="btnCamera" onPress={() => { setModalVisible(!modalVisible); setLoading(true); }} icon={<Icon as={MaterialIcons} name={forVideo ? "videocam" : "photo-camera"} size="md" />} />
                    <IconButton key="btnLibrary"
                        display={element.templateData.allowGallery ? "flex" : "none"}
                        onPress={() => { pickMediaFile(); }}
                        icon={<Icon as={MaterialIcons} name={forVideo ? "video-library" : "photo-library"} size="md" />}
                    /></>
            }
        </HStack>;
    return <ElementBase errors={errorList} element={element} forReview={props.forReview} rigthButton={actionButton} >
        <Box>
            {mediaList}
            {payload.value?.length > 4 ? <><View></View>{actionButton}</> : <></>}
            {modalVisible && <ModalFull
                visible={modalVisible}
                title="Cámara"
                closeButton_onPress={() => { setModalVisible(false); setLoading(false); }}
            >
                <View style={styles.container} >
                    <CameraUI onRequestClose={() => { setModalVisible(false); setLoading(false); }} onCallback={callBack} forVideo={forVideo} />
                </View>
            </ModalFull>}
        </Box>
    </ElementBase>
});

const styles = StyleSheet.create({
    container: {

        flex: 1,
        justifyContent: 'center', width: "100%"
    },
    camera: {
        flex: 1, alignItems: "flex-end"

    },
    buttonContainer: {
        flex: 1,
        flexDirection: 'row',
        backgroundColor: 'transparent',
        margin: 64,
    },
    button: {
        flex: 1,
        alignSelf: 'flex-end',
        alignItems: 'center',
    },
    text: {
        fontSize: 24,
        fontWeight: 'bold',
        color: 'white',
    },
});