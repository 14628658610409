/**
 * This file is where we do "rehydration" of your RootStore from AsyncStorage.
 * This lets you persist your state between app launches.
 *
 * Navigation state persistence is handled in navigationUtilities.tsx.
 *
 * Note that Fast Refresh doesn't play well with this file, so if you edit this,
 * do a full refresh of your app instead.
 *
 * @refresh reset
 */
import { applySnapshot, IDisposer, onSnapshot } from "mobx-state-tree"
import type { RootStore } from "../RootStore"
import * as storage from "../../utils/storage"

/**
 * The key we'll be saving our state as within async storage.
 */

const AUTHENTICATIONSTORE_STATE_STORAGE_KEY = "authStore-v1"
const FORMSSTORE_STATE_STORAGE_KEY = "formsStore-v1"
const INTEGRATIONSSTORE_STATE_STORAGE_KEY = "IntegrationsStore-v1"
const ITEMSSSTORE_STATE_STORAGE_KEY = "ItemsStore-v1"
const TASKSSTORE_STATE_STORAGE_KEY = "TasksStore-v1"
/**
 * Setup the root state.
 */
let _disposerAuthStore: IDisposer
let _disposerFormsStore: IDisposer
let _disposerIntegrationsStore: IDisposer
let _disposerItemsStore: IDisposer
let _disposerTasksStore: IDisposer
export async function setupRootStore(rootStore: RootStore) {
  let restoredState: any

  try {
    // load the last known state from AsyncStorage
    restoredState = (await storage.load(AUTHENTICATIONSTORE_STATE_STORAGE_KEY)) || {}
    applySnapshot(rootStore.authenticationStore, restoredState)
    restoredState = (await storage.load(FORMSSTORE_STATE_STORAGE_KEY)) || {}
    applySnapshot(rootStore.formsStore, restoredState)
    restoredState = (await storage.load(INTEGRATIONSSTORE_STATE_STORAGE_KEY)) || {}
    applySnapshot(rootStore.integrationsStore, restoredState)
    restoredState = (await storage.load(ITEMSSSTORE_STATE_STORAGE_KEY)) || {}
    await applySnapshot(rootStore.itemsStore, restoredState)
    restoredState = (await storage.load(TASKSSTORE_STATE_STORAGE_KEY)) || {}
    applySnapshot(rootStore.tasksStore, restoredState)

  } catch (e) {
    // if there's any problems loading, then inform the dev what happened
    if (__DEV__) {
      //console.tron.error(e.message, null)
    }
  }

  // stop tracking state changes if we've already setup
  if (_disposerAuthStore) _disposerAuthStore()
  if (_disposerFormsStore) _disposerFormsStore()
  if (_disposerIntegrationsStore) _disposerIntegrationsStore()
  if (_disposerItemsStore) _disposerItemsStore()
  if (_disposerTasksStore) _disposerTasksStore()
  // track changes & save to AsyncStorage
  _disposerAuthStore = onSnapshot(rootStore.authenticationStore, (snapshot) => storage.save(AUTHENTICATIONSTORE_STATE_STORAGE_KEY, snapshot))
  _disposerFormsStore = onSnapshot(rootStore.formsStore, (snapshot) => storage.save(FORMSSTORE_STATE_STORAGE_KEY, snapshot))
  _disposerIntegrationsStore = onSnapshot(rootStore.integrationsStore, (snapshot) => storage.save(INTEGRATIONSSTORE_STATE_STORAGE_KEY, snapshot))
  _disposerItemsStore = onSnapshot(rootStore.itemsStore, (snapshot) => storage.save(ITEMSSSTORE_STATE_STORAGE_KEY, snapshot))
  _disposerTasksStore = onSnapshot(rootStore.tasksStore, (snapshot) => storage.save(TASKSSTORE_STATE_STORAGE_KEY, snapshot))

  const unsubscribe = () => {
    _disposerAuthStore()
    _disposerAuthStore = undefined
    _disposerFormsStore()
    _disposerFormsStore = undefined
    _disposerIntegrationsStore()
    _disposerIntegrationsStore = undefined
    _disposerItemsStore()
    _disposerItemsStore = undefined
    _disposerTasksStore()
    _disposerTasksStore = undefined
  }

  return { rootStore, restoredState, unsubscribe }
}
