
export interface CustomAppDef {
  name: string
  isPTW:boolean
  displayName: string
  packagename: string
  baseUrl: string
  user?:string,
  pass?:string,
  loginSAML?: LoginSaml
  logoutSAML?: LogoutSaml
  logo: string
  styles: Styles
  memoryLow: boolean
  version: string
  versionCode: string
  firebase: Firebase
  profileHidden?: boolean
  haveHelp?: boolean,
  startInSetting?: boolean
  hideResetPassword?:boolean
  hideRegisterButton?:boolean
}

export interface LoginSaml {
  url: string
  title: string
}

export interface LogoutSaml {
  url: string
}

export interface ColorPalette {
  "50": string
  "100": string
  "200": string
  "300": string
  "400": string
  "500": string
  "600": string
  "700": string
  "800": string
  "900": string
}

export interface Styles {
  primaryColor: string
  secondaryColor: string,
  primaryPalette:ColorPalette,
  secondaryPalette:ColorPalette
}

export interface Firebase {
  iosApp: IosApp
  androidApp: AndroidApp
}

export interface IosApp {
  name: string
  displayName: string
  platform: string
  appId: string
  namespace: string
  apiKeyId: string
  state: string
}

export interface AndroidApp {
  name: string
  displayName: string
  platform: string
  appId: string
  namespace: string
  apiKeyId: string
  state: string
}

import { customApp as customAppJSON } from "../voolks/customApp.json"

export const customApp: CustomAppDef = customAppJSON