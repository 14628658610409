import { Icon, IconButton, Spinner, } from 'native-base';
import React, { FunctionComponent } from 'react';
import { ElementBase, IElementBaseProps, initializeElement } from '..';
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { getDataFromIntegration } from '../../utils/itemCommonFunctions';
export const PopulateElement: FunctionComponent<IElementBaseProps> = ({ ...props }) => {
    const {
        itemsStore, element, integrationsStore, statusStore,
        errorList, setErrorList
    } = initializeElement(props);

    const [loadingOptions, setLoadingOptions] = React.useState<boolean>(false);

    const getInfo = async () => {
        setLoadingOptions(true);
        let data = await getDataFromIntegration(element, itemsStore.getPayloadElement, props.parentId, integrationsStore.getIntegration);
        itemsStore.populatePayload(data, element.integration.populateValues, props.parentId);
        setTimeout(() => {
            statusStore.reloadPayloadElementFromPopulate(data, element.integration.populateValues, props.parentId);
            setLoadingOptions(false);
        }, 400);

    }

    let btnCallIntegration = loadingOptions ? <Spinner key={"populate".concat(element.id, "Spinner")} size="lg" /> : <></>;
    if (!props.forReview && !loadingOptions && element.integration && element.integration.id)
        btnCallIntegration = <IconButton key="btnImportIntegration" size="lg" onPress={getInfo} icon={<Icon as={MaterialCommunityIcons} name="cloud-search" size="lg" />} />;

    return <ElementBase errors={errorList} element={element} rigthButton={btnCallIntegration} forReview={props.forReview} >
    </ElementBase>
}